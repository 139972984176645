import { UpsertLawyer } from 'api/generated/models/auth';

export interface MeFormData {
  lawyer_advocate_license: {
    key: string;
    url: string;
  };
  picture: {
    key: string;
    url: string;
  };
  identity_card_document: {
    key: string;
    url: string;
  };
}

export enum EVerificationStep {
  STEP1,
  STEP2,
  STEP3,
}

export interface IVerificationFormData {}

export interface IInitialVerification {
  step: EVerificationStep;
  formData: IVerificationFormData & UpsertLawyer & MeFormData;
}

export interface IVerificationState extends IInitialVerification {
  actions: {
    setStep: (payload: EVerificationStep) => any;
    setFormData: (payload: IVerificationFormData) => any;
    resetStep: () => any;
    resetFormData: () => any;
  };
}
