import { useState } from "react";

export const useOtp = () => {
  const [otp, setOtp] = useState('');

const onChangeOtp = (value: string) => setOtp(value);

return {
    onChangeOtp, otp, setOtp
  };
};
