import { User } from 'api/generated/models/case';

import { secretString } from './format-string';

export function getFullName(user: User) {
  return [user?.first_name, user?.last_name].filter(Boolean).join(' ');
}

export function getSecretFullName(user: User) {
  return [secretString(user?.first_name), secretString(user?.last_name)]
    .filter(Boolean)
    .join(' ');
}
