import { useState } from 'react';

export const useLegalDraftingListModal = () => {
  const [isLegalDraftingListModalOpen, setIsLegalDraftingListModalOpen] =
    useState(false);
  const onOpenLegalDraftingListModal = () =>
    setIsLegalDraftingListModalOpen(true);
  const onCloseLegalDraftingListModal = () =>
    setIsLegalDraftingListModalOpen(false);

  return {
    isLegalDraftingListModalOpen,
    onOpenLegalDraftingListModal,
    onCloseLegalDraftingListModal,
  };
};
