export function formatSupportingResourceLink(text: string = ''): string {
  if (!text) {
    return window.location.origin;
  }

  try {
    new URL(text);
    return text;
  } catch (error) {
    return `https://${text}`;
  }
}
