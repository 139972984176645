import IncomingConsultationsAudio from 'assets/audio/incoming-consultation.mp3';

export const incomingConsultationsNotificationAudio = () => {
  const incomingConsultationsSound = new Audio(IncomingConsultationsAudio);

  const playAudio = () => {
    if ('Notification' in window && Notification.permission === 'granted') {
      incomingConsultationsSound.play();

      if (navigator.serviceWorker && navigator.serviceWorker.ready) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification('Konsultasi baru', {
            body: 'Cek beranda untuk melihat konsultasi terbaru',
            // Other options like icon, image, etc. can be specified here
          });
        });
      } else {
        // Fallback for environments without service workers
        new Notification('Konsultasi baru', {
          body: 'Cek beranda untuk melihat konsultasi terbaru',
          // Other options like icon, image, etc. can be specified here
        });
      }
    }
  };
  return playAudio();
};
