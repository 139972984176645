import './style.scss';

import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { Divider, Grid, Layout } from 'antd';

import { UserInfo } from 'components/user-info';
import { useLayoutStore } from 'store/layout';

import { MainMenu, SettingMenu, VerificationMenu } from './components';
import { AuthMenu } from './components/AuthMenu';

export function PageSidebar() {
  const location = useLocation();
  const screens = Grid.useBreakpoint();
  const isMobileSidebarVisible = useLayoutStore(
    (state) => state.isMobileSidebarVisible
  );
  const toggleMobileSidebar = useLayoutStore(
    (state) => state.actions.toggleMobileSidebar
  );
  const sidebarRef = useRef<HTMLDivElement>(null);

  const siderWidth = screens.md ? 300 : 288;
  const isSettingPath = location?.pathname?.includes('/setting');
  const isVerificationStep = ['/verification'].includes(location?.pathname);
  const isDesktopMainMenuVisible = !isSettingPath && !isVerificationStep;

  useEffect(() => {
    toggleMobileSidebar(false);
  }, [location.pathname, toggleMobileSidebar]);

  return (
    <>
      <div
        className={`hk-cms-sidebar-backdrop ${
          isMobileSidebarVisible ? 'hk-cms-sidebar-backdrop--open' : ''
        }`}
        onClick={() => toggleMobileSidebar()}></div>
      <Layout.Sider
        className={`hk-cms-sidebar ${
          isMobileSidebarVisible ? 'hk-cms-sidebar--open' : ''
        }`}
        id="page-sidebar"
        ref={sidebarRef}
        width={siderWidth}>
        <div className="wrapper hk-margin-top-md">
          {/* Desktop view */}
          {screens.md && isVerificationStep && <VerificationMenu />}
          {screens.md && isSettingPath && (
            <>
              <UserInfo className="hk-margin-bottom-md" />
              <SettingMenu />
            </>
          )}
          {screens.md && isDesktopMainMenuVisible && <MainMenu />}

          {/* Mobile view */}
          {!screens.md && (
            <>
              <MainMenu />
              <Divider className="hk-margin-top-sm hk-margin-bottom-sm" />
              <SettingMenu />
              <Divider className="hk-margin-top-sm hk-margin-bottom-sm" />
              <AuthMenu />
            </>
          )}
        </div>
      </Layout.Sider>
    </>
  );
}
