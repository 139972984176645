import { Grid, message, Modal, Space, Typography } from 'antd';

import { ReactComponent as SuccessIc } from 'assets/svg/success-ic.svg';

interface MessageSuccessOptions {
  desktopMessage: string;
  mobileMessage: string;
  mobileMessageTitle?: string;
}

export function useResponsiveFlashMessage() {
  const screens = Grid.useBreakpoint();

  const messageSuccess = (options: MessageSuccessOptions) => {
    if (screens.md) {
      return message.success(options.desktopMessage);
    } else {
      return Modal.success({
        closable: true,
        content: (
          <Space
            className="hk-align-center hk-padding-top-lg"
            direction="vertical"
            size={0}>
            <SuccessIc />
            <Typography.Title className="text-title hk-color-black-title hk-margin-bottom-sm">
              {options.mobileMessageTitle || 'Berhasil'}
            </Typography.Title>
            <Typography.Paragraph type="secondary">
              {options.mobileMessage}
            </Typography.Paragraph>
          </Space>
        ),
        footer: false,
        icon: <></>,
        maskClosable: true,
      });
    }
  };

  return {
    messageSuccess,
  };
}
