import './style.scss';

import { Tag } from 'antd';

const UpcomingTag = () => {
  return (
    <Tag className="hk-margin-top-sm upcoming-tag" color="#6CAB3B">
      Segera hadir
    </Tag>
  );
};

export { UpcomingTag };
