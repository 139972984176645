import { message } from 'antd';
import { isAxiosError } from 'axios';

// import { Error } from 'api/model';
import { SERVICES } from 'config/constants';

import { GenerateUrlPathProps } from './types';

/**
 *
 * @param {Object} errorCatch - error message from BE
 * @param {String} customError - error message from FE
 */
export function errHandler(errorCatch: any, customError: string = '') {
  const {
    response: {
      data: { message: errorMessageFromBE = 'Terjadi kesalahan server' },
    },
  } = errorCatch ?? {};
  message.error(customError || errorMessageFromBE);
}

// Under development, will be adjusted once BE ready
export function errorHandler(error: unknown) {
  if (isAxiosError(error)) {
    return message.error(error.message);
  }
}

export function normalizeNumber(value: string) {
  return value.replace(/\D/g, '');
}

export function formatPhoneNumberPayloadBE(
  val: string | number | null | undefined
) {
  if (val === 0 || val === '0') return '+62';
  if (val === 62 || val === '62') return '+62';
  if (val && String(val)?.[0] === '0') {
    return `+62${String(val)?.substring(1, String(val)?.length)}`;
  }
  if (val && String(val)?.[0] === '6' && String(val)?.[1] === '2') {
    return `+62${String(val)?.substring(2, String(val)?.length)}`;
  }

  return `+62${val}`;
}

export function generateUrlPath(props: GenerateUrlPathProps) {
  const { service, path, version = 'v1' } = props ?? {};

  return `/${SERVICES[service]}/${version}/${path?.replace(/^\//, '')}`;
}

export function priceFormatter(number: number | string) {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function currencyFormatter(amount: number, currency = 'Rp') {
  const formatter = priceFormatter(amount);
  return currency + ' ' + formatter;
}

export const combinedInitialName = (firstName: string, lastName: string) => {
  const firstLetter = firstName ? firstName[0] : '';
  const secondLetter = lastName ? lastName[0] : '';
  return `${firstLetter}${secondLetter}`;
};

export function generateUniqueId() {
  return `_${Math.random().toString(36).slice(2, 9)}`;
}

export const maskingString = (str: string, mask: string = '*') => {
  const maskWord = (word: string): string => {
    if (!word) return '';
    return word.charAt(0) + mask.repeat(word.length - 1);
  };

  return str
    .split(' ')
    .map((word) => maskWord(word))
    .join(' ');
};
