import dayjs from 'dayjs';

import { Consultation, ConsultationBasicCase } from 'api/generated/models/case';

/**
 * Get consultation time left in seconds
 *
 * @param consultation Consultation to be checked
 * @returns number of seconds left for given consultation
 */
export function getConsultationTimeLeft(
  consultation: Consultation | ConsultationBasicCase | undefined | null
): number | null {
  if (consultation?.started_at) {
    const start = new Date();
    const end = new Date(
      (consultation.started_at + consultation.duration) * 1000
    );
    const secondsLeft = dayjs(end).diff(start, 'seconds');

    if (secondsLeft > 0) {
      return secondsLeft;
    } else {
      return 0;
    }
  }

  return null;
}
