import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { CurrentUserState, InitialCurrentUser } from './types/current-user';

const initialState: InitialCurrentUser = {
  consultId: undefined,
  firstName: undefined,
  id: undefined,
  lastName: undefined,
  picture: undefined,
  isOnline: undefined,
  isAnnouncementBannerVisible: true,
  lawyerProfileUrl: undefined,
  disableAutoOffline: false,
  role: undefined,
};

export const useCurrentUserStore = create<CurrentUserState>()(
  persist(
    (set) => ({
      ...initialState,
      actions: {
        setCurrentUser(payload) {
          set((state) => ({ ...state, ...payload }));
        },
        setIsAnnouncementBannerVisible(visible) {
          set((state) => ({ ...state, isAnnouncementBannerVisible: visible }));
        },
        reset() {
          set(() => initialState);
        },
      },
    }),
    {
      name: 'currentUser',
      partialize: (state) => {
        // define keys from state that dont want to be included in persist storage
        const excludedKeys = ['actions'];
        return Object.fromEntries(
          Object.entries(state).filter(([key]) => !excludedKeys.includes(key))
        );
      },
    }
  )
);
