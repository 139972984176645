import './style.scss';

import { Col, Row, Space, Typography } from 'antd';

import { ReactComponent as InformationBoxGreenIc } from 'assets/svg/information-box-green-ic.svg';

export const StatusInformationBanner = () => {
  return (
    <Row
      align="middle"
      className="status-information-banner hk-padding-md hk-margin-bottom-lg"
      gutter={[16, 16]}
      justify="start">
      <Col className="flex center " lg={2} xs={24}>
        <InformationBoxGreenIc />
      </Col>
      <Col lg={22} xs={24}>
        <Space direction="vertical" size={0}>
          <Typography.Text className="hk-color-black-title font-18 weight-600">
            Jangan menutup atau minimize jika status masih Online.
          </Typography.Text>
          <Typography.Text className="hk-color-black-title font-14 weight-400">
            Pastikan Anda berubah menjadi Offline sebelum menutup tab atau
            keluar dari konsultasi.
          </Typography.Text>
        </Space>
      </Col>
    </Row>
  );
};
