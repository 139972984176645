/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * HukumkuRestAPI-CaseService
 * OpenAPI spec version: 1.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type { ErrorType } from '../../../../utils/case-service-request';
import { caseServiceRequest } from '../../../../utils/case-service-request';
import type {
  Error,
  GetLawyerOrdersParams,
  GetOrders200,
  GetOrdersId200,
  GetOrdersIdPaymentStatus200,
  GetOrdersParams,
  ListLawyerOrder,
  PaymentInvoice200,
} from '../../../models/case';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Request payment
 */
export const paymentInvoice = (
  id: string,
  options?: SecondParameter<typeof caseServiceRequest>
) => {
  return caseServiceRequest<PaymentInvoice200>(
    { url: `/orders/${id}/invoice`, method: 'post' },
    options
  );
};

export const getPaymentInvoiceMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof paymentInvoice>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof paymentInvoice>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof paymentInvoice>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return paymentInvoice(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PaymentInvoiceMutationResult = NonNullable<
  Awaited<ReturnType<typeof paymentInvoice>>
>;

export type PaymentInvoiceMutationError = ErrorType<Error>;

/**
 * @summary Request payment
 */
export const usePaymentInvoice = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof paymentInvoice>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}) => {
  const mutationOptions = getPaymentInvoiceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get list of order
 */
export const getOrders = (
  params: GetOrdersParams,
  options?: SecondParameter<typeof caseServiceRequest>,
  signal?: AbortSignal
) => {
  return caseServiceRequest<GetOrders200>(
    { url: `/orders`, method: 'get', params, signal },
    options
  );
};

export const getGetOrdersQueryKey = (params: GetOrdersParams) =>
  [`/orders`, ...(params ? [params] : [])] as const;

export const getGetOrdersQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrders>>,
  TError = ErrorType<Error>,
>(
  params: GetOrdersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrders>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryOptions<Awaited<ReturnType<typeof getOrders>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOrdersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrders>>> = ({
    signal,
  }) => getOrders(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetOrdersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOrders>>
>;
export type GetOrdersQueryError = ErrorType<Error>;

/**
 * @summary Get list of order
 */
export const useGetOrders = <
  TData = Awaited<ReturnType<typeof getOrders>>,
  TError = ErrorType<Error>,
>(
  params: GetOrdersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrders>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrdersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get detail order
 */
export const getOrdersId = (
  id: string,
  options?: SecondParameter<typeof caseServiceRequest>,
  signal?: AbortSignal
) => {
  return caseServiceRequest<GetOrdersId200>(
    { url: `/orders/${id}`, method: 'get', signal },
    options
  );
};

export const getGetOrdersIdQueryKey = (id: string) =>
  [`/orders/${id}`] as const;

export const getGetOrdersIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrdersId>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrdersId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryOptions<Awaited<ReturnType<typeof getOrdersId>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOrdersIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersId>>> = ({
    signal,
  }) => getOrdersId(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetOrdersIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOrdersId>>
>;
export type GetOrdersIdQueryError = ErrorType<Error>;

/**
 * @summary Get detail order
 */
export const useGetOrdersId = <
  TData = Awaited<ReturnType<typeof getOrdersId>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrdersId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrdersIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get order's payment status
 */
export const getOrdersIdPaymentStatus = (
  id: string,
  options?: SecondParameter<typeof caseServiceRequest>,
  signal?: AbortSignal
) => {
  return caseServiceRequest<GetOrdersIdPaymentStatus200>(
    { url: `/orders/${id}/payment-status`, method: 'get', signal },
    options
  );
};

export const getGetOrdersIdPaymentStatusQueryKey = (id: string) =>
  [`/orders/${id}/payment-status`] as const;

export const getGetOrdersIdPaymentStatusQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrdersIdPaymentStatus>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrdersIdPaymentStatus>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getOrdersIdPaymentStatus>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetOrdersIdPaymentStatusQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getOrdersIdPaymentStatus>>
  > = ({ signal }) => getOrdersIdPaymentStatus(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetOrdersIdPaymentStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOrdersIdPaymentStatus>>
>;
export type GetOrdersIdPaymentStatusQueryError = ErrorType<Error>;

/**
 * @summary Get order's payment status
 */
export const useGetOrdersIdPaymentStatus = <
  TData = Awaited<ReturnType<typeof getOrdersIdPaymentStatus>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrdersIdPaymentStatus>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrdersIdPaymentStatusQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get list of order
 */
export const getLawyerOrders = (
  params: GetLawyerOrdersParams,
  options?: SecondParameter<typeof caseServiceRequest>,
  signal?: AbortSignal
) => {
  return caseServiceRequest<ListLawyerOrder>(
    { url: `/lawyer-orders`, method: 'get', params, signal },
    options
  );
};

export const getGetLawyerOrdersQueryKey = (params: GetLawyerOrdersParams) =>
  [`/lawyer-orders`, ...(params ? [params] : [])] as const;

export const getGetLawyerOrdersQueryOptions = <
  TData = Awaited<ReturnType<typeof getLawyerOrders>>,
  TError = ErrorType<Error>,
>(
  params: GetLawyerOrdersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerOrders>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getLawyerOrders>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLawyerOrdersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLawyerOrders>>> = ({
    signal,
  }) => getLawyerOrders(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLawyerOrdersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLawyerOrders>>
>;
export type GetLawyerOrdersQueryError = ErrorType<Error>;

/**
 * @summary Get list of order
 */
export const useGetLawyerOrders = <
  TData = Awaited<ReturnType<typeof getLawyerOrders>>,
  TError = ErrorType<Error>,
>(
  params: GetLawyerOrdersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerOrders>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLawyerOrdersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
