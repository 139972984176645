import { FEATURE_FLAG } from './feature-flag';

interface FeatureFlagValue {
  [envName: string]: {
    [flagName: string]: boolean;
  };
}

export const FEATURE_FLAG_VALUE: FeatureFlagValue = {
  stage: {
    [FEATURE_FLAG.CHANGE_PASSWORD]: true,
    [FEATURE_FLAG.PHONE_LOGIN]: true,
    [FEATURE_FLAG.AUTHENTICATION_OTP]: true,
    [FEATURE_FLAG.SETTING_CREDIT]: true,
    [FEATURE_FLAG.LEGAL_DRAFTS]: true,
  },
  prod: {
    [FEATURE_FLAG.CHANGE_PASSWORD]: true,
    [FEATURE_FLAG.PHONE_LOGIN]: true,
    [FEATURE_FLAG.AUTHENTICATION_OTP]: true,
    [FEATURE_FLAG.SETTING_CREDIT]: true,
    [FEATURE_FLAG.LEGAL_DRAFTS]: true,
  },
};
