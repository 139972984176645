import './style.scss';

import { Avatar as AntdAvatar, Grid } from 'antd';

import { BasicUser } from 'api/generated/models/auth';
import { User } from 'api/generated/models/case';
import { combinedInitialName } from 'utils';

export interface AvatarProps {
  size?: number;
  user?: Partial<User> | Partial<BasicUser>;
}

export function Avatar({ size, user }: AvatarProps) {
  const screens = Grid.useBreakpoint();
  const defaultSize = screens.md ? 50 : 40;
  const photoUrl = user?.picture?.url;

  return photoUrl ? (
    <AntdAvatar className="avatar" size={size || defaultSize} src={photoUrl} />
  ) : (
    <AntdAvatar className="avatar" size={size || defaultSize}>
      {combinedInitialName(user?.first_name || '', user?.last_name || '') ||
        'U'}
    </AntdAvatar>
  );
}
