import '../../style.scss';

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ArrowLeftOutlined, WarningFilled } from '@ant-design/icons';
import {
  Button,
  Col,
  Grid,
  Modal,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { useCountDownTimer } from 'hooks/useCountDownTimer';
import { useFlag } from 'hooks/useFlag';

import ArrowRight from 'assets/svg/arrow-right.svg';
import { Avatar } from 'components/avatar';
import { useEndConsultation } from 'components/chat/hooks/useEndConsultation';
import { ENDED_CONSULTATION_STATUS } from 'config/constants';
import { FEATURE_FLAG } from 'config/feature-flag';
import { CreateOfferModal } from 'pages/legal-drafting/components/modal/CreateOfferModal';
import { LegalDraftModal } from 'pages/legal-drafting/components/modal/LegalDraftModal';
import { ModalLegalDraftGuidebook } from 'pages/legal-drafting/components/modal-legal-draft-guidebook';
import { useLegalDraftingListModal } from 'pages/legal-drafting/hooks/useLegalDraftingListModal';
import { useOfferLegalDrafts } from 'pages/legal-drafting/hooks/useOfferLegalDrafts';
import { ConsultationStatus } from 'types/enums';
import { formatSecondsToDuration } from 'utils/format-seconds-to-duration';
import { getConsultationTimeLeft } from 'utils/get-consultation-time-left';
import { getSecretFullName } from 'utils/get-full-name';

import { ChatHeadProps } from '../../types';

export const ChatHead = ({
  consultation,
  hasStartMessage,
  isBackButtonVisible,
  isHasActiveOrderOffer,
  onSuccessfullyEnded,
}: ChatHeadProps) => {
  const { t } = useTranslation(['consultation']);
  const screens = Grid.useBreakpoint();
  const navigate = useNavigate();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);

  // Todo remove when ready for production
  const ENABLE_LEGAL_DRAFTS = useFlag(FEATURE_FLAG.LEGAL_DRAFTS);
  const {
    isCreateOfferModalOpen,
    onOpenCreateLegalDraftsModal,
    onCloseCreateLegalDraftsModal,
  } = useOfferLegalDrafts();

  const {
    isLegalDraftingListModalOpen,
    onOpenLegalDraftingListModal,
    onCloseLegalDraftingListModal,
  } = useLegalDraftingListModal();

  const clientName = consultation?.case?.client
    ? getSecretFullName(consultation?.case?.client)
    : '';

  const isFinished = ENDED_CONSULTATION_STATUS.includes(
    consultation?.status as ConsultationStatus
  );
  const [isEndConfirmationModalOpened, setIsEndConfirmationModalOpened] =
    useState<boolean>(false);

  const consultationTimeSecondsLeft = useMemo(
    () => getConsultationTimeLeft(consultation),
    [consultation]
  );

  const handleSuccessfullyEnded = () => {
    setIsEndConfirmationModalOpened(false);
    onSuccessfullyEnded?.();
  };

  const { displayedSecondsLeft, timerClassName } = useCountDownTimer({
    countDownSecondsLeft: consultationTimeSecondsLeft,
    options: {
      enabled: consultation?.status === ConsultationStatus.ongoing,
    },
  });

  const { endConsultation, isLoadingEndConsultation } = useEndConsultation({
    consultation,
    onSuccessfullyEnded: handleSuccessfullyEnded,
  });

  const handleConfirmEndConsultation = async () => {
    if (consultation?.id) {
      endConsultation();
    }
  };

  const handleClickBackChatList = () => {
    navigate('/message');
  };

  useEffect(() => {
    const hasTimeLeft =
      typeof consultationTimeSecondsLeft === 'number' &&
      consultationTimeSecondsLeft > 0;
    const isTimerFinished =
      typeof displayedSecondsLeft === 'number' && displayedSecondsLeft <= 0;
    const isOngoing =
      consultation && consultation.status === ConsultationStatus.ongoing;
    if (
      isOngoing &&
      hasTimeLeft &&
      isTimerFinished &&
      !isLoadingEndConsultation
    ) {
      endConsultation();
    } else if (isOngoing && !hasTimeLeft && !isLoadingEndConsultation) {
      endConsultation();
    }
  }, [
    consultation,
    consultationTimeSecondsLeft,
    displayedSecondsLeft,
    endConsultation,
    isLoadingEndConsultation,
  ]);

  return (
    <>
      <ModalLegalDraftGuidebook
        isShow={isShowModal}
        onCloseModal={() => setIsShowModal(false)}
      />
      <Row
        align="middle"
        className="main-chat-head"
        gutter={24}
        justify="space-between"
        wrap={false}>
        <Col span={17}>
          <Space direction="horizontal" size={12}>
            {isBackButtonVisible && (
              <ArrowLeftOutlined onClick={handleClickBackChatList} />
            )}
            <Avatar user={consultation?.case.client} />
            <Space direction="vertical" size={0}>
              <Tooltip arrow={true} placement="bottomLeft" title={clientName}>
                <Typography.Paragraph
                  className="user-name weight-500 hk-margin-none hk-color-black-title"
                  ellipsis={{ rows: 2 }}>
                  {clientName}
                </Typography.Paragraph>
              </Tooltip>
              {!screens.md && isFinished ? null : (
                <Typography.Text className="session-state weight-400 hk-color-black-title">
                  Sesi konsultasi:{' '}
                  {isFinished && (
                    <Typography.Text type="danger">
                      {t(`consultation:status.${consultation?.status}`)}
                    </Typography.Text>
                  )}
                  {!isFinished && !hasStartMessage && screens.md && (
                    <Typography.Text type="warning">
                      Menunggu kesiapan client
                    </Typography.Text>
                  )}
                  {!isFinished && hasStartMessage && (
                    <span className={timerClassName}>
                      {formatSecondsToDuration(displayedSecondsLeft)}
                    </span>
                  )}
                </Typography.Text>
              )}
            </Space>
          </Space>
        </Col>
        <Col>
          {isFinished ? (
            <Tag bordered={false} color="error">
              {t(`consultation:status.${consultation?.status}`)}
            </Tag>
          ) : (
            <Button
              danger
              onClick={() => setIsEndConfirmationModalOpened(true)}
              size={screens.md ? 'large' : 'small'}
              type="text">
              <span className="desktop">AKHIRI KONSULTASI</span>
              <span className="mobile">AKHIRI</span>
            </Button>
          )}
        </Col>
      </Row>
      {ENABLE_LEGAL_DRAFTS && (
        <>
          {isLegalDraftingListModalOpen && (
            <LegalDraftModal
              caseCode={consultation?.case?.code}
              clientName={clientName}
              onCancel={onCloseLegalDraftingListModal}
              open={isLegalDraftingListModalOpen}
            />
          )}
          <Row
            align="middle"
            className="main-chat-head legal-draft"
            gutter={[24, 0]}
            justify="space-between"
            wrap={false}>
            <Col md={12}>
              <Space direction="vertical" size={6}>
                <Space direction="horizontal" size={6}>
                  <Typography.Paragraph className="create-offer weight-500 hk-margin-none hk-color-black-title">
                    Buat Penawaran
                  </Typography.Paragraph>
                  {!isFinished && (
                    <Tag bordered={false} color="orange">
                      <Typography.Paragraph className="create-offer-tag weight-400 hk-margin-none hk-color-status-yellow">
                        Tersedia saat selesai konsultasi
                      </Typography.Paragraph>
                    </Tag>
                  )}
                </Space>
                <Typography.Paragraph className="create-offer-caption weight-400 hk-margin-none hk-color-dark-grey">
                  Anda bisa membuat penawaran untuk pembuatan dokumen{' '}
                  {!screens.md && (
                    <span>
                      {isHasActiveOrderOffer && (
                        <Typography.Paragraph
                          className="hk-cursor-pointer font-10 weight-500"
                          color="#787872"
                          onClick={onOpenLegalDraftingListModal}>
                          Lihat Legal Drafting
                        </Typography.Paragraph>
                      )}
                    </span>
                  )}
                </Typography.Paragraph>
              </Space>
            </Col>
            <Col md={12}>
              <Row align="middle" gutter={[12, 0]} justify="end" wrap={false}>
                <Col>
                  <Button
                    className={`h-full ${!screens.md && 'hk-align-left'}`}
                    disabled={!isFinished}
                    onClick={onOpenCreateLegalDraftsModal}
                    size={screens.md ? 'large' : 'small'}
                    type="primary">
                    Buat {!screens.md ? <br></br> : ' '}Penawaran
                  </Button>
                </Col>
                {isHasActiveOrderOffer && screens.md && (
                  <Col>
                    <Button
                      onClick={onOpenLegalDraftingListModal}
                      size={screens.md ? 'large' : 'small'}>
                      Legal Draft
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>

          <button
            className="main-chat-head-button-guide"
            onClick={() => setIsShowModal(true)}>
            <span>Lihat Cara Pemakaian</span>{' '}
            <img alt="guidebook" src={ArrowRight} />
          </button>
        </>
      )}
      <Modal
        closable={!isLoadingEndConsultation}
        footer={null}
        maskClosable={!isLoadingEndConsultation}
        onCancel={() => setIsEndConfirmationModalOpened(false)}
        open={isEndConfirmationModalOpened}
        width={400}>
        <div className="hk-padding-top-lg hk-padding-bottom-lg hk-padding-left-md hk-padding-right-md">
          <Space align="center" className="w-full" direction="vertical">
            <WarningFilled className="anticon-large hk-color-status-yellow hk-margin-bottom-md" />
            <Typography.Text className="text-title weight-600 hk-padding-bottom-sm">
              Stop Konsultasi
            </Typography.Text>
            <Typography.Text className="block text-center">
              Apakah Anda yakin untuk mengakhiri sesi konsultasi ini?
            </Typography.Text>
          </Space>
          <Row className="hk-margin-top-lg" gutter={12} justify="space-between">
            <Col span={12}>
              <Button
                block
                loading={isLoadingEndConsultation}
                onClick={() => setIsEndConfirmationModalOpened(false)}>
                Batal
              </Button>
            </Col>
            <Col span={12}>
              <Button
                block
                loading={isLoadingEndConsultation}
                onClick={handleConfirmEndConsultation}
                type="primary">
                Ya, Akhiri
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>

      {isCreateOfferModalOpen && (
        <CreateOfferModal
          action="create-from-chat"
          consultationId={consultation?.id}
          onCancel={onCloseCreateLegalDraftsModal}
          open={isCreateOfferModalOpen}
        />
      )}
    </>
  );
};
