import { Col, Row, Typography } from 'antd';

import { ReactComponent as EmptyListImg } from 'assets/svg/empty-folder.svg';

export const EmptyLegalDrafts = ({ message }: { message: string }) => {
  return (
    <Row className="hk-margin-top-xl" justify="center">
      <Col className="flex center flex-column items-center">
        <EmptyListImg />
        <Typography.Paragraph className="hk-margin-bottom-xs hk-margin-top-lg font-20 weight-500 hk-color-black-title hk-align-center">
          Tidak ada data.
        </Typography.Paragraph>
        <Typography.Paragraph className="font-14 weight-400 hk-color-heading-1 hk-align-center">
          {message}
        </Typography.Paragraph>
      </Col>
    </Row>
  );
};
