import '../style.scss';

import { useMemo } from 'react';

import { Col, Grid, Modal, Row, Skeleton, Typography } from 'antd';
import dayjs from 'dayjs';

import { User } from 'api/generated/models/case';
import { useGetLegalDraftsId } from 'api/generated/services/case/legal-draft/legal-draft';
import { useGetOrders } from 'api/generated/services/case/order/order';

import { LimitAlert } from '../../limit-alert';
import { DocumentDetailCard } from './DocumentDetailCard';
import { ForumPaymentCard } from './ForumPaymentCard';
import { OtherInformations } from './OtherInformations';
import { Revisions } from './Revisions';
import { ForumDiscussionModalProps, MissedBy } from './types';

export const ForumDiscussionModal = (props: ForumDiscussionModalProps) => {
  const { legalDraftId } = props;

  const screens = Grid.useBreakpoint();

  const { data: legalDraft, isLoading: isLoadingLegalDraft } =
    useGetLegalDraftsId(legalDraftId ?? '');

  const caseId = legalDraft?.data?.case_id;

  const { data: orderData, isLoading: isLoadingOrder } = useGetOrders(
    {
      product_type: ['legal_drafts'],
      case_id: caseId,
      page: 1,
      limit: 1,
    },
    {
      query: {
        enabled: !!caseId,
      },
    }
  );

  const memoizedLegalDraft = useMemo(() => {
    const dueDate = legalDraft?.data?.items?.[0]?.due_date
      ? dayjs
          .unix(legalDraft?.data?.items?.[0]?.due_date)
          .format('DD MMMM YYYY, hh:mm')
      : '-';
    const dueDateCard = legalDraft?.data?.items?.[0]?.due_date
      ? dayjs.unix(legalDraft?.data?.items?.[0]?.due_date).format('DD/MM/YYYY')
      : '-';

    const revisions = legalDraft?.data?.items?.[0]?.revisions ?? null;
    const revisionLength = revisions?.length ? revisions.length - 1 : 0;

    const comments =
      legalDraft?.data?.items?.[0]?.revisions?.[revisionLength]?.comments;
    const commentsLength = comments?.length ? comments.length - 1 : 0;

    const missedBy: MissedBy =
      (revisions?.[revisionLength]?.comments?.[commentsLength]
        .comment as MissedBy) ?? null;

    const lawyerInfo = legalDraft?.data?.lawyer;
    const itemLegalDraftId = legalDraft?.data?.items?.[0]?.id;
    const legalDraftStatus = legalDraft?.data?.items?.[0]?.status;

    return {
      dueDate,
      dueDateCard,
      itemLegalDraftId,
      lawyerInfo,
      legalDraftStatus,
      missedBy,
      revisions,
    };
  }, [legalDraft?.data?.items, legalDraft?.data?.lawyer]);

  const memoizedOrder = useMemo(() => {
    const payment = orderData?.data?.[0].payment;
    const clientInfo = orderData?.data?.[0].client_info;

    return {
      payment,
      clientInfo,
    };
  }, [orderData?.data]);

  return (
    <Modal
      {...props}
      className="legal-draft-modal legal-draft-modal__forum-discussion"
      footer={false}
      width={1114}>
      <Row
        align="top"
        className="legal-draft-modal__forum-discussion-body"
        gutter={[40, 0]}>
        <Col className="card-wrapper hk-margin-top-lg" md={8} xs={24}>
          {isLoadingLegalDraft || isLoadingOrder ? (
            <Skeleton.Button block className="skeleton-forum-card" />
          ) : (
            <ForumPaymentCard
              dueDate={memoizedLegalDraft?.dueDateCard}
              legalDraft={legalDraft?.data}
              payment={memoizedOrder?.payment}
              user={memoizedOrder?.clientInfo as User}
            />
          )}
          {isLoadingLegalDraft || isLoadingOrder ? (
            <Skeleton.Button
              block
              className="hk-margin-top-md skeleton-document-detail"
            />
          ) : (
            <>
              <DocumentDetailCard legalDraft={legalDraft?.data} />
              {screens.md && <OtherInformations />}
            </>
          )}
        </Col>
        <Col className="revision-wrapper" md={16} xs={24}>
          <Typography.Title
            className="legal-draft-modal-title weight-500"
            color="#121926">
            Forum Diskusi
          </Typography.Title>

          <LimitAlert
            dueDate={memoizedLegalDraft.dueDate}
            missedBy={memoizedLegalDraft.missedBy}
          />
          {isLoadingLegalDraft || isLoadingOrder ? (
            <Skeleton className="skeleton-revision" />
          ) : (
            <>
              <Revisions
                clientInfo={memoizedOrder?.clientInfo}
                lawyerInfo={memoizedLegalDraft?.lawyerInfo}
                legalDraftItemId={memoizedLegalDraft.itemLegalDraftId}
                missedBy={memoizedLegalDraft.missedBy}
                revisions={memoizedLegalDraft?.revisions}
                status={memoizedLegalDraft?.legalDraftStatus ?? ''}
              />
              {!screens.md && <OtherInformations />}
            </>
          )}
        </Col>
      </Row>
    </Modal>
  );
};
