import Slide1 from 'assets/img/auth-slide-1.svg';
import Slide2 from 'assets/img/auth-slide-2.svg';
import Slide3 from 'assets/img/auth-slide-3.svg';

export const AUTH_SLIDE = [
  {
    src: Slide1,
    title: 'PELUANG UNTUK ADVOKAT',
    caption: 'Dapatkan pendapatan tambahan di waktu luang',
  },
  {
    src: Slide2,
    title: 'DAPATKAN TAMBAHAN PENDAPATAN',
    caption: 'Tingkatkan visibilitas dan reputasi kepada masyarakat luas.',
  },
  {
    src: Slide3,
    title: 'JANGKAUAN KLIEN LUAS',
    caption:
      'Dapatkan klien baru tanpa batasan jarak, kapan saja, dan di mana saja.',
  },
];
