import { useEffect, useState } from 'react';

import { InboxOutlined } from '@ant-design/icons';
import { Modal, ModalProps, Typography, Upload, UploadFile } from 'antd';

import { ConsultationBasicCase } from 'api/generated/models/case';
import { BasicDocument } from 'api/generated/models/document';
import { usePostDocuments } from 'api/generated/services/document/default/default';

export interface UploadFileModalProps extends ModalProps {
  consultationId?: ConsultationBasicCase['id'];
  onUpload?: (document: BasicDocument) => void;
}

export function UploadFileModal({
  consultationId,
  onUpload,
  ...modalProps
}: UploadFileModalProps) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadedDocument, setUploadedDocument] =
    useState<BasicDocument | null>(null);
  const extraData = {
    reference_id: consultationId || '',
    reference_type: 'consultations',
    document_type: 'chat_document',
  };
  const {
    mutate: uploadFile,
    isLoading,
    isError,
  } = usePostDocuments({
    mutation: {
      onSuccess(data) {
        setUploadedDocument(data.data);
      },
    },
  });

  const handleChange = (uploadData: any) => {
    const changedFileList = (uploadData?.fileList as UploadFile[]) || [];
    if (!changedFileList?.length) return;

    const fileListItem = changedFileList[0];
    const file = fileListItem.originFileObj as Blob;
    uploadFile({
      data: {
        file,
        name: fileListItem.name,
        ...extraData,
      },
    });
  };

  const handleClickSend = () => {
    if (uploadedDocument) {
      onUpload?.(uploadedDocument);
    }
  };

  useEffect(() => {
    if (!modalProps.open) {
      setFileList([]);
    }
  }, [modalProps.open]);

  return (
    <Modal
      {...modalProps}
      cancelButtonProps={{
        disabled: isLoading,
      }}
      cancelText="Batal"
      closable={false}
      okButtonProps={{ disabled: isLoading || isError || !fileList.length }}
      okText="Kirim"
      onOk={handleClickSend}>
      <Upload.Dragger
        accept=".png,.jpg,.jpeg,.pdf,.doc,.docx"
        beforeUpload={(file) => {
          setFileList([file]);
          return false;
        }}
        fileList={fileList}
        multiple={false}
        name="file"
        onChange={handleChange}
        onRemove={() => setFileList([])}
        withCredentials={true}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click atau drag file ke area ini untuk mengunggah
        </p>
      </Upload.Dragger>
      {Boolean(isError) && (
        <Typography.Text type="danger">
          Gagal mengunggah file. Coba ulangi kembali.
        </Typography.Text>
      )}
    </Modal>
  );
}
