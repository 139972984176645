import { CloseOutlined, InboxOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Typography,
  Upload,
} from 'antd';

import { User } from 'api/generated/models/case';
import {
  usePatchLegalDraftItemsIdReviseRequestAcknowledge,
  usePatchLegalDraftItemsIdReviseRequestDeny,
  usePostLegalDraftItemsId,
} from 'api/generated/services/case/legal-draft/legal-draft';
import { FormError } from 'components';
import { useUploadDraft } from 'pages/legal-drafting/hooks/useUploadDraft';

import { RevisionHead } from './RevisionHead';
import { RevisionActionsProps } from './types';

export const RevisionActions = ({
  action,
  id,
  setAction,
  status,
  lawyerInfo,
}: RevisionActionsProps) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate: acceptRevisionRequest, isLoading: isLoadingAcceptRevision } =
    usePatchLegalDraftItemsIdReviseRequestAcknowledge();

  const { mutate: rejectRevisionRequest, isLoading: isLoadingDenyRevision } =
    usePatchLegalDraftItemsIdReviseRequestDeny();

  const {
    mutate: uploadLegalDraftDocument,
    isLoading: isLoadingUploadLegalDraftDocument,
  } = usePostLegalDraftItemsId();

  const { fileList, handleChangeFile, onClickRemoveFile, setFileList } =
    useUploadDraft();

  const onAcceptRevisionRequest = () => {
    acceptRevisionRequest(
      { id },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
          setAction('accept-revision');
          message.success('Sukses menerima revisi');
        },
      }
    );
  };

  const onRejectRevisionRequest = () => {
    const formValue = form.getFieldsValue();

    rejectRevisionRequest(
      { id, data: { reason: formValue.reason } },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
          setAction('reject-revision');
          message.success('Sukses menolak revisi');
        },
      }
    );
  };

  const onUploadReviseDocument = () => {
    const formValue = form.getFieldsValue();

    const payload = {
      id,
      data: { file: fileList[0].originFileObj, reason: formValue.reason },
    };
    uploadLegalDraftDocument(
      { ...payload },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
          setAction(null);
          message.success('Sukses unggah revisi');
        },
      }
    );
  };

  if (action === 'accept-revision' || (!action && status === 'in_revise')) {
    return (
      <Col>
        <Button onClick={() => setAction('upload')} type="primary">
          Unggah Dokumen
        </Button>
      </Col>
    );
  }

  if (action === 'upload') {
    return (
      <Form className="w-full" form={form} onFinish={onUploadReviseDocument}>
        <Col span={24}>
          <RevisionHead role="lawyer" user={lawyerInfo as User} />
        </Col>
        <Col className="hk-margin-top-md" span={24}>
          <Form.Item
            name="reason"
            rules={[
              {
                required: action === 'upload',
                message: <FormError message="Wajib diisi." />,
              },
            ]}>
            <Input.TextArea placeholder="Tulis disini..." />
          </Form.Item>
        </Col>
        <Col className="hk-margin-top-md hk-margin-bottom-md" span={24}>
          <Form.Item
            name="uploadedFile"
            rules={[
              {
                required: action === 'upload',
                message: <FormError message="Wajib diisi." />,
              },
            ]}>
            <Upload.Dragger
              accept=".png,.jpg,.jpeg,.pdf,.doc,.docx"
              beforeUpload={() => {
                return false;
              }}
              className=""
              maxCount={1}
              multiple={false}
              name="file"
              onChange={handleChangeFile}
              onDrop={handleChangeFile}
              onRemove={() => setFileList([])}
              showUploadList={false}
              withCredentials={true}>
              <Row
                align="middle"
                className="hk-padding-left-sm hk-padding-top-none hk-padding-right-sm hk-padding-bottom-none"
                justify="start">
                <Col className="hk-margin-right-sm">
                  <InboxOutlined className="font-36" />
                </Col>
                <Col className="hk-align-left ">
                  <Typography.Text className="hk-align-left font-12 weight-500 hk-color-black-title">
                    Browse or drag files here
                  </Typography.Text>
                  <br />
                  <Typography.Text className="hk-align-left font-12 weight-500 hk-color-dark-grey">
                    Support (.doc, .docx, pdf up to 1MB). Maximum attachments
                    allowed: 1
                  </Typography.Text>
                </Col>
              </Row>
            </Upload.Dragger>
          </Form.Item>
          {fileList.length === 1 &&
            fileList.map((eachFile, index) => {
              return (
                <Row
                  align="middle"
                  className="hk-margin-top-md"
                  justify="space-between"
                  key={index}>
                  <Col>
                    <Typography.Paragraph className="hk-padding-none hk-margin-none name">
                      {eachFile.name}
                    </Typography.Paragraph>
                  </Col>
                  <Col>
                    <Button
                      className="hk-padding-none hk-margin-none hk-color-black-title close-icon"
                      icon={<CloseOutlined color="#000" />}
                      onClick={onClickRemoveFile}
                      size="small"
                      type="text"
                    />
                  </Col>
                </Row>
              );
            })}
        </Col>
        <Row gutter={[12, 0]} justify="end">
          <Col>
            <Button onClick={() => setAction(null)}>Batal</Button>
          </Col>
          <Col>
            <Form.Item>
              <Button
                disabled={isLoadingUploadLegalDraftDocument}
                htmlType="submit"
                loading={isLoadingUploadLegalDraftDocument}
                type="primary">
                Kirim
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  if (action === 'reject-revision') {
    return (
      <Form className="w-full" form={form} onFinish={onRejectRevisionRequest}>
        <Col span={24}>
          <RevisionHead role="lawyer" user={lawyerInfo as User} />
        </Col>
        <Col className="hk-margin-top-md" span={24}>
          <Form.Item
            name="reason"
            rules={[
              {
                required: action === 'reject-revision',
                message: <FormError message="Wajib diisi." />,
              },
            ]}>
            <Input.TextArea placeholder="Tulis disini..." />
          </Form.Item>
        </Col>
        <Row gutter={[12, 0]} justify="end">
          <Col>
            <Button onClick={() => setAction(null)}>Batal</Button>
          </Col>
          <Col>
            <Form.Item>
              <Button
                disabled={isLoadingDenyRevision}
                htmlType="submit"
                loading={isLoadingDenyRevision}
                type="primary">
                Kirim
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  return (
    <>
      <Col>
        <Button onClick={() => setAction('reject-revision')}>
          Tolak Revisi
        </Button>
      </Col>
      <Col>
        <Button
          disabled={isLoadingAcceptRevision}
          loading={isLoadingAcceptRevision}
          onClick={onAcceptRevisionRequest}
          type="primary">
          Terima Revisi
        </Button>
      </Col>
    </>
  );
};
