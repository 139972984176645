/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * HukumkuRestAPI-DocumentService
 * OpenAPI spec version: 1.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type {
  BodyType,
  ErrorType,
} from '../../../../utils/document-service-request';
import { documentServiceRequest } from '../../../../utils/document-service-request';
import type {
  AddLawyerDocumentRequest,
  AddLawyerDocumentStatusEnumRequest,
  AddLawyerDocumentStatusRequest,
  BasicDocumentResponse,
  BasicLawyerDocumentStatusResponse,
  CategoryTreeResponse,
  DocumentResponse,
  EditLawyerDocumentRequest,
  EditLawyerDocumentStatusRequest,
  Error,
  GetDocuments200,
  GetDocumentsParams,
  GetLawyerDocumentsPaginatedSearchSummaryParams,
  GetLawyerDocumentsParams,
  GetLawyerDocumentsPublicParams,
  GetLawyerDocumentsSearchSummaryParams,
  LawyerDocumentCountResponse,
  LawyerDocumentDownloadUrlResponse,
  LawyerDocumentPublicResponse,
  LawyerDocumentResponse,
  LawyerDocumentsPublicResponse,
  LawyerDocumentsResponse,
  LawyerDocumentStatusEnumListResponse,
  PaginatedSearchSummaryResponse,
  SearchEngineSummaryResponse,
  TopLawyerDocumentsResponse,
  Upload,
} from '../../../models/document';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Upload file
 */
export const postDocuments = (
  upload: BodyType<Upload>,
  options?: SecondParameter<typeof documentServiceRequest>
) => {
  const formData = new FormData();
  formData.append('file', upload.file);
  formData.append('reference_id', upload.reference_id);
  formData.append('reference_type', upload.reference_type);
  formData.append('document_type', upload.document_type);
  if (upload.name !== undefined) {
    formData.append('name', upload.name);
  }
  if (upload.id !== undefined) {
    formData.append('id', upload.id);
  }
  if (upload.delete !== undefined) {
    formData.append('delete', upload.delete.toString());
  }

  return documentServiceRequest<BasicDocumentResponse>(
    {
      url: `/documents`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  );
};

export const getPostDocumentsMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDocuments>>,
    TError,
    { data: BodyType<Upload> },
    TContext
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postDocuments>>,
  TError,
  { data: BodyType<Upload> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postDocuments>>,
    { data: BodyType<Upload> }
  > = (props) => {
    const { data } = props ?? {};

    return postDocuments(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostDocumentsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postDocuments>>
>;
export type PostDocumentsMutationBody = BodyType<Upload>;
export type PostDocumentsMutationError = ErrorType<Error>;

/**
 * @summary Upload file
 */
export const usePostDocuments = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDocuments>>,
    TError,
    { data: BodyType<Upload> },
    TContext
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}) => {
  const mutationOptions = getPostDocumentsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get List Document
 */
export const getDocuments = (
  params?: GetDocumentsParams,
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<GetDocuments200>(
    { url: `/documents`, method: 'get', params, signal },
    options
  );
};

export const getGetDocumentsQueryKey = (params?: GetDocumentsParams) =>
  [`/documents`, ...(params ? [params] : [])] as const;

export const getGetDocumentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getDocuments>>,
  TError = ErrorType<Error>,
>(
  params?: GetDocumentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocuments>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryOptions<Awaited<ReturnType<typeof getDocuments>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDocumentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocuments>>> = ({
    signal,
  }) => getDocuments(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetDocumentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDocuments>>
>;
export type GetDocumentsQueryError = ErrorType<Error>;

/**
 * @summary Get List Document
 */
export const useGetDocuments = <
  TData = Awaited<ReturnType<typeof getDocuments>>,
  TError = ErrorType<Error>,
>(
  params?: GetDocumentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocuments>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDocumentsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get signed URL
 */
export const getDocumentsId = (
  id: string,
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<DocumentResponse>(
    { url: `/documents/${id}`, method: 'get', signal },
    options
  );
};

export const getGetDocumentsIdQueryKey = (id: string) =>
  [`/documents/${id}`] as const;

export const getGetDocumentsIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getDocumentsId>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocumentsId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getDocumentsId>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDocumentsIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocumentsId>>> = ({
    signal,
  }) => getDocumentsId(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetDocumentsIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDocumentsId>>
>;
export type GetDocumentsIdQueryError = ErrorType<Error>;

/**
 * @summary Get signed URL
 */
export const useGetDocumentsId = <
  TData = Awaited<ReturnType<typeof getDocumentsId>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocumentsId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDocumentsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get List Lawyer Document
 */
export const getLawyerDocuments = (
  params: GetLawyerDocumentsParams,
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<LawyerDocumentsResponse>(
    { url: `/lawyer_documents`, method: 'get', params, signal },
    options
  );
};

export const getGetLawyerDocumentsQueryKey = (
  params: GetLawyerDocumentsParams
) => [`/lawyer_documents`, ...(params ? [params] : [])] as const;

export const getGetLawyerDocumentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getLawyerDocuments>>,
  TError = ErrorType<Error>,
>(
  params: GetLawyerDocumentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerDocuments>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getLawyerDocuments>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLawyerDocumentsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLawyerDocuments>>
  > = ({ signal }) => getLawyerDocuments(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLawyerDocumentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLawyerDocuments>>
>;
export type GetLawyerDocumentsQueryError = ErrorType<Error>;

/**
 * @summary Get List Lawyer Document
 */
export const useGetLawyerDocuments = <
  TData = Awaited<ReturnType<typeof getLawyerDocuments>>,
  TError = ErrorType<Error>,
>(
  params: GetLawyerDocumentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerDocuments>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLawyerDocumentsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get List Lawyer Document Public
 */
export const getLawyerDocumentsPublic = (
  params: GetLawyerDocumentsPublicParams,
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<LawyerDocumentsPublicResponse>(
    { url: `/lawyer_documents/public`, method: 'get', params, signal },
    options
  );
};

export const getGetLawyerDocumentsPublicQueryKey = (
  params: GetLawyerDocumentsPublicParams
) => [`/lawyer_documents/public`, ...(params ? [params] : [])] as const;

export const getGetLawyerDocumentsPublicQueryOptions = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsPublic>>,
  TError = ErrorType<Error>,
>(
  params: GetLawyerDocumentsPublicParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerDocumentsPublic>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getLawyerDocumentsPublic>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLawyerDocumentsPublicQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLawyerDocumentsPublic>>
  > = ({ signal }) => getLawyerDocumentsPublic(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLawyerDocumentsPublicQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLawyerDocumentsPublic>>
>;
export type GetLawyerDocumentsPublicQueryError = ErrorType<Error>;

/**
 * @summary Get List Lawyer Document Public
 */
export const useGetLawyerDocumentsPublic = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsPublic>>,
  TError = ErrorType<Error>,
>(
  params: GetLawyerDocumentsPublicParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerDocumentsPublic>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLawyerDocumentsPublicQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Lawyer Document Detail
 */
export const getLawyerDocumentsId = (
  id: string,
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<LawyerDocumentResponse>(
    { url: `/lawyer_documents/${id}`, method: 'get', signal },
    options
  );
};

export const getGetLawyerDocumentsIdQueryKey = (id: string) =>
  [`/lawyer_documents/${id}`] as const;

export const getGetLawyerDocumentsIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsId>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerDocumentsId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getLawyerDocumentsId>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLawyerDocumentsIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLawyerDocumentsId>>
  > = ({ signal }) => getLawyerDocumentsId(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetLawyerDocumentsIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLawyerDocumentsId>>
>;
export type GetLawyerDocumentsIdQueryError = ErrorType<Error>;

/**
 * @summary Get Lawyer Document Detail
 */
export const useGetLawyerDocumentsId = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsId>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerDocumentsId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLawyerDocumentsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Lawyer Document Detail Public
 */
export const getLawyerDocumentsIdPublic = (
  id: string,
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<LawyerDocumentPublicResponse>(
    { url: `/lawyer_documents/${id}/public`, method: 'get', signal },
    options
  );
};

export const getGetLawyerDocumentsIdPublicQueryKey = (id: string) =>
  [`/lawyer_documents/${id}/public`] as const;

export const getGetLawyerDocumentsIdPublicQueryOptions = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsIdPublic>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerDocumentsIdPublic>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getLawyerDocumentsIdPublic>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLawyerDocumentsIdPublicQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLawyerDocumentsIdPublic>>
  > = ({ signal }) => getLawyerDocumentsIdPublic(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetLawyerDocumentsIdPublicQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLawyerDocumentsIdPublic>>
>;
export type GetLawyerDocumentsIdPublicQueryError = ErrorType<Error>;

/**
 * @summary Get Lawyer Document Detail Public
 */
export const useGetLawyerDocumentsIdPublic = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsIdPublic>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerDocumentsIdPublic>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLawyerDocumentsIdPublicQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Lawyer Document Download URL
 */
export const getLawyerDocumentsIdDownload = (
  id: string,
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<LawyerDocumentDownloadUrlResponse>(
    { url: `/lawyer_documents/${id}/download`, method: 'get', signal },
    options
  );
};

export const getGetLawyerDocumentsIdDownloadQueryKey = (id: string) =>
  [`/lawyer_documents/${id}/download`] as const;

export const getGetLawyerDocumentsIdDownloadQueryOptions = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsIdDownload>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerDocumentsIdDownload>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getLawyerDocumentsIdDownload>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLawyerDocumentsIdDownloadQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLawyerDocumentsIdDownload>>
  > = ({ signal }) => getLawyerDocumentsIdDownload(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetLawyerDocumentsIdDownloadQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLawyerDocumentsIdDownload>>
>;
export type GetLawyerDocumentsIdDownloadQueryError = ErrorType<Error>;

/**
 * @summary Get Lawyer Document Download URL
 */
export const useGetLawyerDocumentsIdDownload = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsIdDownload>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerDocumentsIdDownload>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLawyerDocumentsIdDownloadQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Lawyer Document Count
 */
export const getLawyerDocumentsCount = (
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<LawyerDocumentCountResponse>(
    { url: `/lawyer_documents/count`, method: 'get', signal },
    options
  );
};

export const getGetLawyerDocumentsCountQueryKey = () =>
  [`/lawyer_documents/count`] as const;

export const getGetLawyerDocumentsCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsCount>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getLawyerDocumentsCount>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getLawyerDocumentsCount>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLawyerDocumentsCountQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLawyerDocumentsCount>>
  > = ({ signal }) => getLawyerDocumentsCount(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLawyerDocumentsCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLawyerDocumentsCount>>
>;
export type GetLawyerDocumentsCountQueryError = ErrorType<Error>;

/**
 * @summary Get Lawyer Document Count
 */
export const useGetLawyerDocumentsCount = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsCount>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getLawyerDocumentsCount>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLawyerDocumentsCountQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Top Lawyer Document
 */
export const getLawyerDocumentsTop = (
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<TopLawyerDocumentsResponse>(
    { url: `/lawyer_documents/top`, method: 'get', signal },
    options
  );
};

export const getGetLawyerDocumentsTopQueryKey = () =>
  [`/lawyer_documents/top`] as const;

export const getGetLawyerDocumentsTopQueryOptions = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsTop>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getLawyerDocumentsTop>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getLawyerDocumentsTop>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLawyerDocumentsTopQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLawyerDocumentsTop>>
  > = ({ signal }) => getLawyerDocumentsTop(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLawyerDocumentsTopQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLawyerDocumentsTop>>
>;
export type GetLawyerDocumentsTopQueryError = ErrorType<Error>;

/**
 * @summary Get Top Lawyer Document
 */
export const useGetLawyerDocumentsTop = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsTop>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getLawyerDocumentsTop>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLawyerDocumentsTopQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Category Tree
 */
export const getLawyerDocumentsGetCategoryTree = (
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<CategoryTreeResponse>(
    { url: `/lawyer_documents/get_category_tree`, method: 'get', signal },
    options
  );
};

export const getGetLawyerDocumentsGetCategoryTreeQueryKey = () =>
  [`/lawyer_documents/get_category_tree`] as const;

export const getGetLawyerDocumentsGetCategoryTreeQueryOptions = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsGetCategoryTree>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getLawyerDocumentsGetCategoryTree>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getLawyerDocumentsGetCategoryTree>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLawyerDocumentsGetCategoryTreeQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLawyerDocumentsGetCategoryTree>>
  > = ({ signal }) => getLawyerDocumentsGetCategoryTree(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLawyerDocumentsGetCategoryTreeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLawyerDocumentsGetCategoryTree>>
>;
export type GetLawyerDocumentsGetCategoryTreeQueryError = ErrorType<Error>;

/**
 * @summary Get Category Tree
 */
export const useGetLawyerDocumentsGetCategoryTree = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsGetCategoryTree>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getLawyerDocumentsGetCategoryTree>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetLawyerDocumentsGetCategoryTreeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Search Engine Summary
 */
export const getLawyerDocumentsSearchSummary = (
  params?: GetLawyerDocumentsSearchSummaryParams,
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<SearchEngineSummaryResponse>(
    { url: `/lawyer_documents/search_summary`, method: 'get', params, signal },
    options
  );
};

export const getGetLawyerDocumentsSearchSummaryQueryKey = (
  params?: GetLawyerDocumentsSearchSummaryParams
) => [`/lawyer_documents/search_summary`, ...(params ? [params] : [])] as const;

export const getGetLawyerDocumentsSearchSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsSearchSummary>>,
  TError = ErrorType<Error>,
>(
  params?: GetLawyerDocumentsSearchSummaryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerDocumentsSearchSummary>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getLawyerDocumentsSearchSummary>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetLawyerDocumentsSearchSummaryQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLawyerDocumentsSearchSummary>>
  > = ({ signal }) =>
    getLawyerDocumentsSearchSummary(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLawyerDocumentsSearchSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLawyerDocumentsSearchSummary>>
>;
export type GetLawyerDocumentsSearchSummaryQueryError = ErrorType<Error>;

/**
 * @summary Search Engine Summary
 */
export const useGetLawyerDocumentsSearchSummary = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsSearchSummary>>,
  TError = ErrorType<Error>,
>(
  params?: GetLawyerDocumentsSearchSummaryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerDocumentsSearchSummary>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLawyerDocumentsSearchSummaryQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get paginated search summary
 */
export const getLawyerDocumentsPaginatedSearchSummary = (
  params: GetLawyerDocumentsPaginatedSearchSummaryParams,
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<PaginatedSearchSummaryResponse>(
    {
      url: `/lawyer_documents/paginated_search_summary`,
      method: 'get',
      params,
      signal,
    },
    options
  );
};

export const getGetLawyerDocumentsPaginatedSearchSummaryQueryKey = (
  params: GetLawyerDocumentsPaginatedSearchSummaryParams
) =>
  [
    `/lawyer_documents/paginated_search_summary`,
    ...(params ? [params] : []),
  ] as const;

export const getGetLawyerDocumentsPaginatedSearchSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsPaginatedSearchSummary>>,
  TError = ErrorType<Error>,
>(
  params: GetLawyerDocumentsPaginatedSearchSummaryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerDocumentsPaginatedSearchSummary>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getLawyerDocumentsPaginatedSearchSummary>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetLawyerDocumentsPaginatedSearchSummaryQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLawyerDocumentsPaginatedSearchSummary>>
  > = ({ signal }) =>
    getLawyerDocumentsPaginatedSearchSummary(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLawyerDocumentsPaginatedSearchSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLawyerDocumentsPaginatedSearchSummary>>
>;
export type GetLawyerDocumentsPaginatedSearchSummaryQueryError =
  ErrorType<Error>;

/**
 * @summary Get paginated search summary
 */
export const useGetLawyerDocumentsPaginatedSearchSummary = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsPaginatedSearchSummary>>,
  TError = ErrorType<Error>,
>(
  params: GetLawyerDocumentsPaginatedSearchSummaryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerDocumentsPaginatedSearchSummary>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLawyerDocumentsPaginatedSearchSummaryQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get List of Available Lawyer Document Status
 */
export const getLawyerDocumentsStatusList = (
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<LawyerDocumentStatusEnumListResponse>(
    { url: `/lawyer_documents/status_list`, method: 'get', signal },
    options
  );
};

export const getGetLawyerDocumentsStatusListQueryKey = () =>
  [`/lawyer_documents/status_list`] as const;

export const getGetLawyerDocumentsStatusListQueryOptions = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsStatusList>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getLawyerDocumentsStatusList>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getLawyerDocumentsStatusList>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLawyerDocumentsStatusListQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLawyerDocumentsStatusList>>
  > = ({ signal }) => getLawyerDocumentsStatusList(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLawyerDocumentsStatusListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLawyerDocumentsStatusList>>
>;
export type GetLawyerDocumentsStatusListQueryError = ErrorType<Error>;

/**
 * @summary Get List of Available Lawyer Document Status
 */
export const useGetLawyerDocumentsStatusList = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsStatusList>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getLawyerDocumentsStatusList>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLawyerDocumentsStatusListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Add a New Lawyer Document Status Enum
 */
export const postLawyerDocumentsAddLawyerDocumentStatusEnum = (
  addLawyerDocumentStatusEnumRequest: BodyType<AddLawyerDocumentStatusEnumRequest>,
  options?: SecondParameter<typeof documentServiceRequest>
) => {
  return documentServiceRequest<void>(
    {
      url: `/lawyer_documents/add_lawyer_document_status_enum`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: addLawyerDocumentStatusEnumRequest,
    },
    options
  );
};

export const getPostLawyerDocumentsAddLawyerDocumentStatusEnumMutationOptions =
  <TError = ErrorType<Error>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof postLawyerDocumentsAddLawyerDocumentStatusEnum>
      >,
      TError,
      { data: BodyType<AddLawyerDocumentStatusEnumRequest> },
      TContext
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof postLawyerDocumentsAddLawyerDocumentStatusEnum>>,
    TError,
    { data: BodyType<AddLawyerDocumentStatusEnumRequest> },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof postLawyerDocumentsAddLawyerDocumentStatusEnum>
      >,
      { data: BodyType<AddLawyerDocumentStatusEnumRequest> }
    > = (props) => {
      const { data } = props ?? {};

      return postLawyerDocumentsAddLawyerDocumentStatusEnum(
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostLawyerDocumentsAddLawyerDocumentStatusEnumMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postLawyerDocumentsAddLawyerDocumentStatusEnum>>
  >;
export type PostLawyerDocumentsAddLawyerDocumentStatusEnumMutationBody =
  BodyType<AddLawyerDocumentStatusEnumRequest>;
export type PostLawyerDocumentsAddLawyerDocumentStatusEnumMutationError =
  ErrorType<Error>;

/**
 * @summary Add a New Lawyer Document Status Enum
 */
export const usePostLawyerDocumentsAddLawyerDocumentStatusEnum = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLawyerDocumentsAddLawyerDocumentStatusEnum>>,
    TError,
    { data: BodyType<AddLawyerDocumentStatusEnumRequest> },
    TContext
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}) => {
  const mutationOptions =
    getPostLawyerDocumentsAddLawyerDocumentStatusEnumMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Add a New Lawyer Document Status
 */
export const postLawyerDocumentsAddLawyerDocumentStatus = (
  addLawyerDocumentStatusRequest: BodyType<AddLawyerDocumentStatusRequest>,
  options?: SecondParameter<typeof documentServiceRequest>
) => {
  return documentServiceRequest<BasicLawyerDocumentStatusResponse>(
    {
      url: `/lawyer_documents/add_lawyer_document_status`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: addLawyerDocumentStatusRequest,
    },
    options
  );
};

export const getPostLawyerDocumentsAddLawyerDocumentStatusMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLawyerDocumentsAddLawyerDocumentStatus>>,
    TError,
    { data: BodyType<AddLawyerDocumentStatusRequest> },
    TContext
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postLawyerDocumentsAddLawyerDocumentStatus>>,
  TError,
  { data: BodyType<AddLawyerDocumentStatusRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postLawyerDocumentsAddLawyerDocumentStatus>>,
    { data: BodyType<AddLawyerDocumentStatusRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return postLawyerDocumentsAddLawyerDocumentStatus(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostLawyerDocumentsAddLawyerDocumentStatusMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postLawyerDocumentsAddLawyerDocumentStatus>>
  >;
export type PostLawyerDocumentsAddLawyerDocumentStatusMutationBody =
  BodyType<AddLawyerDocumentStatusRequest>;
export type PostLawyerDocumentsAddLawyerDocumentStatusMutationError =
  ErrorType<Error>;

/**
 * @summary Add a New Lawyer Document Status
 */
export const usePostLawyerDocumentsAddLawyerDocumentStatus = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLawyerDocumentsAddLawyerDocumentStatus>>,
    TError,
    { data: BodyType<AddLawyerDocumentStatusRequest> },
    TContext
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}) => {
  const mutationOptions =
    getPostLawyerDocumentsAddLawyerDocumentStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Edit a Lawyer Document Status
 */
export const postLawyerDocumentsEditLawyerDocumentStatus = (
  editLawyerDocumentStatusRequest: BodyType<EditLawyerDocumentStatusRequest>,
  options?: SecondParameter<typeof documentServiceRequest>
) => {
  return documentServiceRequest<BasicLawyerDocumentStatusResponse>(
    {
      url: `/lawyer_documents/edit_lawyer_document_status`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: editLawyerDocumentStatusRequest,
    },
    options
  );
};

export const getPostLawyerDocumentsEditLawyerDocumentStatusMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLawyerDocumentsEditLawyerDocumentStatus>>,
    TError,
    { data: BodyType<EditLawyerDocumentStatusRequest> },
    TContext
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postLawyerDocumentsEditLawyerDocumentStatus>>,
  TError,
  { data: BodyType<EditLawyerDocumentStatusRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postLawyerDocumentsEditLawyerDocumentStatus>>,
    { data: BodyType<EditLawyerDocumentStatusRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return postLawyerDocumentsEditLawyerDocumentStatus(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostLawyerDocumentsEditLawyerDocumentStatusMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postLawyerDocumentsEditLawyerDocumentStatus>>
  >;
export type PostLawyerDocumentsEditLawyerDocumentStatusMutationBody =
  BodyType<EditLawyerDocumentStatusRequest>;
export type PostLawyerDocumentsEditLawyerDocumentStatusMutationError =
  ErrorType<Error>;

/**
 * @summary Edit a Lawyer Document Status
 */
export const usePostLawyerDocumentsEditLawyerDocumentStatus = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLawyerDocumentsEditLawyerDocumentStatus>>,
    TError,
    { data: BodyType<EditLawyerDocumentStatusRequest> },
    TContext
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}) => {
  const mutationOptions =
    getPostLawyerDocumentsEditLawyerDocumentStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete a Lawyer Document Status
 */
export const deleteLawyerDocumentsDeleteLawyerDocumentStatusId = (
  id: string,
  options?: SecondParameter<typeof documentServiceRequest>
) => {
  return documentServiceRequest<void>(
    {
      url: `/lawyer_documents/delete_lawyer_document_status/${id}`,
      method: 'delete',
    },
    options
  );
};

export const getDeleteLawyerDocumentsDeleteLawyerDocumentStatusIdMutationOptions =
  <TError = ErrorType<Error>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof deleteLawyerDocumentsDeleteLawyerDocumentStatusId>
      >,
      TError,
      { id: string },
      TContext
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteLawyerDocumentsDeleteLawyerDocumentStatusId>
    >,
    TError,
    { id: string },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof deleteLawyerDocumentsDeleteLawyerDocumentStatusId>
      >,
      { id: string }
    > = (props) => {
      const { id } = props ?? {};

      return deleteLawyerDocumentsDeleteLawyerDocumentStatusId(
        id,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteLawyerDocumentsDeleteLawyerDocumentStatusIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof deleteLawyerDocumentsDeleteLawyerDocumentStatusId>
    >
  >;

export type DeleteLawyerDocumentsDeleteLawyerDocumentStatusIdMutationError =
  ErrorType<Error>;

/**
 * @summary Delete a Lawyer Document Status
 */
export const useDeleteLawyerDocumentsDeleteLawyerDocumentStatusId = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteLawyerDocumentsDeleteLawyerDocumentStatusId>
    >,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}) => {
  const mutationOptions =
    getDeleteLawyerDocumentsDeleteLawyerDocumentStatusIdMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
/**
 * @summary Edit a New Lawyer Document
 */
export const postLawyerDocumentsEditLawyerDocument = (
  editLawyerDocumentRequest: BodyType<EditLawyerDocumentRequest>,
  options?: SecondParameter<typeof documentServiceRequest>
) => {
  return documentServiceRequest<LawyerDocumentResponse>(
    {
      url: `/lawyer_documents/edit_lawyer_document`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: editLawyerDocumentRequest,
    },
    options
  );
};

export const getPostLawyerDocumentsEditLawyerDocumentMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLawyerDocumentsEditLawyerDocument>>,
    TError,
    { data: BodyType<EditLawyerDocumentRequest> },
    TContext
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postLawyerDocumentsEditLawyerDocument>>,
  TError,
  { data: BodyType<EditLawyerDocumentRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postLawyerDocumentsEditLawyerDocument>>,
    { data: BodyType<EditLawyerDocumentRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return postLawyerDocumentsEditLawyerDocument(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostLawyerDocumentsEditLawyerDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof postLawyerDocumentsEditLawyerDocument>>
>;
export type PostLawyerDocumentsEditLawyerDocumentMutationBody =
  BodyType<EditLawyerDocumentRequest>;
export type PostLawyerDocumentsEditLawyerDocumentMutationError =
  ErrorType<Error>;

/**
 * @summary Edit a New Lawyer Document
 */
export const usePostLawyerDocumentsEditLawyerDocument = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLawyerDocumentsEditLawyerDocument>>,
    TError,
    { data: BodyType<EditLawyerDocumentRequest> },
    TContext
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}) => {
  const mutationOptions =
    getPostLawyerDocumentsEditLawyerDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Add a New Lawyer Document
 */
export const postLawyerDocumentsAddLawyerDocument = (
  addLawyerDocumentRequest: BodyType<AddLawyerDocumentRequest>,
  options?: SecondParameter<typeof documentServiceRequest>
) => {
  const formData = new FormData();
  if (addLawyerDocumentRequest.file !== undefined) {
    formData.append('file', addLawyerDocumentRequest.file);
  }
  formData.append(
    'main_category_id',
    addLawyerDocumentRequest.main_category_id
  );
  formData.append('category_id', addLawyerDocumentRequest.category_id);
  if (addLawyerDocumentRequest.subcategory_id !== undefined) {
    formData.append('subcategory_id', addLawyerDocumentRequest.subcategory_id);
  }
  formData.append('judul', addLawyerDocumentRequest.judul);
  formData.append('nomor_surat', addLawyerDocumentRequest.nomor_surat);
  if (addLawyerDocumentRequest.nomor_perkara !== undefined) {
    formData.append('nomor_perkara', addLawyerDocumentRequest.nomor_perkara);
  }
  formData.append('tahun', addLawyerDocumentRequest.tahun.toString());
  if (addLawyerDocumentRequest.status_pihak !== undefined) {
    formData.append('status_pihak', addLawyerDocumentRequest.status_pihak);
  }
  if (addLawyerDocumentRequest.jenis_perkara !== undefined) {
    formData.append('jenis_perkara', addLawyerDocumentRequest.jenis_perkara);
  }
  if (addLawyerDocumentRequest.lama_proses !== undefined) {
    formData.append('lama_proses', addLawyerDocumentRequest.lama_proses);
  }
  if (addLawyerDocumentRequest.tanggal_penetapan !== undefined) {
    formData.append(
      'tanggal_penetapan',
      addLawyerDocumentRequest.tanggal_penetapan.toString()
    );
  }
  if (addLawyerDocumentRequest.tempat_penetapan !== undefined) {
    formData.append(
      'tempat_penetapan',
      addLawyerDocumentRequest.tempat_penetapan
    );
  }
  if (addLawyerDocumentRequest.amar_putusan_summarized !== undefined) {
    formData.append(
      'amar_putusan_summarized',
      addLawyerDocumentRequest.amar_putusan_summarized
    );
  }
  if (addLawyerDocumentRequest.tergugat !== undefined) {
    formData.append('tergugat', addLawyerDocumentRequest.tergugat);
  }
  if (addLawyerDocumentRequest.pengacara_tergugat !== undefined) {
    formData.append(
      'pengacara_tergugat',
      addLawyerDocumentRequest.pengacara_tergugat
    );
  }
  if (addLawyerDocumentRequest.penggugat !== undefined) {
    formData.append('penggugat', addLawyerDocumentRequest.penggugat);
  }
  if (addLawyerDocumentRequest.pengacara_penggugat !== undefined) {
    formData.append(
      'pengacara_penggugat',
      addLawyerDocumentRequest.pengacara_penggugat
    );
  }
  if (addLawyerDocumentRequest.jaksa_penuntut_umum !== undefined) {
    formData.append(
      'jaksa_penuntut_umum',
      addLawyerDocumentRequest.jaksa_penuntut_umum
    );
  }
  if (addLawyerDocumentRequest.ketua_hakim !== undefined) {
    formData.append('ketua_hakim', addLawyerDocumentRequest.ketua_hakim);
  }
  if (addLawyerDocumentRequest.hakim_anggota !== undefined) {
    formData.append('hakim_anggota', addLawyerDocumentRequest.hakim_anggota);
  }
  if (addLawyerDocumentRequest.dasar_hukum !== undefined) {
    formData.append('dasar_hukum', addLawyerDocumentRequest.dasar_hukum);
  }
  if (addLawyerDocumentRequest.jenis_kerugian !== undefined) {
    formData.append('jenis_kerugian', addLawyerDocumentRequest.jenis_kerugian);
  }
  if (addLawyerDocumentRequest.nama_kurator !== undefined) {
    formData.append('nama_kurator', addLawyerDocumentRequest.nama_kurator);
  }
  if (addLawyerDocumentRequest.summary_penggugat !== undefined) {
    formData.append(
      'summary_penggugat',
      addLawyerDocumentRequest.summary_penggugat
    );
  }
  if (addLawyerDocumentRequest.summary_tergugat !== undefined) {
    formData.append(
      'summary_tergugat',
      addLawyerDocumentRequest.summary_tergugat
    );
  }
  if (addLawyerDocumentRequest.summary_pertimbangan_hakim !== undefined) {
    formData.append(
      'summary_pertimbangan_hakim',
      addLawyerDocumentRequest.summary_pertimbangan_hakim
    );
  }
  if (addLawyerDocumentRequest.summary_keputusan !== undefined) {
    formData.append(
      'summary_keputusan',
      addLawyerDocumentRequest.summary_keputusan
    );
  }

  return documentServiceRequest<LawyerDocumentResponse>(
    {
      url: `/lawyer_documents/add_lawyer_document`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  );
};

export const getPostLawyerDocumentsAddLawyerDocumentMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLawyerDocumentsAddLawyerDocument>>,
    TError,
    { data: BodyType<AddLawyerDocumentRequest> },
    TContext
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postLawyerDocumentsAddLawyerDocument>>,
  TError,
  { data: BodyType<AddLawyerDocumentRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postLawyerDocumentsAddLawyerDocument>>,
    { data: BodyType<AddLawyerDocumentRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return postLawyerDocumentsAddLawyerDocument(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostLawyerDocumentsAddLawyerDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof postLawyerDocumentsAddLawyerDocument>>
>;
export type PostLawyerDocumentsAddLawyerDocumentMutationBody =
  BodyType<AddLawyerDocumentRequest>;
export type PostLawyerDocumentsAddLawyerDocumentMutationError =
  ErrorType<Error>;

/**
 * @summary Add a New Lawyer Document
 */
export const usePostLawyerDocumentsAddLawyerDocument = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLawyerDocumentsAddLawyerDocument>>,
    TError,
    { data: BodyType<AddLawyerDocumentRequest> },
    TContext
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}) => {
  const mutationOptions =
    getPostLawyerDocumentsAddLawyerDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
