import './style.scss';

import { HTMLAttributes, useState } from 'react';

import { Col, Row, Space, Typography } from 'antd';

import { BasicCase, Case } from 'api/generated/models/case';
import { formatSupportingResourceLink } from 'utils/format-supporting-resource-link';

const DESCRIPTION_ELLIPSIS_ROWS = 3;

export interface CaseModalContentProps {
  consultationCase?: Case | BasicCase;
}

function EllipsisSymbol({ className }: HTMLAttributes<HTMLDivElement>) {
  return (
    <Typography.Text className={className} type="secondary">
      Lihat lebih banyak
    </Typography.Text>
  );
}

export function CaseModalContent({ consultationCase }: CaseModalContentProps) {
  const [isEllipsis, setIsEllipsis] = useState<boolean>(false);
  if (!consultationCase) return null;

  const supportingResourceLink = consultationCase.supporting_resource
    ? formatSupportingResourceLink(consultationCase.supporting_resource)
    : undefined;

  return (
    <Space className="hk-case-modal-content" direction="vertical">
      <Row>
        <Col span={24}>
          <Space direction="vertical">
            <Typography.Title className="hk-margin-bottom-sm font-16 weight-500 hk-color-black-title">
              <Space className="consultation-dialog-topic-title">
                <span>Topik: </span>
                <span>{consultationCase.topic}</span>
              </Space>
            </Typography.Title>
            <div className="description">
              <Typography.Paragraph
                ellipsis={{
                  rows: DESCRIPTION_ELLIPSIS_ROWS,
                  onEllipsis: (ellipsis) => setIsEllipsis(ellipsis),
                  symbol: (
                    <EllipsisSymbol
                      className={isEllipsis ? 'description__expand' : ''}
                    />
                  ),
                  expandable: true,
                }}
                type="secondary">
                {consultationCase.description}
              </Typography.Paragraph>
            </div>
          </Space>
        </Col>
      </Row>

      {Boolean(supportingResourceLink) && (
        <Typography.Link
          href={supportingResourceLink}
          target="_blank"
          underline>
          Lihat Tautan
        </Typography.Link>
      )}
    </Space>
  );
}
