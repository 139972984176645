import './style.scss';

import { useMemo, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Col, Grid, Layout, Row, Space } from 'antd';

import { ReactComponent as HukumkuLogo } from 'assets/svg/header-logo.svg';
import { ReactComponent as HukumkuLogoMobile } from 'assets/svg/header-logo-mobile.svg';

import { AvatarDropdown, StatusSelect } from './components';
import { MobileSidebarToggle } from './components/MobileSidebarToggle';

export function PageHeader() {
  const screens = Grid.useBreakpoint();
  const location = useLocation();
  const headerRef = useRef<HTMLDivElement>(null);

  const showStatusAndNotification = useMemo(() => {
    // TODO: get user status from BE instead
    const excludePath = [
      '/verification',
      '/verification/waiting',
      '/verification/reject',
      '/verification/banned',
    ];
    return !excludePath?.includes(location?.pathname);
  }, [location]);

  return (
    <Layout.Header className="hk-header" id="page-header" ref={headerRef}>
      <Row align="middle" className="h-full" justify="space-between">
        <Col>
          <Link to="/">
            <Row align="middle">
              {screens.md ? <HukumkuLogo /> : <HukumkuLogoMobile />}
            </Row>
          </Link>
        </Col>
        <Col>
          <Space align="center" size={screens.md ? 35 : 14}>
            {showStatusAndNotification && <StatusSelect />}
            {screens.md || (!screens.md && !showStatusAndNotification) ? (
              <AvatarDropdown disableDropdown={!showStatusAndNotification} />
            ) : (
              <MobileSidebarToggle />
            )}
          </Space>
        </Col>
      </Row>
    </Layout.Header>
  );
}
