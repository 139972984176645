import { Consultation, GetConsultationsId200 } from 'api/generated/models/case';
import {
  useGetConsultationsId,
  usePatchConsultationsIdStatus,
} from 'api/generated/services/case/consultation/consultation';
import { ENDED_CONSULTATION_STATUS } from 'config/constants';
import { ConsultationStatus } from 'types/enums';

export interface UseEndConsultationOptions {
  consultation?: Consultation;
  onSuccessfullyEnded?: () => void;
}

export function useEndConsultation({
  consultation,
  onSuccessfullyEnded,
}: UseEndConsultationOptions) {
  const handlePatchConsultationSuccess = () => {
    onSuccessfullyEnded?.();
  };

  const {
    mutate: patchConsultationStatus,
    isLoading: isLoadingPatchConsultationStatus,
  } = usePatchConsultationsIdStatus({
    request: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    mutation: {
      onSuccess: handlePatchConsultationSuccess,
    },
  });

  const {
    refetch: refetchConsultation,
    isRefetching: isLoadingRefetchConsultation,
  } = useGetConsultationsId(consultation?.id || '', {
    query: {
      enabled: false,
    },
  });

  const handleRefetchConsultationSuccess = (data?: GetConsultationsId200) => {
    const { data: latestConsultation } = data || {};
    if (!latestConsultation?.id) return;

    const isOngoing = !ENDED_CONSULTATION_STATUS.includes(
      latestConsultation.status as ConsultationStatus
    );
    if (isOngoing) {
      patchConsultationStatus({
        id: latestConsultation?.id,
        data: {
          status: ConsultationStatus.finished,
        },
      });
    } else {
      handlePatchConsultationSuccess();
    }
  };

  const endConsultation = async () => {
    const { data } = await refetchConsultation();
    handleRefetchConsultationSuccess(data);
  };

  const isLoadingEndConsultation =
    isLoadingRefetchConsultation || isLoadingPatchConsultationStatus;

  return {
    endConsultation,
    isLoadingEndConsultation,
  };
}
