import { useState } from 'react';

export const useOfferLegalDrafts = () => {
  const [isCreateOfferModalOpen, setIsCreateOfferModalOpen] = useState(false);
  const onOpenCreateLegalDraftsModal = () => setIsCreateOfferModalOpen(true);
  const onCloseCreateLegalDraftsModal = () => setIsCreateOfferModalOpen(false);

  return {
    isCreateOfferModalOpen,
    onOpenCreateLegalDraftsModal,
    onCloseCreateLegalDraftsModal,
  };
};
