import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss';

import { useLocation } from 'react-router-dom';
import Slider from 'react-slick';

import { Typography } from 'antd';

import { AUTH_SLIDE } from './constant';

const Slideshow = () => {
  const { pathname } = useLocation();
  const isLogin = pathname === '/auth/login';

  const SETTING = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 7000,
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    centerMode: true,
    centerPadding: '0',
  };
  return (
    <div className="slide-container w-full hk-padding-left-xl hk-padding-right-xl flex flex-column center">
      {isLogin ? (
        <div className="slide-header">
          <Typography.Paragraph className="hk-align-center hk-color-white text-title">
            TIP #1: Aktifkan status online dan pantau terus konsultasi yang
            masuk ke Akun Anda
          </Typography.Paragraph>
        </div>
      ) : null}

      <Slider {...SETTING}>
        {AUTH_SLIDE.map((eachSlide) => (
          <div className="slide-wrapper " key={eachSlide.title}>
            <img alt={eachSlide.title} src={eachSlide.src} />
            <Typography.Paragraph
              className="slide-text-title hk-align-center hk-color-white"
              color="#fff">
              {eachSlide.title}
            </Typography.Paragraph>
            <Typography.Paragraph className="slide-text-caption hk-padding-bottom-xl hk-margin-top-md hk-align-center hk-color-white">
              {eachSlide.caption}
            </Typography.Paragraph>
          </div>
        ))}
      </Slider>
    </div>
  );
};
export { Slideshow };
