import { AxiosError, AxiosRequestConfig } from 'axios';
import QueryString from 'qs';

import { generateUrlPath } from 'utils';

import { createClient } from './create-client';

const client = createClient();

export const caseServiceRequest = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const { url, ...restOfConfig } = config;
  const prefixedUrl = generateUrlPath({
    service: 'CASE',
    path: url || '',
  });
  const response = await client({
    url: prefixedUrl,
    paramsSerializer(params) {
      return QueryString.stringify(params, { arrayFormat: 'repeat' });
    },
    ...restOfConfig,
    ...options,
  });
  return response?.data;
};

export type ErrorType<Error> = AxiosError<Error>;

export type BodyType<BodyData> = BodyData;
