import './style.scss';

import { Col, Collapse, CollapseProps, Row, Typography } from 'antd';

export const OtherInformations = () => {
  const items: CollapseProps['items'] = [
    {
      key: 'contact-us',
      label: (
        <Typography.Text className="font-12 weight-400 hk-color-black-title">
          Hubungi Kami
        </Typography.Text>
      ),
    },
    {
      key: 'report-client',
      label: (
        <Typography.Text className="font-12 weight-400 hk-color-black-title">
          Report Klien
        </Typography.Text>
      ),
    },
  ];
  return (
    <Row className="hk-margin-top-lg" gutter={[0, 12]}>
      <Col span={24}>
        <Typography.Text className="font-20 weight-500 hk-color-black-title">
          Informasi Lainnya
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Collapse
          accordion
          className="forum-collapse"
          expandIconPosition="end"
          items={items}
        />
      </Col>
    </Row>
  );
};
