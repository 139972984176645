import { Result } from 'antd';

import { ReactComponent as HourglassIc } from 'assets/svg/verification-waiting-ic.svg';

interface PlaceholderComingSoonProps {
  description?: string;
}

export const PlaceholderComingSoon = ({
  description = 'Saat ini kami sedang melakukan pengerjaan untuk membangun halaman ini',
}: PlaceholderComingSoonProps) => {
  return (
    <Result
      className="w-full h-full hk-padding-md hk-margin-none"
      icon={<HourglassIc />}
      subTitle={description}
      title="Segera Hadir"
    />
  );
};
