import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useFlag } from 'hooks/useFlag';

import { PlaceholderComingSoon } from 'components';
import { DashboardLayout } from 'components/layouts/dashboard-layout';
import { DashboardLayoutLoading } from 'components/placeholder-loadings/dashboard-layout-loading';
import { FEATURE_FLAG } from 'config/feature-flag';
import { lazyNamedExport } from 'utils/lazy-named-export';

const AuthRoutes = lazyNamedExport(
  import(/* webpackChunkName: "AuthRoutes" */ './Auth'),
  'AuthRoutes'
);
const HomeRoutes = lazyNamedExport(
  import(/* webpackChunkName: "HomeRoutes" */ './Home'),
  'HomeRoutes'
);
const LegalDraftRoutes = lazyNamedExport(
  import(/* webpackChunkName: "LegalDraftingRoutes" */ './LegalDrafting'),
  'LegalDraftingRoutes'
);
const MessageRoutes = lazyNamedExport(
  import(/* webpackChunkName: "MessageRoute" */ './Message'),
  'MessageRoutes'
);
const MyCasesRoute = lazyNamedExport(
  import(/* webpackChunkName: "MyCasesRoute" */ './MyCases'),
  'MyCasesRoute'
);
const SettingRoutes = lazyNamedExport(
  import(/* webpackChunkName: "SettingRoute" */ './Setting'),
  'SettingRoutes'
);
const FindCaseRoutes = lazyNamedExport(
  import(/* webpackChunkName: "FindCase" */ './FindCase'),
  'FindCaseRoutes'
);
const QuickStart = lazyNamedExport(
  import(/* webpackChunkName: "QuickStart" */ '../pages/quick-start'),
  'QuickStart'
);
const Faq = lazyNamedExport(
  import(/* webpackChunkName: "Faq" */ '../pages/faq'),
  'Faq'
);
const VerificationRoutes = lazyNamedExport(
  import(/* webpackChunkName: "VerificationRoutes" */ './Verification'),
  'VerificationRoutes'
);

const VerificationBanned = lazyNamedExport(
  import(
    /* webpackChunkName: "VerificationBanned" */ 'pages/verification-banned'
  ),
  'VerificationBanned'
);
const LawyerRedirect = lazyNamedExport(
  import(/* webpackChunkName: "LawyerRedirect" */ 'pages/lawyer-redirect'),
  'LawyerRedirect'
);
const LegalAssistantRoutes = lazyNamedExport(
  import(/* webpackChunkName: "LegalAssistantRoutes" */ './LegalAssistant'),
  'LegalAssistantRoutes'
);

const NotFoundError = lazyNamedExport(
  import(
    /* webpackChunkName: "NotFoundError" */ 'components/placeholder-errors/not-found-error'
  ),
  'NotFoundError'
);

export const AppRoutes = () => {
  const ENABLE_LEGAL_DRAFTS = useFlag(FEATURE_FLAG.LEGAL_DRAFTS);

  return (
    <Routes>
      {/* Non Logged in Routes */}
      <Route path="/auth/*" element={<AuthRoutes />} />
      <Route path="/quick-start" element={<QuickStart />} />
      <Route path="/verification/banned" element={<VerificationBanned />} />
      <Route path="/public/profile" element={<LawyerRedirect />} />

      {/* Logged in Routes */}
      <Route
        path="/*"
        element={
          <Suspense fallback={<DashboardLayoutLoading />}>
            <Routes>
              <Route path="/" element={<DashboardLayout />}>
                <Route path="home/*" element={<HomeRoutes />} />
                <Route path="my-cases/*" element={<MyCasesRoute />} />
                <Route path="message/*" element={<MessageRoutes />} />
                <Route
                  path="legal-drafting/*"
                  element={
                    ENABLE_LEGAL_DRAFTS ? (
                      <LegalDraftRoutes />
                    ) : (
                      <PlaceholderComingSoon />
                    )
                  }
                />
                <Route path="setting/*" element={<SettingRoutes />} />
                <Route path="verification/*" element={<VerificationRoutes />} />
                <Route path="find-case/*" element={<FindCaseRoutes />} />
                <Route path="faq" element={<Faq />} />
                <Route
                  path="legal-assistant/*"
                  element={<LegalAssistantRoutes />}
                />

                <Route path="" element={<Navigate to="/home" replace />} />
                <Route path="*" element={<NotFoundError />} />
              </Route>
            </Routes>
          </Suspense>
        }
      />
    </Routes>
  );
};
