import './style.scss';

import { Col, Row, Skeleton } from 'antd';

export const CardSkeleton = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col className="h-full" md={12} xl={8} xs={24}>
        <Skeleton.Button
          active
          block
          className="legal-card-skeleton"
          shape="square"
          size="large"
        />
      </Col>
      <Col className="h-full legal-card-skeleton" md={12} xl={8} xs={24}>
        <Skeleton.Button
          active
          block
          className="legal-card-skeleton"
          shape="square"
          size="large"
        />
      </Col>
      <Col className="h-full legal-card-skeleton" md={12} xl={8} xs={24}>
        <Skeleton.Button
          active
          block
          className="legal-card-skeleton"
          shape="square"
          size="large"
        />
      </Col>
    </Row>
  );
};
