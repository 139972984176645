export enum ConsultationStatus {
  not_started = 'not_started',
  ongoing = 'ongoing',
  finished = 'finished',
  cancelled = 'cancelled',
  unresolved = 'unresolved',
  created = 'created',
  expired = 'expired',
}

export enum LegalDraftStatus {
  created = 'created',
  in_progress = 'in_progress',
  in_review = 'in_review',
  accepted = 'accepted',
  need_revise = 'need_revise',
  in_revise = 'in_revise',
  passed_due_date = 'passed_due_date',
  cancelled = 'cancelled',
  appealed = 'appealed',
  not_completed = 'not_completed',
}
