import { Message as BaseMessage } from '@chatscope/chat-ui-kit-react';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import { Message as TinodeMessage } from 'libs/tinode/types/sdk/Message';

import { useGetOrdersId } from 'api/generated/services/case/order/order';
import { useGetDocumentsId } from 'api/generated/services/document/default/default';
import { MESSAGE_VIOLATION_ERROR } from 'config/constants';
import { OfferCard } from 'pages/legal-drafting/components/card-legal-draft';

import { MessageDocument } from '../message-document';

export interface MessageProps {
  currentUserTopicName: string;
  message: TinodeMessage;
  isViolated: boolean;
  onOpenViolatedModal: () => void;
}

export function Message({
  currentUserTopicName,
  message,
  isViolated = false,
  onOpenViolatedModal,
}: MessageProps) {
  const textMessage =
    typeof message.content?.txt?.message === 'string'
      ? message.content?.txt?.message
      : '';
  const isDocument = message.content?.txt?.type === 'document';
  const documentId = message.content?.txt?.message;
  const isOrder = message.content?.type === 'order';
  const orderId = message.content?.message;
  const orderChatOwner = message.content?.on_behalf;
  const direction =
    message.from === `usr${currentUserTopicName}` || orderChatOwner === 'lawyer'
      ? 'outgoing'
      : 'incoming';
  const { data: documentData, isLoading: isLoadingDocument } =
    useGetDocumentsId(documentId, {
      query: {
        enabled: isDocument,
      },
    });

  const { data: ordersData } = useGetOrdersId(orderId ?? '', {
    query: {
      enabled: !!orderId,
    },
  });

  const documentModelPayload = isLoadingDocument ? (
    <Spin />
  ) : (
    <BaseMessage.CustomContent>
      {documentData?.data ? (
        <MessageDocument document={documentData?.data} />
      ) : (
        <Spin />
      )}
    </BaseMessage.CustomContent>
  );
  const violationPayload = (
    <BaseMessage.CustomContent>
      <div className="cs-message__text-content">{MESSAGE_VIOLATION_ERROR}</div>
      <div
        className="cs-message__text-content hk-margin-top-md weight-600 hk-cursor-pointer"
        onClick={onOpenViolatedModal}>
        Cek panduan
      </div>
    </BaseMessage.CustomContent>
  );
  const legalDraftPayload = (
    <BaseMessage.CustomContent>
      <OfferCard orders={ordersData?.data} />
    </BaseMessage.CustomContent>
  );
  const sentTime = dayjs(message.ts).format('HH:mm');

  const modelPayload = () => {
    if (isDocument) {
      return documentModelPayload;
    }

    if (isViolated) {
      return violationPayload;
    }

    if (isOrder) {
      return legalDraftPayload;
    }

    return undefined;
  };

  return (
    <BaseMessage
      className={
        direction === 'incoming' ? 'message-incoming' : 'message-outgoing'
      }
      key={message.seq}
      model={{
        type: isDocument ? 'custom' : 'text',
        payload: modelPayload(),
        message: isDocument ? undefined : textMessage,
        direction,
        position: 'single',
        sentTime,
      }}>
      <BaseMessage.Footer sentTime={sentTime} />
    </BaseMessage>
  );
}
