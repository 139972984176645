import { useState } from 'react';

export const useClientIssue = () => {
  const [isIssueModalOpen, setIsIssueModalOpen] = useState(false);

  const onOpenIssueModal = () => setIsIssueModalOpen(true);
  const onCloseIssueModal = () => setIsIssueModalOpen(false);

  return { isIssueModalOpen, onOpenIssueModal, onCloseIssueModal };
};
