import Step0 from 'assets/img/legal-drafts-guidelines/step_0.webp';
import Step1 from 'assets/img/legal-drafts-guidelines/step_1.webp';
import Step2 from 'assets/img/legal-drafts-guidelines/step_2.webp';
import Step3 from 'assets/img/legal-drafts-guidelines/step_3.webp';
import Step4 from 'assets/img/legal-drafts-guidelines/step_4.webp';
import Step5 from 'assets/img/legal-drafts-guidelines/step_5.webp';
import Step6 from 'assets/img/legal-drafts-guidelines/step_6.webp';
import Step7 from 'assets/img/legal-drafts-guidelines/step_7.webp';
import Step8 from 'assets/img/legal-drafts-guidelines/step_8.webp';
import Step9 from 'assets/img/legal-drafts-guidelines/step_9.webp';
import Step10 from 'assets/img/legal-drafts-guidelines/step_10.webp';
import Step11 from 'assets/img/legal-drafts-guidelines/step_11.webp';
import Step12 from 'assets/img/legal-drafts-guidelines/step_12.webp';

export const LEGAL_DRAFTS_GUIDEBOOK = [
  {
    id: 1,
    imageUrl: Step0,
    title: 'Buat Penawaran',
    subtitle:
      'Anda dapat membuat penawaran saat sesi konsultasi telah selesai dilakukan',
  },
  {
    id: 2,
    imageUrl: Step1,
    title: 'Detail Penawaran',
    subtitle:
      'Setelah selesai konsultasi, Anda dapat membuat penawaran dan melengkapi detail penawaran yang ingin dibuat.',
  },
  {
    id: 3,
    imageUrl: Step2,
    title: 'Menunggu Pembayaran',
    subtitle:
      'Setelah submit penawaran, Anda dapat menunggu klien melakukan pembayaran agar dapat mengupload dokumen.',
  },
  {
    id: 4,
    imageUrl: Step3,
    title: 'Upload Dokumen',
    subtitle:
      'Setelah klien melakukan pembayaran, Anda dapat mengupload dokumen yang telah disepakati sebelumnya.',
  },
  {
    id: 5,
    imageUrl: Step4,
    title: 'Menunggu Ulasan',
    subtitle:
      'Setelah upload dokumen, Anda dapat menunggu klien untuk mengulas dokumen yang di upload.',
  },
  {
    id: 6,
    imageUrl: Step5,
    title: 'Meminta Revisi',
    subtitle: (
      <>
        Jika klien meminta revisi dari dokumen yang ada, Anda dapat melihat
        dokumen dengan status{' '}
        <span className="highlight">&lsquo;Butuh Revisi&rsquo;</span>.
      </>
    ),
  },
  {
    id: 7,
    imageUrl: Step6,
    title: 'Melakukan Revisi',
    subtitle: (
      <>
        Jika Anda ingin melakukan revisi, Anda dapat menerima revisi, dan
        mengupload kembali dokumen sesuai revisi di kemudian hari. Status akan
        berubah menjadi{' '}
        <span className="highlight">&lsquo;Sedang Direvisi&rsquo;</span>.
      </>
    ),
  },
  {
    id: 8,
    imageUrl: Step7,
    title: 'Re-Upload Dokumen',
    subtitle: (
      <>
        Setelah selesai melakukan perbaikan, Anda dapat mengupload ulang dokumen
        yang telah selesai diperbaiki. Setelah itu status akan berubah menjadi{' '}
        <span className="highlight">&lsquo;Sedang Direvisi&rsquo;</span> oleh
        klien
      </>
    ),
  },
  {
    id: 9,
    imageUrl: Step8,
    title: 'Menolak Revisi',
    subtitle: (
      <>
        Jika Anda ingin menolak revisi dari klien, Anda dapat menolak revisi
        dari klien dengan klik{' '}
        <span className="highlight">&lsquo;Tolak Revisi&rsquo;</span>
      </>
    ),
  },
  {
    id: 10,
    imageUrl: Step9,
    title: 'Dokumen Diterima',
    subtitle:
      'Status dokumen akan selesai jika klien telah menerima dan tidak memiliki revisi lagi. Legal drafting akan selesai dilakukan.',
  },
  {
    id: 11,
    imageUrl: Step10,
    title: 'Dokumen Terlewatkan',
    subtitle: (
      <>
        Status dokumen{' '}
        <span className="highlight">&lsquo;Terlewatkan&rsquo;</span> terjadi
        apabila klien belum melakukan penyelesaian dari batas waktu yang telah
        ditentukan.
      </>
    ),
  },
  {
    id: 12,
    imageUrl: Step11,
    title: 'Dokumen Belum Selesai',
    subtitle: (
      <>
        Jika status dokumen{' '}
        <span className="highlight">&lsquo;Belum Selesai&rsquo;</span>{' '}
        menandakan bahwa klien melakukan banding dengan Hukumku.
      </>
    ),
  },
  {
    id: 13,
    imageUrl: Step12,
    title: 'Informasi Lanjutan',
    subtitle:
      'Pada saat klien mengajukan banding, Anda dapat menunggu Admin Hukumku untuk menghubungi Anda, dan melihat apakah banding klien diterima atau ditolak oleh Admin.',
  },
];
