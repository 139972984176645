import { useEffect, useRef, useState } from 'react';

import {
  DANGER_TIMER_THRESHOLD_SECONDS,
  TIMER_INTERVAL_MILLISECONDS,
  WARNING_TIMER_THRESHOLD_SECONDS,
} from 'config/constants';

export interface UseCountDownTimerParams {
  countDownSecondsLeft?: number | null;
  options?: {
    dangerTimerThresholdSeconds?: number;
    enabled?: boolean;
    warningTimerThresholdSeconds?: number;
  };
}

export function useCountDownTimer({
  countDownSecondsLeft = 0,
  options = {},
}: UseCountDownTimerParams = {}) {
  const enabled =
    typeof options?.enabled === 'boolean' ? options.enabled : true;

  const timer = useRef<any>(null);
  const [displayedSecondsLeft, setDisplayedSecondsLeft] = useState<
    number | null
  >(null);

  const getTimerClassName = (secondsLeft: number | null) => {
    const dangerTimerThresholdSeconds =
      options?.dangerTimerThresholdSeconds || DANGER_TIMER_THRESHOLD_SECONDS;
    const warningTimerThresholdSeconds =
      options?.warningTimerThresholdSeconds || WARNING_TIMER_THRESHOLD_SECONDS;

    if (typeof secondsLeft !== 'number') return '';

    if (secondsLeft <= dangerTimerThresholdSeconds)
      return 'hk-color-status-red';
    if (secondsLeft <= warningTimerThresholdSeconds)
      return 'hk-color-status-yellow';
    return 'hk-color-dark-green';
  };

  const timerClassName = getTimerClassName(displayedSecondsLeft);

  useEffect(() => {
    if (enabled && typeof countDownSecondsLeft === 'number') {
      setDisplayedSecondsLeft(countDownSecondsLeft);
    } else {
      clearInterval(timer.current);
    }
  }, [countDownSecondsLeft, enabled]);

  useEffect(() => {
    if (typeof countDownSecondsLeft !== 'number' || !enabled) return;

    if (countDownSecondsLeft > 0) {
      timer.current = setInterval(() => {
        setDisplayedSecondsLeft((prevSeconds) =>
          typeof prevSeconds === 'number' ? prevSeconds - 1 : 0
        );
      }, TIMER_INTERVAL_MILLISECONDS);
    }

    return () => {
      clearInterval(timer.current);
      timer.current = null;
    };
  }, [countDownSecondsLeft, enabled]);

  useEffect(() => {
    if (
      typeof displayedSecondsLeft === 'number' &&
      displayedSecondsLeft <= 0 &&
      timer.current
    ) {
      clearInterval(timer.current);
    }
  }, [displayedSecondsLeft]);

  return {
    displayedSecondsLeft,
    timerClassName,
  };
}
