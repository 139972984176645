import './style.scss';

import { useCallback, useEffect, useMemo, useState } from 'react';
import Countdown from 'react-countdown';
import OtpInput from 'react-otp-input';

import { Button, Form, Modal, Row, Typography } from 'antd';
import { useOtp } from 'hooks/useOtp';

import { FormError } from 'components/form-error';

import { CountdownProps, OtpModalProps } from './types';

const COUNTDOWN = 180 * 1000;

const OtpModal = ({
  context,
  credential,
  isModalOpen,
  loading,
  onCancel,
  onSubmit,
  message,
  onReset,
}: OtpModalProps) => {
  const [form] = Form.useForm();

  const { onChangeOtp, otp, setOtp } = useOtp();
  const [initCountdown, setInitCountdown] = useState<number>(0);

  const disableNextBtn = useMemo(
    () => otp.length !== 4 || loading,
    [loading, otp]
  );

  useEffect(() => {
    const startCountdown = () => {
      isModalOpen && setInitCountdown(Date.now() + COUNTDOWN);
    };
    startCountdown();
  }, [isModalOpen]);

  const handleOnChangeOtpInput = useCallback((value: string) => {
    onChangeOtp(value);
  }, [onChangeOtp]);

  const resetTimer = () => {
    onReset();
    setInitCountdown(Date.now() + COUNTDOWN);
  };

  const renderer = ({ minutes, seconds, completed }: CountdownProps) => {
    if (completed) {
      return (
        <Button onClick={resetTimer} type="ghost">
          <Typography.Paragraph className="font-14 weight-500 hk-color-heading-2">
            Tidak menerima kode?
            <span className="hk-color-heading-1"> Kirim ulang</span>
          </Typography.Paragraph>
        </Button>
      );
    } else {
      return (
        <Typography.Paragraph className="font-14 weight-500 hk-color-heading-2">
          Mohon tunggu{' '}
          <span className="hk-color-light-green">
            {minutes}:{seconds}
          </span>{' '}
          detik untuk kirim ulang
        </Typography.Paragraph>
      );
    }
  };

  const handleOnFinish = useCallback(() => {
    onSubmit(otp, context, credential);
    setOtp('');
  }, [onSubmit, otp, context, setOtp, credential]);

  return (
    <Modal
      className="otp-modal"
      destroyOnClose
      footer={false}
      onCancel={onCancel}
      open={isModalOpen}
      title={
        <Typography.Title className="text-title weight-500" color="#121926">
          Masukkan Kode OTP
        </Typography.Title>
      }
      width={520}>
      <Form form={form} id="otpForm" onFinish={handleOnFinish}>
        <Typography.Paragraph>
          {message && typeof message !== 'string' && { ...message }}
        </Typography.Paragraph>
        <OtpInput
          containerStyle="flex flex-row space-around"
          inputStyle="otp-input"
          numInputs={4}
          onChange={handleOnChangeOtpInput}
          placeholder=""
          renderInput={(props: any) => (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: <FormError message="OTP is required" />,
                },
              ]}>
              <input {...props} type="tel" />
            </Form.Item>
          )}
          value={otp}
        />
        <Row
          align="middle"
          className="hk-align-center hk-margin-top-md"
          justify="center">
          <Countdown
            autoStart={true}
            date={initCountdown}
            key={initCountdown}
            renderer={renderer}
          />
        </Row>

        <Button
          className="w-full hk-margin-top-sm weight-500"
          disabled={disableNextBtn}
          form="otpForm"
          htmlType="submit"
          loading={loading}
          type="primary">
          Verifikasi
        </Button>
      </Form>
    </Modal>
  );
};

export { OtpModal };
