import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Grid } from 'antd';

import { useCurrentUserStore } from 'store/current-user';

export function useResponsiveDashboardLayout() {
  const screens = Grid.useBreakpoint();
  const location = useLocation();
  const isAnnouncementBannerVisible = useCurrentUserStore(
    (state) => state.isAnnouncementBannerVisible
  );

  const syncLayoutSpacing = useCallback(() => {
    const announcementBanner = document.querySelector<HTMLDivElement>(
      '#announcement-banner'
    );
    const pageHeader = document.querySelector<HTMLDivElement>('#page-header');
    const pageSidebar = document.querySelector<HTMLDivElement>('#page-sidebar');

    const announcementBannerBottomOffset =
      announcementBanner?.getBoundingClientRect()?.bottom || 0;
    if (pageHeader) {
      pageHeader.style.top = screens.md
        ? ''
        : `${announcementBannerBottomOffset}px`;
    }

    const pageHeaderBottomOffset =
      pageHeader?.getBoundingClientRect()?.bottom || 0;
    const nextSibling = pageHeader?.nextSibling as HTMLDivElement;

    if (pageSidebar) {
      pageSidebar.style.top = screens.md ? '' : `${pageHeaderBottomOffset}px`;
    }
    if (nextSibling) {
      nextSibling.style.marginTop = screens.md
        ? ''
        : `${pageHeaderBottomOffset}px`;
    }
  }, [screens]);

  useEffect(() => {
    syncLayoutSpacing();
  }, [syncLayoutSpacing]);

  useEffect(() => {
    syncLayoutSpacing();
  }, [screens, syncLayoutSpacing]);

  useEffect(() => {
    syncLayoutSpacing();
  }, [isAnnouncementBannerVisible, syncLayoutSpacing]);

  useEffect(() => {
    syncLayoutSpacing();
  }, [location, syncLayoutSpacing]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);
}
