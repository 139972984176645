import { Dispatch, SetStateAction } from 'react';

import { ModalProps } from 'antd';

import {
  BasicLegalDraftLawyer,
  BasicOrderClientInfo,
  LegalDraftItemRevision,
} from 'api/generated/models/case';

export interface ForumDiscussionModalProps extends ModalProps {
  legalDraftId: string | null;
}

export type ActionType = 'accept-revision' | 'reject-revision' | 'upload';

export interface RevisionsProps {
  clientInfo: BasicOrderClientInfo | undefined;
  lawyerInfo: BasicLegalDraftLawyer | undefined;
  legalDraftItemId: string | undefined;
  missedBy: MissedBy;
  revisions: LegalDraftItemRevision[] | null;
  status: string;
}

export interface RevisionActionsProps {
  action: ActionType | null;
  setAction: Dispatch<SetStateAction<ActionType | null>>;
  id: string;
  status: string;
  lawyerInfo: BasicLegalDraftLawyer | undefined;
}

export type RevisionRole = 'client' | 'lawyer' | 'system';

export type MissedBy = 'missed by client' | 'missed by lawyer' | null;

export enum MissedByEnum {
  client = 'missed by client',
  lawyer = 'missed by lawyer',
}
