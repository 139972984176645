import { SelectProps } from 'antd';

import { Api, Services } from 'types';
import { ConsultationStatus } from 'types/enums';

export const DEBOUNCE_DEFAULT_DURATION_MILLISECONDS = 600;

export const CASE_DESCRIPTION_EXCERPT_MAX_CHAR_LENGTH = 80;

export const GENDER_OPTIONS: SelectProps['options'] = [
  {
    label: 'Laki-laki',
    value: 'male',
  },
  {
    label: 'Perempuan',
    value: 'female',
  },
];

export const DEGREE_OPTIONS: SelectProps['options'] = [
  {
    label: 'S1',
    value: 'bachelor',
  },
  {
    label: 'S2',
    value: 'master',
  },
  {
    label: 'S3',
    value: 'doctoral',
  },
];

export const EXPERIENCE_OPTIONS: SelectProps['options'] = [
  {
    label: '1 - 3 Tahun',
    value: '1_3_years',
  },
  {
    label: '4 - 7 Tahun',
    value: '4_7_years',
  },
  {
    label: '7 - 10 Tahun',
    value: '7_10_years',
  },
  {
    label: '>10 Tahun',
    value: 'more_than_10_years',
  },
];

export const PAYMENTS_OPTIONS: SelectProps['options'] = [
  // // Virtual Account
  // { label: 'BCA Virtual Account', value: 'bca_va' },
  // { label: 'Mandiri Virtual Account', value: 'mandiri_va' },
  // { label: 'BNI Virtual Account', value: 'bni_va' },
  // { label: 'BRI Virtual Account', value: 'bri_va' },
  // { label: 'Permata Virtual Account', value: 'permata_va' },

  // // E-Wallets
  // { label: 'GoPay', value: 'gopay' },
  // { label: 'OVO', value: 'ovo' },
  // { label: 'DANA', value: 'dana' },
  // { label: 'LinkAja', value: 'linkaja' },
  // { label: 'ShopeePay', value: 'shopeepay' },

  // // Direct Debit
  // { label: 'BCA KlikPay', value: 'bca_klikpay' },
  // { label: 'Mandiri Clickpay', value: 'mandiri_clickpay' },
  // { label: 'CIMB Clicks', value: 'cimb_clicks' },

  // // Credit Cards
  // { label: 'Credit Card', value: 'credit_card' },

  // // Retail Outlets
  // { label: 'Indomaret', value: 'indomaret' },
  // { label: 'Alfamart', value: 'alfamart' },
  // { label: 'Alfamidi', value: 'alfamidi' },
  { label: 'Pembayaran Penuh', value: 'full_payment' },
];

export const SERVICES: Record<Api, Services> = {
  AUTH: 'auth',
  CASE: 'case',
  DOCUMENT: 'document',
};

export const TIMER_INTERVAL_MILLISECONDS = 1000;

export const WARNING_TIMER_THRESHOLD_SECONDS = 900;

export const DANGER_TIMER_THRESHOLD_SECONDS = 300;

export const ACCEPT_CONSULTATION_WAITING_SECONDS = 300;

export const MAX_ONGOING_CONSULTATIONS_COUNT = 3;

export const ENDED_CONSULTATION_STATUS = [
  ConsultationStatus.cancelled,
  ConsultationStatus.expired,
  ConsultationStatus.finished,
  ConsultationStatus.unresolved,
];

export const MESSAGE_VIOLATION_ERROR =
  'Pesan ini tidak diizinkan untuk ditampilkan karena mengandung konten yang melanggar pedoman penggunaan kami.';

export const LOCALSTORAGE_KEY = {
  showGuideline: 'show_guide',
};

export const CONSULTATION_TYPE_MAP: Record<string, string> = {
  chat: 'Pesan Singkat',
};

export const HUBUNGI_KAMI_URL = 'https://wa.link/30r1d5';

export const SYARAT_KETENTUAN_URL =
  'https://www.hukumku.id/syarat-dan-ketentuan-advokat';

export const HUKUMKU_LANDING = 'https://www.hukumku.id';
