export function formatDisplayedLatestMessage(message: any): string {
  if (
    message?.content?.type === 'system' &&
    message?.content?.message === 'chat started'
  ) {
    return 'Memulai konsultasi';
  }

  if (message?.content?.txt?.type === 'document') return 'File';

  return message?.content?.txt?.message;
}
