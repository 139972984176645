import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Button, Col, Divider, Row, Space, Typography } from 'antd';

import { User } from 'api/generated/models/case';
import AdminAvatar from 'assets/svg/admin-avatar.svg';
import { ReactComponent as DownloadIcon } from 'assets/svg/download-ic.svg';
import { ReactComponent as PaperIc } from 'assets/svg/paper-ic.svg';

import { RevisionActions } from './RevisionAction';
import { RevisionHead } from './RevisionHead';
import {
  ActionType,
  MissedByEnum,
  RevisionRole,
  RevisionsProps,
} from './types';

export const Revisions = ({
  clientInfo,
  lawyerInfo,
  legalDraftItemId,
  revisions,
  status,
}: RevisionsProps) => {
  const [action, setAction] = useState<ActionType | null>(null);

  const commentFactory = (comment: MissedByEnum | string) => {
    if (comment === MissedByEnum.client) {
      return 'Status dokumen telah diselesaikan oleh Sistem.';
    }

    if (comment === MissedByEnum.lawyer) {
      return 'Status dokumen telah diselesaikan oleh Admin.';
    }

    return comment;
  };

  return (
    <Space className="hk-margin-top-lg w-full" direction="vertical">
      {revisions?.map(
        (
          { id: revisionId, document, comments, created_at: createdAtRevision },
          revIndex
        ) => {
          const isLastRevision = revIndex + 1 === revisions.length;

          return (
            <Row key={revisionId}>
              {revIndex !== 0 && <Divider />}
              <Col span={24}>
                <RevisionHead
                  createdAt={createdAtRevision ?? 0}
                  role="lawyer"
                  user={lawyerInfo as User}
                />
                <Row
                  align="middle"
                  className="w-full"
                  gutter={[12, 0]}
                  justify="start">
                  <Col>
                    <Space direction="horizontal" size={8}>
                      <PaperIc />
                      <Typography.Text className="weight-400 font-14 hk-color-black-title">
                        {document?.name}
                      </Typography.Text>
                    </Space>
                  </Col>
                  <Col>
                    <Link
                      download={document?.name}
                      rel="noopener noreferrer"
                      target="_blank"
                      to={document?.url ?? ''}>
                      <Button
                        icon={<DownloadIcon color="#000" />}
                        size="small"
                        type="text"
                      />
                    </Link>
                  </Col>
                </Row>
              </Col>

              {comments?.length
                ? comments.map(
                    (
                      {
                        comment,
                        created_at: createdAtComment,
                        from_role,
                        id: commentId,
                      },
                      commentIndex
                    ) => {
                      const isLastComment =
                        commentIndex + 1 === comments.length;
                      const role = (from_role as RevisionRole) ?? 'client';
                      const adminAvatar = {
                        first_name: 'Admin',
                        last_name: 'Hukumku',
                        role: 'admin',
                        picture: {
                          url: AdminAvatar,
                        },
                      } as User;
                      return (
                        <Col
                          className={`${
                            role === 'lawyer' && 'hk-padding-top-md'
                          }`}
                          key={commentId}
                          span={24}>
                          {role === 'client' ? (
                            <>
                              <Divider />
                              <RevisionHead
                                createdAt={createdAtComment ?? 0}
                                role={role}
                                user={clientInfo as User}
                              />
                            </>
                          ) : role === 'lawyer' && commentIndex !== 0 ? (
                            <>
                              <Divider />
                              <RevisionHead
                                createdAt={createdAtComment ?? 0}
                                role={role}
                                user={lawyerInfo as User}
                              />
                            </>
                          ) : role === 'system' ? (
                            <>
                              <Divider />
                              <RevisionHead
                                createdAt={createdAtComment ?? 0}
                                role={role}
                                user={adminAvatar as User}
                              />
                            </>
                          ) : null}
                          <Typography.Paragraph
                            className="weight-400 font-14 hk-color-black-title"
                            ellipsis={{
                              rows: 4,
                              expandable: true,
                              symbol: (
                                <>
                                  <br />
                                  <Typography.Text
                                    className="hk-color-dark-green font-12 weight-400"
                                    underline>
                                    Lihat lebih banyak
                                  </Typography.Text>
                                </>
                              ),
                            }}>
                            {commentFactory(comment)}
                          </Typography.Paragraph>
                          {isLastRevision &&
                          isLastComment &&
                          (status === 'need_revise' ||
                            status === 'in_revise') ? (
                            <>
                              {role === 'client' && <Divider />}
                              <Row
                                className="w-full hk-margin-top-lg"
                                gutter={[12, 0]}
                                justify="end">
                                <RevisionActions
                                  action={action}
                                  id={legalDraftItemId ?? ''}
                                  lawyerInfo={lawyerInfo}
                                  setAction={setAction}
                                  status={status}
                                />
                              </Row>
                            </>
                          ) : null}
                        </Col>
                      );
                    }
                  )
                : null}
            </Row>
          );
        }
      )}
    </Space>
  );
};
