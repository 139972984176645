/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * HukumkuRestAPI-AuthService
 * OpenAPI spec version: 1.0
 */
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { ErrorType } from '../../../../utils/auth-service-request';
import { authServiceRequest } from '../../../../utils/auth-service-request';
import type {
  Error,
  GetPracticeAreas200,
  GetPracticeAreasIdTopics200,
  GetPracticeAreasParams,
} from '../../../models/auth';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Get list of Practice Areas
 */
export const getPracticeAreas = (
  params?: GetPracticeAreasParams,
  options?: SecondParameter<typeof authServiceRequest>,
  signal?: AbortSignal
) => {
  return authServiceRequest<GetPracticeAreas200>(
    { url: `/practice-areas`, method: 'get', params, signal },
    options
  );
};

export const getGetPracticeAreasQueryKey = (params?: GetPracticeAreasParams) =>
  [`/practice-areas`, ...(params ? [params] : [])] as const;

export const getGetPracticeAreasQueryOptions = <
  TData = Awaited<ReturnType<typeof getPracticeAreas>>,
  TError = ErrorType<Error>,
>(
  params?: GetPracticeAreasParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPracticeAreas>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof authServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getPracticeAreas>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPracticeAreasQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPracticeAreas>>
  > = ({ signal }) => getPracticeAreas(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPracticeAreasQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPracticeAreas>>
>;
export type GetPracticeAreasQueryError = ErrorType<Error>;

/**
 * @summary Get list of Practice Areas
 */
export const useGetPracticeAreas = <
  TData = Awaited<ReturnType<typeof getPracticeAreas>>,
  TError = ErrorType<Error>,
>(
  params?: GetPracticeAreasParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPracticeAreas>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof authServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPracticeAreasQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get list of Practice Area's Topics
 */
export const getPracticeAreasIdTopics = (
  id: string,
  options?: SecondParameter<typeof authServiceRequest>,
  signal?: AbortSignal
) => {
  return authServiceRequest<GetPracticeAreasIdTopics200>(
    { url: `/practice-areas/${id}/topics`, method: 'get', signal },
    options
  );
};

export const getGetPracticeAreasIdTopicsQueryKey = (id: string) =>
  [`/practice-areas/${id}/topics`] as const;

export const getGetPracticeAreasIdTopicsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPracticeAreasIdTopics>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPracticeAreasIdTopics>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof authServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getPracticeAreasIdTopics>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPracticeAreasIdTopicsQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPracticeAreasIdTopics>>
  > = ({ signal }) => getPracticeAreasIdTopics(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetPracticeAreasIdTopicsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPracticeAreasIdTopics>>
>;
export type GetPracticeAreasIdTopicsQueryError = ErrorType<Error>;

/**
 * @summary Get list of Practice Area's Topics
 */
export const useGetPracticeAreasIdTopics = <
  TData = Awaited<ReturnType<typeof getPracticeAreasIdTopics>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPracticeAreasIdTopics>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof authServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPracticeAreasIdTopicsQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
