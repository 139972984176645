/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * HukumkuRestAPI-CaseService
 * OpenAPI spec version: 1.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type {
  BodyType,
  ErrorType,
} from '../../../../utils/case-service-request';
import { caseServiceRequest } from '../../../../utils/case-service-request';
import type {
  CreateLegalDraft,
  Error,
  GetLegalDrafts200,
  GetLegalDraftsId200,
  GetLegalDraftsParams,
  LegalDraftRevisionRequestComment,
  PostLegalDrafts200,
  UploadLegalDraftItem,
} from '../../../models/case';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Create legal draft
 */
export const postLegalDrafts = (
  createLegalDraft: BodyType<CreateLegalDraft>,
  options?: SecondParameter<typeof caseServiceRequest>
) => {
  return caseServiceRequest<PostLegalDrafts200>(
    {
      url: `/legal-drafts`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createLegalDraft,
    },
    options
  );
};

export const getPostLegalDraftsMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLegalDrafts>>,
    TError,
    { data: BodyType<CreateLegalDraft> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postLegalDrafts>>,
  TError,
  { data: BodyType<CreateLegalDraft> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postLegalDrafts>>,
    { data: BodyType<CreateLegalDraft> }
  > = (props) => {
    const { data } = props ?? {};

    return postLegalDrafts(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostLegalDraftsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postLegalDrafts>>
>;
export type PostLegalDraftsMutationBody = BodyType<CreateLegalDraft>;
export type PostLegalDraftsMutationError = ErrorType<Error>;

/**
 * @summary Create legal draft
 */
export const usePostLegalDrafts = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLegalDrafts>>,
    TError,
    { data: BodyType<CreateLegalDraft> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}) => {
  const mutationOptions = getPostLegalDraftsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get list legal draft
 */
export const getLegalDrafts = (
  params: GetLegalDraftsParams,
  options?: SecondParameter<typeof caseServiceRequest>,
  signal?: AbortSignal
) => {
  return caseServiceRequest<GetLegalDrafts200>(
    { url: `/legal-drafts`, method: 'get', params, signal },
    options
  );
};

export const getGetLegalDraftsQueryKey = (params: GetLegalDraftsParams) =>
  [`/legal-drafts`, ...(params ? [params] : [])] as const;

export const getGetLegalDraftsQueryOptions = <
  TData = Awaited<ReturnType<typeof getLegalDrafts>>,
  TError = ErrorType<Error>,
>(
  params: GetLegalDraftsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLegalDrafts>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getLegalDrafts>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLegalDraftsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLegalDrafts>>> = ({
    signal,
  }) => getLegalDrafts(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLegalDraftsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLegalDrafts>>
>;
export type GetLegalDraftsQueryError = ErrorType<Error>;

/**
 * @summary Get list legal draft
 */
export const useGetLegalDrafts = <
  TData = Awaited<ReturnType<typeof getLegalDrafts>>,
  TError = ErrorType<Error>,
>(
  params: GetLegalDraftsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLegalDrafts>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLegalDraftsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get detail legal draft
 */
export const getLegalDraftsId = (
  id: string,
  options?: SecondParameter<typeof caseServiceRequest>,
  signal?: AbortSignal
) => {
  return caseServiceRequest<GetLegalDraftsId200>(
    { url: `/legal-drafts/${id}`, method: 'get', signal },
    options
  );
};

export const getGetLegalDraftsIdQueryKey = (id: string) =>
  [`/legal-drafts/${id}`] as const;

export const getGetLegalDraftsIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getLegalDraftsId>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLegalDraftsId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getLegalDraftsId>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLegalDraftsIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLegalDraftsId>>
  > = ({ signal }) => getLegalDraftsId(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetLegalDraftsIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLegalDraftsId>>
>;
export type GetLegalDraftsIdQueryError = ErrorType<Error>;

/**
 * @summary Get detail legal draft
 */
export const useGetLegalDraftsId = <
  TData = Awaited<ReturnType<typeof getLegalDraftsId>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLegalDraftsId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLegalDraftsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Upload legal draft item
 */
export const postLegalDraftItemsId = (
  id: string,
  uploadLegalDraftItem: BodyType<UploadLegalDraftItem>,
  options?: SecondParameter<typeof caseServiceRequest>
) => {
  const formData = new FormData();
  if (uploadLegalDraftItem.file !== undefined) {
    formData.append('file', uploadLegalDraftItem.file);
  }
  if (uploadLegalDraftItem.reason !== undefined) {
    formData.append('reason', uploadLegalDraftItem.reason);
  }

  return caseServiceRequest<void>(
    {
      url: `/legal-draft-items/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  );
};

export const getPostLegalDraftItemsIdMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLegalDraftItemsId>>,
    TError,
    { id: string; data: BodyType<UploadLegalDraftItem> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postLegalDraftItemsId>>,
  TError,
  { id: string; data: BodyType<UploadLegalDraftItem> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postLegalDraftItemsId>>,
    { id: string; data: BodyType<UploadLegalDraftItem> }
  > = (props) => {
    const { id, data } = props ?? {};

    return postLegalDraftItemsId(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostLegalDraftItemsIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof postLegalDraftItemsId>>
>;
export type PostLegalDraftItemsIdMutationBody = BodyType<UploadLegalDraftItem>;
export type PostLegalDraftItemsIdMutationError = ErrorType<Error>;

/**
 * @summary Upload legal draft item
 */
export const usePostLegalDraftItemsId = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLegalDraftItemsId>>,
    TError,
    { id: string; data: BodyType<UploadLegalDraftItem> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}) => {
  const mutationOptions = getPostLegalDraftItemsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Accept revision
 */
export const patchLegalDraftItemsIdAccept = (
  id: string,
  options?: SecondParameter<typeof caseServiceRequest>
) => {
  return caseServiceRequest<void>(
    { url: `/legal-draft-items/${id}/accept`, method: 'patch' },
    options
  );
};

export const getPatchLegalDraftItemsIdAcceptMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchLegalDraftItemsIdAccept>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchLegalDraftItemsIdAccept>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchLegalDraftItemsIdAccept>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return patchLegalDraftItemsIdAccept(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchLegalDraftItemsIdAcceptMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchLegalDraftItemsIdAccept>>
>;

export type PatchLegalDraftItemsIdAcceptMutationError = ErrorType<Error>;

/**
 * @summary Accept revision
 */
export const usePatchLegalDraftItemsIdAccept = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchLegalDraftItemsIdAccept>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}) => {
  const mutationOptions =
    getPatchLegalDraftItemsIdAcceptMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Request revise legal draft revision
 */
export const postLegalDraftItemsIdReviseRequest = (
  id: string,
  legalDraftRevisionRequestComment: BodyType<LegalDraftRevisionRequestComment>,
  options?: SecondParameter<typeof caseServiceRequest>
) => {
  return caseServiceRequest<void>(
    {
      url: `/legal-draft-items/${id}/revise-request`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: legalDraftRevisionRequestComment,
    },
    options
  );
};

export const getPostLegalDraftItemsIdReviseRequestMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLegalDraftItemsIdReviseRequest>>,
    TError,
    { id: string; data: BodyType<LegalDraftRevisionRequestComment> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postLegalDraftItemsIdReviseRequest>>,
  TError,
  { id: string; data: BodyType<LegalDraftRevisionRequestComment> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postLegalDraftItemsIdReviseRequest>>,
    { id: string; data: BodyType<LegalDraftRevisionRequestComment> }
  > = (props) => {
    const { id, data } = props ?? {};

    return postLegalDraftItemsIdReviseRequest(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostLegalDraftItemsIdReviseRequestMutationResult = NonNullable<
  Awaited<ReturnType<typeof postLegalDraftItemsIdReviseRequest>>
>;
export type PostLegalDraftItemsIdReviseRequestMutationBody =
  BodyType<LegalDraftRevisionRequestComment>;
export type PostLegalDraftItemsIdReviseRequestMutationError = ErrorType<Error>;

/**
 * @summary Request revise legal draft revision
 */
export const usePostLegalDraftItemsIdReviseRequest = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLegalDraftItemsIdReviseRequest>>,
    TError,
    { id: string; data: BodyType<LegalDraftRevisionRequestComment> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}) => {
  const mutationOptions =
    getPostLegalDraftItemsIdReviseRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Acknowledge legal draft revision
 */
export const patchLegalDraftItemsIdReviseRequestAcknowledge = (
  id: string,
  options?: SecondParameter<typeof caseServiceRequest>
) => {
  return caseServiceRequest<void>(
    {
      url: `/legal-draft-items/${id}/revise-request/acknowledge`,
      method: 'patch',
    },
    options
  );
};

export const getPatchLegalDraftItemsIdReviseRequestAcknowledgeMutationOptions =
  <TError = ErrorType<Error>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof patchLegalDraftItemsIdReviseRequestAcknowledge>
      >,
      TError,
      { id: string },
      TContext
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof patchLegalDraftItemsIdReviseRequestAcknowledge>>,
    TError,
    { id: string },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof patchLegalDraftItemsIdReviseRequestAcknowledge>
      >,
      { id: string }
    > = (props) => {
      const { id } = props ?? {};

      return patchLegalDraftItemsIdReviseRequestAcknowledge(id, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchLegalDraftItemsIdReviseRequestAcknowledgeMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof patchLegalDraftItemsIdReviseRequestAcknowledge>>
  >;

export type PatchLegalDraftItemsIdReviseRequestAcknowledgeMutationError =
  ErrorType<Error>;

/**
 * @summary Acknowledge legal draft revision
 */
export const usePatchLegalDraftItemsIdReviseRequestAcknowledge = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchLegalDraftItemsIdReviseRequestAcknowledge>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}) => {
  const mutationOptions =
    getPatchLegalDraftItemsIdReviseRequestAcknowledgeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Deny legal draft revision
 */
export const patchLegalDraftItemsIdReviseRequestDeny = (
  id: string,
  legalDraftRevisionRequestComment: BodyType<LegalDraftRevisionRequestComment>,
  options?: SecondParameter<typeof caseServiceRequest>
) => {
  return caseServiceRequest<void>(
    {
      url: `/legal-draft-items/${id}/revise-request/deny`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: legalDraftRevisionRequestComment,
    },
    options
  );
};

export const getPatchLegalDraftItemsIdReviseRequestDenyMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchLegalDraftItemsIdReviseRequestDeny>>,
    TError,
    { id: string; data: BodyType<LegalDraftRevisionRequestComment> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchLegalDraftItemsIdReviseRequestDeny>>,
  TError,
  { id: string; data: BodyType<LegalDraftRevisionRequestComment> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchLegalDraftItemsIdReviseRequestDeny>>,
    { id: string; data: BodyType<LegalDraftRevisionRequestComment> }
  > = (props) => {
    const { id, data } = props ?? {};

    return patchLegalDraftItemsIdReviseRequestDeny(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchLegalDraftItemsIdReviseRequestDenyMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchLegalDraftItemsIdReviseRequestDeny>>
>;
export type PatchLegalDraftItemsIdReviseRequestDenyMutationBody =
  BodyType<LegalDraftRevisionRequestComment>;
export type PatchLegalDraftItemsIdReviseRequestDenyMutationError =
  ErrorType<Error>;

/**
 * @summary Deny legal draft revision
 */
export const usePatchLegalDraftItemsIdReviseRequestDeny = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchLegalDraftItemsIdReviseRequestDeny>>,
    TError,
    { id: string; data: BodyType<LegalDraftRevisionRequestComment> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}) => {
  const mutationOptions =
    getPatchLegalDraftItemsIdReviseRequestDenyMutationOptions(options);

  return useMutation(mutationOptions);
};
