import { writeLogging } from 'libs/firebase';
import { Tinode as TinodeSdk } from 'tinode-sdk';

import { Tinode } from './types/sdk/Tinode';
interface InitializeTinodeParams {
  onComplete?: () => void;
}

export function initializeTinode({ onComplete }: InitializeTinodeParams = {}) {
  const tinode: Tinode = new TinodeSdk(
    {
      appName: process.env.REACT_APP_TINODE_APP_NAME,
      host: process.env.REACT_APP_TINODE_HOST,
      apiKey: process.env.REACT_APP_TINODE_API_KEY,
      secure: true,
      persist: true,
    },
    onComplete
  );

  tinode.setHumanLanguage('en_US');
  tinode.enableLogging(true, true);

  function customLogger(message: string, severity: string) {
    const logEntry = {
      severity: severity,
      message: message,
      timestamp: new Date().toISOString(),
    };

    writeLogging({ message, severity });

    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log('[Tinode Information]', logEntry);
    }
  }

  tinode.logger = customLogger;

  return tinode;
}
