import './style.scss';

import { CloseOutlined, InboxOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import {
  Alert,
  Button,
  Col,
  message,
  Modal,
  ModalProps,
  Row,
  Typography,
  Upload,
} from 'antd';

import {
  useGetLegalDraftsId,
  usePostLegalDraftItemsId,
} from 'api/generated/services/case/legal-draft/legal-draft';
import { useUploadDraft } from 'pages/legal-drafting/hooks/useUploadDraft';

interface UploadLegalDraftModalProps extends ModalProps {
  legalDraftId: string | undefined;
  onCancel: () => void;
}

export const UploadLegalDraftModal = (props: UploadLegalDraftModalProps) => {
  const queryClient = useQueryClient();

  const { legalDraftId, onCancel } = props;

  const {
    mutate: uploadLegalDraftDocument,
    isLoading: isLoadingUploadLegalDraftDocument,
  } = usePostLegalDraftItemsId();

  const { fileList, handleChangeFile, onClickRemoveFile, setFileList } =
    useUploadDraft();

  const { data: legalDraftDetail } = useGetLegalDraftsId(legalDraftId ?? '', {
    query: {
      enabled: !!legalDraftId,
    },
  });

  const legalDraftItemId = legalDraftDetail?.data?.items?.[0]?.id;

  if (!legalDraftId || !legalDraftItemId) return;

  const onUploadFile = () => {
    const payload = {
      data: { file: fileList[0].originFileObj },
      id: legalDraftItemId,
    };

    uploadLegalDraftDocument(
      { ...payload },
      {
        onSuccess: () => {
          message.success('Sukses upload dokumen');
          onCancel();
          queryClient.invalidateQueries();
        },
      }
    );
  };

  return (
    <Modal
      {...props}
      className="legal-draft-modal legal-draft-modal__upload"
      footer={false}
      title={
        <Typography.Title
          className="legal-draft-modal-title weight-500 hk-margin-bottom-lg"
          color="#121926">
          Unggah Dokumen
        </Typography.Title>
      }
      width={484}>
      <Upload.Dragger
        accept=".png,.jpg,.jpeg,.pdf,.doc,.docx"
        beforeUpload={() => {
          return false;
        }}
        className="uploader"
        maxCount={1}
        multiple={false}
        name="file"
        onChange={handleChangeFile}
        onDrop={handleChangeFile}
        onRemove={() => setFileList([])}
        showUploadList={false}
        withCredentials={true}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="uploader-text">
          Click atau drag file ke area ini untuk mengunggah
        </p>
      </Upload.Dragger>
      {fileList.length === 1 && (
        <>
          <Alert
            className="alert-wrapper"
            message={
              <Typography.Text className="alert-text">
                Maximum attachments allowed: 1
              </Typography.Text>
            }
            type="error"
          />
          {fileList.map((eachFile, index) => {
            return (
              <Row
                align="middle"
                className="hk-margin-top-md"
                justify="space-between"
                key={index}>
                <Col>
                  <Typography.Paragraph className="hk-padding-none hk-margin-none name">
                    {eachFile.name}
                  </Typography.Paragraph>
                </Col>
                <Col>
                  <Button
                    className="hk-padding-none hk-margin-none hk-color-black-title close-icon"
                    icon={<CloseOutlined color="#000" />}
                    onClick={onClickRemoveFile}
                    size="small"
                    type="text"
                  />
                </Col>
              </Row>
            );
          })}
        </>
      )}
      <Button
        className="w-full hk-margin-top-lg"
        disabled={!fileList.length || isLoadingUploadLegalDraftDocument}
        loading={isLoadingUploadLegalDraftDocument}
        onClick={onUploadFile}
        type="primary">
        Unggah
      </Button>
    </Modal>
  );
};
