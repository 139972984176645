import { useCallback, useEffect } from 'react';

import dayjs from 'dayjs';
import { tinodeAdapter } from 'libs/tinode/tinode-adapter';

import { useGetConsultations } from 'api/generated/services/case/consultation/consultation';
import { MAX_ONGOING_CONSULTATIONS_COUNT } from 'config/constants';
import { useChatStore } from 'store/chat';
import { useCurrentUserStore } from 'store/current-user';
import { ConsultationStatus } from 'types/enums';
import { incomingConsultationsNotificationAudio } from 'utils/incoming-consultations-audio';
import { log } from 'utils/log';

export function useIncomingChats() {
  const isTinodeConnected = useChatStore((state) => state.isTinodeConnected);
  const setAcceptedConsultationIds = useChatStore(
    (state) => state.actions.setAcceptedConsultationIds
  );
  const setIncomingConsultations = useChatStore(
    (state) => state.actions.setIncomingConsultations
  );
  const setIsLoadingIncomingConsultations = useChatStore(
    (state) => state.actions.setIsLoadingIncomingConsultations
  );
  const isOnline = useCurrentUserStore((state) => state.isOnline);
  const todayStartTime = dayjs().hour(0).minute(0).second(1).unix();
  const todayEndTime = dayjs().hour(23).minute(59).second(59).unix();

  const {
    refetch: refetchConsultations,
    isFetching: isLoadingIncomingConsultations,
  } = useGetConsultations(
    {
      page: 1,
      limit: MAX_ONGOING_CONSULTATIONS_COUNT,
      status: [ConsultationStatus.not_started, ConsultationStatus.ongoing],
      created_at: {
        gte: todayStartTime,
        lte: todayEndTime,
      },
    },
    {
      query: {
        enabled: isOnline,
        onSuccess(data) {
          try {
            const incomingConsultations = data.data;
            if (incomingConsultations.length) {
              const isNewIncomingConsultation = incomingConsultations.some(
                ({ status }) => status === 'not_started'
              );
              if (isNewIncomingConsultation) {
                incomingConsultationsNotificationAudio();
              }
            }
            setIncomingConsultations(data.data);

            if (!incomingConsultations?.length) {
              setAcceptedConsultationIds([]);
            }
          } catch (error) {
            log({ error, message: '[ERROR RE-FETCH CONSULTATION]' });
          }
        },
      },
    }
  );

  const refetchIncomingConsultations = useCallback(() => {
    if (isOnline) {
      refetchConsultations();
    }
  }, [isOnline, refetchConsultations]);

  useEffect(() => {
    if (isTinodeConnected && isOnline) {
      tinodeAdapter.attachConnectionHandlers({
        onPresMessage: () => {
          refetchIncomingConsultations();
        },
      });
    } else {
      tinodeAdapter.attachConnectionHandlers({
        onPresMessage: () => null,
      });
    }
  }, [isOnline, isTinodeConnected, refetchIncomingConsultations]);

  useEffect(() => {
    setIsLoadingIncomingConsultations(isLoadingIncomingConsultations);
  }, [isLoadingIncomingConsultations, setIsLoadingIncomingConsultations]);

  return {
    refetchIncomingConsultations,
  };
}
