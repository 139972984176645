import 'App.scss';
import 'components/media-uploader/style.scss';
import 'libs/sentry';

import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ErrorBoundary } from '@sentry/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useTinodeConnect } from 'hooks/useTinodeConnect';

import { GeneralError } from 'components/placeholder-errors/general-error';
import { CenteredSpinnerLoading } from 'components/placeholder-loadings/centered-spinner-loading';
import { AppProviders } from 'components/providers/app-providers';
import { AppRoutes } from 'routes';

function App() {
  useTinodeConnect();

  return (
    <ErrorBoundary fallback={<GeneralError />}>
      <BrowserRouter>
        <Suspense fallback={<CenteredSpinnerLoading />}>
          <AppProviders>
            <AppRoutes />
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </AppProviders>
        </Suspense>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
