import 'firebase/firestore';

import dayjs from 'dayjs';
import { initializeApp } from 'firebase/app';
import { FirebaseError } from 'firebase/app'; // Adjust based on your Firebase SDK version
import {
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  getFirestore,
  runTransaction,
} from 'firebase/firestore';

import { firebaseConfig } from './config';

interface WriteLogging {
  message: string;
  severity: string;
  error?: string;
}

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const MAX_RETRIES = 5;

export const writeLogging = async ({
  message = '',
  severity = '',
  error: errorMessage,
}: WriteLogging) => {
  const formattedDate = dayjs(new Date()).format('DD-MM-YYYY');
  const unix = dayjs(new Date()).unix();

  // minimize cost of storing data
  const truncated = (str: string, length: number) => {
    if (!str) return '-';
    return str.length > length ? str.substring(0, length) : str;
  };

  const documentData = {
    message: truncated(message, 100),
    severity: truncated(severity, 100),
    createdAt: unix,
    error: errorMessage ? JSON.stringify(errorMessage) : '',
  };

  const collectionName =
    process.env.REACT_APP_ENV === 'prod'
      ? 'PRODUCTION'
      : process.env.REACT_APP_ENV === 'stage'
      ? 'STAGING'
      : 'DEVELOPMENT';

  const docRef: DocumentReference<DocumentData> = doc(
    firestore,
    collectionName,
    formattedDate
  );

  const executeTransaction = async (retryCount = 0) => {
    try {
      await runTransaction(firestore, async (transaction) => {
        const docSnapshot: DocumentSnapshot<DocumentData> =
          await transaction.get(docRef);

        if (!docSnapshot.exists()) {
          transaction.set(docRef, { messages: [documentData] });
        } else {
          const existingMessages = docSnapshot.data()?.messages || [];
          transaction.update(docRef, {
            messages: [...existingMessages, documentData],
          });
        }
      });
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (error.code === 'resource-exhausted') {
          // eslint-disable-next-line no-console
          console.error(
            'Quota exceeded. Please upgrade your plan or optimize your usage.'
          );
        } else if (
          error.code === 'FAILED_PRECONDITION' &&
          retryCount < MAX_RETRIES
        ) {
          const waitTime = Math.pow(2, retryCount) * 100; // Exponential backoff
          // eslint-disable-next-line no-console
          console.warn(
            `Retrying log write due to version mismatch. Retry count: ${
              retryCount + 1
            }`
          );
          await new Promise((resolve) => setTimeout(resolve, waitTime));
          await executeTransaction(retryCount + 1);
        } else {
          // eslint-disable-next-line no-console
          console.error('Error writing log to Firestore:', error);
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('Unknown error occurred:', error);
      }
    }
  };

  await executeTransaction();
};
