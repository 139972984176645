import './style.scss';

import {
  ArrowDownOutlined,
  FileOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import { Button, Image, Space, Tooltip, Typography } from 'antd';

import { Document } from 'api/generated/models/document';

export interface MessageDocumentProps {
  document: Document;
}

export function MessageDocument({ document }: MessageDocumentProps) {
  const fileType = document.file_type;

  let content = <FileOutlined className="file-icon hk-color-dark-grey" />;
  if (fileType.includes('image/')) {
    content = <Image preview={false} src={document.url} width={250} />;
  }
  if (fileType.includes('pdf')) {
    content = <FilePdfOutlined className="file-icon hk-color-dark-grey" />;
  }

  return (
    <Tooltip placement="bottom" title={document.name}>
      <div className="hk-message-document">
        <div className="backdrop"></div>
        <Button
          className="download-button"
          download={document.name}
          href={document.url}
          icon={<ArrowDownOutlined />}
          shape="circle"
          target={'_blank'}
        />
        <Space align="center" direction="vertical" size={0}>
          {content}
          <Typography.Paragraph
            className="file-name"
            ellipsis={{ rows: 2 }}
            type="secondary">
            {document.name}
          </Typography.Paragraph>
        </Space>
      </div>
    </Tooltip>
  );
}
