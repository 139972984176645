import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Menu } from 'antd';

import { MENU_SIDEBAR_ITEMS } from '../constants';

export function MainMenu() {
  const location = useLocation();

  const pathNames = useMemo(() => location?.pathname?.split('/'), [location]);

  const defaultOpenKeys = useMemo(() => {
    if (pathNames?.length <= 2) {
      return [];
    }
    return [pathNames?.[1]];
  }, [pathNames]);

  const defaultSelectedKeys = useMemo(() => {
    return [pathNames?.[1]];
  }, [pathNames]);

  return (
    <Menu
      defaultOpenKeys={defaultOpenKeys}
      defaultSelectedKeys={defaultSelectedKeys}
      items={MENU_SIDEBAR_ITEMS}
      mode="inline"
      selectedKeys={defaultSelectedKeys}
    />
  );
}
