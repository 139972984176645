import './style.scss';

import { useState } from 'react';

import { Button, Card, Col, Divider, Row, Space, Typography } from 'antd';

import { LegalDraft } from 'api/generated/models/case';
import { ReactComponent as PaperIc } from 'assets/svg/paper-ic.svg';
import { Avatar } from 'components/avatar';
import { LegalStatus } from 'pages/legal-drafting/types';
import { getSecretFullName } from 'utils/get-full-name';

import { ForumDiscussionModal } from '../modal/forum-discussion-modal';
import { StatusTag } from '../status-tag';

interface LegalDraftCardProps {
  legalDraft: LegalDraft;
}

export const LegalDraftCard = ({ legalDraft }: LegalDraftCardProps) => {
  const [selectedLegalDraftId, setSelectedDraftId] = useState<string | null>(
    null
  );

  const onOpenForumDiscussionModal = () => {
    setSelectedDraftId(legalDraft.id);
  };
  const onCloseForumDiscussionModal = () => setSelectedDraftId(null);

  const {
    case: { topic = '', client, description },
  } = legalDraft;

  const clientFullName = getSecretFullName(client);

  const legalDraftsStatus: LegalStatus = legalDraft?.items?.[0]
    ?.status as LegalStatus;

  return (
    <>
      {legalDraft.id === selectedLegalDraftId && (
        <ForumDiscussionModal
          legalDraftId={selectedLegalDraftId}
          onCancel={onCloseForumDiscussionModal}
          open={!!selectedLegalDraftId}
        />
      )}
      <Card
        className="offer-card__container"
        extra={
          <Typography.Paragraph className="hk-margin-bottom-none">
            <StatusTag
              status={legalDraftsStatus as LegalStatus}
              type="legal-drafts"
            />
          </Typography.Paragraph>
        }
        title={
          <div className="topic-wrapper">
            <PaperIc style={{ width: '20px', height: '20px' }} />
            <Typography.Paragraph
              className="font-16 weight-500 hk-color-black-title hk-margin-bottom-none"
              ellipsis>
              {topic}
            </Typography.Paragraph>
          </div>
        }>
        <Typography.Paragraph className="font-14 weight-500 hk-color-black-title hk-margin-bottom-sm">
          Judul Dokumen
        </Typography.Paragraph>
        <Typography.Paragraph
          className="font-12 weight-400 hk-color-dark-grey hk-margin-bottom-sm"
          ellipsis>
          {description}
        </Typography.Paragraph>

        <Divider />

        <Row align="middle" justify="space-between">
          <Col md={14} xs={12}>
            <Space align="start" direction="horizontal" size={6}>
              <Avatar size={35} user={client} />
              <Space direction="vertical" size={0}>
                <Typography.Paragraph className="font-12 weight-500 hk-color-black-title hk-margin-bottom-none">
                  Client Name
                </Typography.Paragraph>
                <Typography.Paragraph
                  className="font-10 weight-400 hk-color-black-title hk-margin-bottom-none"
                  ellipsis>
                  {clientFullName}
                </Typography.Paragraph>
              </Space>
            </Space>
          </Col>
          <Col md={10} xs={12}>
            <Button
              className="detail-button w-full"
              onClick={onOpenForumDiscussionModal}
              size="middle">
              <Typography.Text
                className="font-12 weight-500 hk-color-green"
                ellipsis>
                Lihat Dokumen
              </Typography.Text>
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};
