import { Col, Row, Typography } from 'antd';

import FormErrorAlert from 'assets/svg/form-error-alert.svg';

interface FormErrorProps {
  message: string;
}

const FormError = ({ message }: FormErrorProps) => {
  return (
    <Row
      align="middle"
      className="hk-margin-top-xs"
      gutter={4}
      justify="start"
      wrap={false}>
      <Col className="flex center hk-margin-right-xs">
        <img className="object-fit" src={FormErrorAlert} />
      </Col>
      <Col>
        <Typography.Text type="danger">{message}</Typography.Text>
      </Col>
    </Row>
  );
};

export { FormError };
