import { Steps } from 'antd';

import { useVerificationStore } from 'store/verification';

import { VERIFICATION_STEPS_ITEMS } from '../constants';

export function VerificationMenu() {
  const { step } = useVerificationStore((state) => state);

  return (
    <Steps
      className="hk-margin-top-lg"
      current={step}
      direction="vertical"
      items={VERIFICATION_STEPS_ITEMS}
      size="small"
    />
  );
}
