import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { InitialLayout, LayoutState } from './types/layout';

const initialState: InitialLayout = {
  isMobileSidebarVisible: false,
};

export const useLayoutStore = create<LayoutState>()(
  persist(
    (set) => ({
      ...initialState,
      actions: {
        toggleMobileSidebar(isVisible?: boolean) {
          set((state) => ({
            ...state,
            isMobileSidebarVisible:
              typeof isVisible !== 'undefined'
                ? isVisible
                : !state.isMobileSidebarVisible,
          }));
        },
        reset() {
          set(() => initialState);
        },
      },
    }),
    {
      name: 'layout',
      partialize: (state) => {
        // define keys from state that dont want to be included in persist storage
        const excludedKeys = ['actions'];
        return Object.fromEntries(
          Object.entries(state).filter(([key]) => !excludedKeys.includes(key))
        );
      },
    }
  )
);
