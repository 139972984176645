/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * HukumkuRestAPI-CaseService
 * OpenAPI spec version: 1.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type {
  BodyType,
  ErrorType,
} from '../../../../utils/case-service-request';
import { caseServiceRequest } from '../../../../utils/case-service-request';
import type {
  DisbursementDetail,
  DisbursementRequest,
  DisbursementUpdate,
  Error,
  GetDisbursements200,
  GetDisbursementsId200,
  GetDisbursementsParams,
} from '../../../models/case';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Request disbursement
 */
export const requestDisbursement = (
  disbursementRequest: BodyType<DisbursementRequest>,
  options?: SecondParameter<typeof caseServiceRequest>
) => {
  return caseServiceRequest<DisbursementDetail>(
    {
      url: `/disbursements`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: disbursementRequest,
    },
    options
  );
};

export const getRequestDisbursementMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof requestDisbursement>>,
    TError,
    { data: BodyType<DisbursementRequest> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof requestDisbursement>>,
  TError,
  { data: BodyType<DisbursementRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof requestDisbursement>>,
    { data: BodyType<DisbursementRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return requestDisbursement(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RequestDisbursementMutationResult = NonNullable<
  Awaited<ReturnType<typeof requestDisbursement>>
>;
export type RequestDisbursementMutationBody = BodyType<DisbursementRequest>;
export type RequestDisbursementMutationError = ErrorType<Error>;

/**
 * @summary Request disbursement
 */
export const useRequestDisbursement = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof requestDisbursement>>,
    TError,
    { data: BodyType<DisbursementRequest> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}) => {
  const mutationOptions = getRequestDisbursementMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get pagination of disbursement
 */
export const getDisbursements = (
  params: GetDisbursementsParams,
  options?: SecondParameter<typeof caseServiceRequest>,
  signal?: AbortSignal
) => {
  return caseServiceRequest<GetDisbursements200>(
    { url: `/disbursements`, method: 'get', params, signal },
    options
  );
};

export const getGetDisbursementsQueryKey = (params: GetDisbursementsParams) =>
  [`/disbursements`, ...(params ? [params] : [])] as const;

export const getGetDisbursementsQueryOptions = <
  TData = Awaited<ReturnType<typeof getDisbursements>>,
  TError = ErrorType<Error>,
>(
  params: GetDisbursementsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDisbursements>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getDisbursements>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDisbursementsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDisbursements>>
  > = ({ signal }) => getDisbursements(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetDisbursementsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDisbursements>>
>;
export type GetDisbursementsQueryError = ErrorType<Error>;

/**
 * @summary Get pagination of disbursement
 */
export const useGetDisbursements = <
  TData = Awaited<ReturnType<typeof getDisbursements>>,
  TError = ErrorType<Error>,
>(
  params: GetDisbursementsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDisbursements>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDisbursementsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get detail disbursement
 */
export const getDisbursementsId = (
  id: string,
  options?: SecondParameter<typeof caseServiceRequest>,
  signal?: AbortSignal
) => {
  return caseServiceRequest<GetDisbursementsId200>(
    { url: `/disbursements/${id}`, method: 'get', signal },
    options
  );
};

export const getGetDisbursementsIdQueryKey = (id: string) =>
  [`/disbursements/${id}`] as const;

export const getGetDisbursementsIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getDisbursementsId>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDisbursementsId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getDisbursementsId>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDisbursementsIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDisbursementsId>>
  > = ({ signal }) => getDisbursementsId(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetDisbursementsIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDisbursementsId>>
>;
export type GetDisbursementsIdQueryError = ErrorType<Error>;

/**
 * @summary Get detail disbursement
 */
export const useGetDisbursementsId = <
  TData = Awaited<ReturnType<typeof getDisbursementsId>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDisbursementsId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDisbursementsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update disbursement
 */
export const updateDisbursement = (
  id: string,
  disbursementUpdate: BodyType<DisbursementUpdate>,
  options?: SecondParameter<typeof caseServiceRequest>
) => {
  return caseServiceRequest<DisbursementDetail>(
    {
      url: `/disbursements/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: disbursementUpdate,
    },
    options
  );
};

export const getUpdateDisbursementMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateDisbursement>>,
    TError,
    { id: string; data: BodyType<DisbursementUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateDisbursement>>,
  TError,
  { id: string; data: BodyType<DisbursementUpdate> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateDisbursement>>,
    { id: string; data: BodyType<DisbursementUpdate> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateDisbursement(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateDisbursementMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateDisbursement>>
>;
export type UpdateDisbursementMutationBody = BodyType<DisbursementUpdate>;
export type UpdateDisbursementMutationError = ErrorType<Error>;

/**
 * @summary Update disbursement
 */
export const useUpdateDisbursement = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateDisbursement>>,
    TError,
    { id: string; data: BodyType<DisbursementUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}) => {
  const mutationOptions = getUpdateDisbursementMutationOptions(options);

  return useMutation(mutationOptions);
};
