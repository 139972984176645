/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * HukumkuRestAPI-AuthService
 * OpenAPI spec version: 1.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type {
  BodyType,
  ErrorType,
} from '../../../../utils/auth-service-request';
import { authServiceRequest } from '../../../../utils/auth-service-request';
import type {
  ConsultAuthenticatorRequest,
  Error,
  ForgotPassword,
  GetAuthenticationsMe200,
  Login,
  Otp,
  PostAuthenticationsConsultEndpointBody,
  PostAuthenticationsConsultEndpointDefault,
  PostAuthenticationsRegister200,
  PostAuthenticationsRequestUpdatePassword200,
  Register,
  RequestUpdatePassword,
  ResendMagicLink,
  ValidateRegister,
  VerifyMagicLink,
  VerifyTokenRequest,
} from '../../../models/auth';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Request otp for direct authentication
 */
export const postAuthenticationsOtp = (
  otp: BodyType<Otp>,
  options?: SecondParameter<typeof authServiceRequest>
) => {
  return authServiceRequest<void>(
    {
      url: `/authentications/otp`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: otp,
    },
    options
  );
};

export const getPostAuthenticationsOtpMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthenticationsOtp>>,
    TError,
    { data: BodyType<Otp> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAuthenticationsOtp>>,
  TError,
  { data: BodyType<Otp> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAuthenticationsOtp>>,
    { data: BodyType<Otp> }
  > = (props) => {
    const { data } = props ?? {};

    return postAuthenticationsOtp(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAuthenticationsOtpMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAuthenticationsOtp>>
>;
export type PostAuthenticationsOtpMutationBody = BodyType<Otp>;
export type PostAuthenticationsOtpMutationError = ErrorType<Error>;

/**
 * @summary Request otp for direct authentication
 */
export const usePostAuthenticationsOtp = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthenticationsOtp>>,
    TError,
    { data: BodyType<Otp> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}) => {
  const mutationOptions = getPostAuthenticationsOtpMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Register account for direct authentication
 */
export const postAuthenticationsRegister = (
  register: BodyType<Register>,
  options?: SecondParameter<typeof authServiceRequest>
) => {
  return authServiceRequest<PostAuthenticationsRegister200>(
    {
      url: `/authentications/register`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: register,
    },
    options
  );
};

export const getPostAuthenticationsRegisterMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthenticationsRegister>>,
    TError,
    { data: BodyType<Register> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAuthenticationsRegister>>,
  TError,
  { data: BodyType<Register> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAuthenticationsRegister>>,
    { data: BodyType<Register> }
  > = (props) => {
    const { data } = props ?? {};

    return postAuthenticationsRegister(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAuthenticationsRegisterMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAuthenticationsRegister>>
>;
export type PostAuthenticationsRegisterMutationBody = BodyType<Register>;
export type PostAuthenticationsRegisterMutationError = ErrorType<Error>;

/**
 * @summary Register account for direct authentication
 */
export const usePostAuthenticationsRegister = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthenticationsRegister>>,
    TError,
    { data: BodyType<Register> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}) => {
  const mutationOptions =
    getPostAuthenticationsRegisterMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Validate registered account
 */
export const postAuthenticationsRegisterValidation = (
  validateRegister: BodyType<ValidateRegister>,
  options?: SecondParameter<typeof authServiceRequest>
) => {
  return authServiceRequest<void>(
    {
      url: `/authentications/register/validation`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: validateRegister,
    },
    options
  );
};

export const getPostAuthenticationsRegisterValidationMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthenticationsRegisterValidation>>,
    TError,
    { data: BodyType<ValidateRegister> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAuthenticationsRegisterValidation>>,
  TError,
  { data: BodyType<ValidateRegister> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAuthenticationsRegisterValidation>>,
    { data: BodyType<ValidateRegister> }
  > = (props) => {
    const { data } = props ?? {};

    return postAuthenticationsRegisterValidation(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAuthenticationsRegisterValidationMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAuthenticationsRegisterValidation>>
>;
export type PostAuthenticationsRegisterValidationMutationBody =
  BodyType<ValidateRegister>;
export type PostAuthenticationsRegisterValidationMutationError =
  ErrorType<Error>;

/**
 * @summary Validate registered account
 */
export const usePostAuthenticationsRegisterValidation = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthenticationsRegisterValidation>>,
    TError,
    { data: BodyType<ValidateRegister> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}) => {
  const mutationOptions =
    getPostAuthenticationsRegisterValidationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Login use direct authentication
 */
export const postAuthenticationsDirectLogin = (
  login: BodyType<Login>,
  options?: SecondParameter<typeof authServiceRequest>
) => {
  return authServiceRequest<void>(
    {
      url: `/authentications/direct/login`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: login,
    },
    options
  );
};

export const getPostAuthenticationsDirectLoginMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthenticationsDirectLogin>>,
    TError,
    { data: BodyType<Login> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAuthenticationsDirectLogin>>,
  TError,
  { data: BodyType<Login> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAuthenticationsDirectLogin>>,
    { data: BodyType<Login> }
  > = (props) => {
    const { data } = props ?? {};

    return postAuthenticationsDirectLogin(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAuthenticationsDirectLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAuthenticationsDirectLogin>>
>;
export type PostAuthenticationsDirectLoginMutationBody = BodyType<Login>;
export type PostAuthenticationsDirectLoginMutationError = ErrorType<Error>;

/**
 * @summary Login use direct authentication
 */
export const usePostAuthenticationsDirectLogin = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthenticationsDirectLogin>>,
    TError,
    { data: BodyType<Login> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}) => {
  const mutationOptions =
    getPostAuthenticationsDirectLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Verify Google OAuth access
 */
export const postAuthenticationsProviderVerifyToken = (
  provider: 'google',
  verifyTokenRequest: BodyType<VerifyTokenRequest>,
  options?: SecondParameter<typeof authServiceRequest>
) => {
  return authServiceRequest<void>(
    {
      url: `/authentications/${provider}/verify_token`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: verifyTokenRequest,
    },
    options
  );
};

export const getPostAuthenticationsProviderVerifyTokenMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthenticationsProviderVerifyToken>>,
    TError,
    { provider: 'google'; data: BodyType<VerifyTokenRequest> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAuthenticationsProviderVerifyToken>>,
  TError,
  { provider: 'google'; data: BodyType<VerifyTokenRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAuthenticationsProviderVerifyToken>>,
    { provider: 'google'; data: BodyType<VerifyTokenRequest> }
  > = (props) => {
    const { provider, data } = props ?? {};

    return postAuthenticationsProviderVerifyToken(
      provider,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAuthenticationsProviderVerifyTokenMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAuthenticationsProviderVerifyToken>>
>;
export type PostAuthenticationsProviderVerifyTokenMutationBody =
  BodyType<VerifyTokenRequest>;
export type PostAuthenticationsProviderVerifyTokenMutationError =
  ErrorType<Error>;

/**
 * @summary Verify Google OAuth access
 */
export const usePostAuthenticationsProviderVerifyToken = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthenticationsProviderVerifyToken>>,
    TError,
    { provider: 'google'; data: BodyType<VerifyTokenRequest> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}) => {
  const mutationOptions =
    getPostAuthenticationsProviderVerifyTokenMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Logout based on provider passed
 */
export const getAuthenticationsProviderLogout = (
  provider: string,
  options?: SecondParameter<typeof authServiceRequest>,
  signal?: AbortSignal
) => {
  return authServiceRequest<void>(
    { url: `/authentications/${provider}/logout`, method: 'get', signal },
    options
  );
};

export const getGetAuthenticationsProviderLogoutQueryKey = (provider: string) =>
  [`/authentications/${provider}/logout`] as const;

export const getGetAuthenticationsProviderLogoutQueryOptions = <
  TData = Awaited<ReturnType<typeof getAuthenticationsProviderLogout>>,
  TError = ErrorType<Error>,
>(
  provider: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAuthenticationsProviderLogout>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof authServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getAuthenticationsProviderLogout>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAuthenticationsProviderLogoutQueryKey(provider);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAuthenticationsProviderLogout>>
  > = ({ signal }) =>
    getAuthenticationsProviderLogout(provider, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!provider, ...queryOptions };
};

export type GetAuthenticationsProviderLogoutQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAuthenticationsProviderLogout>>
>;
export type GetAuthenticationsProviderLogoutQueryError = ErrorType<Error>;

/**
 * @summary Logout based on provider passed
 */
export const useGetAuthenticationsProviderLogout = <
  TData = Awaited<ReturnType<typeof getAuthenticationsProviderLogout>>,
  TError = ErrorType<Error>,
>(
  provider: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAuthenticationsProviderLogout>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof authServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAuthenticationsProviderLogoutQueryOptions(
    provider,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Request Forgot password
 */
export const patchAuthenticationsPassword = (
  forgotPassword: BodyType<ForgotPassword>,
  options?: SecondParameter<typeof authServiceRequest>
) => {
  return authServiceRequest<void>(
    {
      url: `/authentications/password`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: forgotPassword,
    },
    options
  );
};

export const getPatchAuthenticationsPasswordMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchAuthenticationsPassword>>,
    TError,
    { data: BodyType<ForgotPassword> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchAuthenticationsPassword>>,
  TError,
  { data: BodyType<ForgotPassword> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchAuthenticationsPassword>>,
    { data: BodyType<ForgotPassword> }
  > = (props) => {
    const { data } = props ?? {};

    return patchAuthenticationsPassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchAuthenticationsPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchAuthenticationsPassword>>
>;
export type PatchAuthenticationsPasswordMutationBody = BodyType<ForgotPassword>;
export type PatchAuthenticationsPasswordMutationError = ErrorType<Error>;

/**
 * @summary Request Forgot password
 */
export const usePatchAuthenticationsPassword = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchAuthenticationsPassword>>,
    TError,
    { data: BodyType<ForgotPassword> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}) => {
  const mutationOptions =
    getPatchAuthenticationsPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary JSON RPC authenticator for consult service
 */
export const postAuthenticationsConsultEndpoint = (
  endpoint: string,
  postAuthenticationsConsultEndpointBody: BodyType<PostAuthenticationsConsultEndpointBody>,
  options?: SecondParameter<typeof authServiceRequest>
) => {
  return authServiceRequest<ConsultAuthenticatorRequest>(
    {
      url: `/authentications/consult/${endpoint}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: postAuthenticationsConsultEndpointBody,
    },
    options
  );
};

export const getPostAuthenticationsConsultEndpointMutationOptions = <
  TError = ErrorType<PostAuthenticationsConsultEndpointDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthenticationsConsultEndpoint>>,
    TError,
    {
      endpoint: string;
      data: BodyType<PostAuthenticationsConsultEndpointBody>;
    },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAuthenticationsConsultEndpoint>>,
  TError,
  { endpoint: string; data: BodyType<PostAuthenticationsConsultEndpointBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAuthenticationsConsultEndpoint>>,
    { endpoint: string; data: BodyType<PostAuthenticationsConsultEndpointBody> }
  > = (props) => {
    const { endpoint, data } = props ?? {};

    return postAuthenticationsConsultEndpoint(endpoint, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAuthenticationsConsultEndpointMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAuthenticationsConsultEndpoint>>
>;
export type PostAuthenticationsConsultEndpointMutationBody =
  BodyType<PostAuthenticationsConsultEndpointBody>;
export type PostAuthenticationsConsultEndpointMutationError =
  ErrorType<PostAuthenticationsConsultEndpointDefault>;

/**
 * @summary JSON RPC authenticator for consult service
 */
export const usePostAuthenticationsConsultEndpoint = <
  TError = ErrorType<PostAuthenticationsConsultEndpointDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthenticationsConsultEndpoint>>,
    TError,
    {
      endpoint: string;
      data: BodyType<PostAuthenticationsConsultEndpointBody>;
    },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}) => {
  const mutationOptions =
    getPostAuthenticationsConsultEndpointMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Verify Request Update Password Otp
 */
export const postAuthenticationsRequestUpdatePassword = (
  requestUpdatePassword: BodyType<RequestUpdatePassword>,
  options?: SecondParameter<typeof authServiceRequest>
) => {
  return authServiceRequest<PostAuthenticationsRequestUpdatePassword200>(
    {
      url: `/authentications/request-update-password`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdatePassword,
    },
    options
  );
};

export const getPostAuthenticationsRequestUpdatePasswordMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthenticationsRequestUpdatePassword>>,
    TError,
    { data: BodyType<RequestUpdatePassword> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAuthenticationsRequestUpdatePassword>>,
  TError,
  { data: BodyType<RequestUpdatePassword> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAuthenticationsRequestUpdatePassword>>,
    { data: BodyType<RequestUpdatePassword> }
  > = (props) => {
    const { data } = props ?? {};

    return postAuthenticationsRequestUpdatePassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAuthenticationsRequestUpdatePasswordMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postAuthenticationsRequestUpdatePassword>>
  >;
export type PostAuthenticationsRequestUpdatePasswordMutationBody =
  BodyType<RequestUpdatePassword>;
export type PostAuthenticationsRequestUpdatePasswordMutationError =
  ErrorType<Error>;

/**
 * @summary Verify Request Update Password Otp
 */
export const usePostAuthenticationsRequestUpdatePassword = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthenticationsRequestUpdatePassword>>,
    TError,
    { data: BodyType<RequestUpdatePassword> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}) => {
  const mutationOptions =
    getPostAuthenticationsRequestUpdatePasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get detail of current user
 */
export const getAuthenticationsMe = (
  options?: SecondParameter<typeof authServiceRequest>,
  signal?: AbortSignal
) => {
  return authServiceRequest<GetAuthenticationsMe200>(
    { url: `/authentications/me`, method: 'get', signal },
    options
  );
};

export const getGetAuthenticationsMeQueryKey = () =>
  [`/authentications/me`] as const;

export const getGetAuthenticationsMeQueryOptions = <
  TData = Awaited<ReturnType<typeof getAuthenticationsMe>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAuthenticationsMe>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getAuthenticationsMe>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAuthenticationsMeQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAuthenticationsMe>>
  > = ({ signal }) => getAuthenticationsMe(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetAuthenticationsMeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAuthenticationsMe>>
>;
export type GetAuthenticationsMeQueryError = ErrorType<Error>;

/**
 * @summary Get detail of current user
 */
export const useGetAuthenticationsMe = <
  TData = Awaited<ReturnType<typeof getAuthenticationsMe>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAuthenticationsMe>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAuthenticationsMeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Verify Magic Link
 */
export const postAuthenticationsMagiclink = (
  verifyMagicLink: BodyType<VerifyMagicLink>,
  options?: SecondParameter<typeof authServiceRequest>
) => {
  return authServiceRequest<void>(
    {
      url: `/authentications/magiclink`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: verifyMagicLink,
    },
    options
  );
};

export const getPostAuthenticationsMagiclinkMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthenticationsMagiclink>>,
    TError,
    { data: BodyType<VerifyMagicLink> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAuthenticationsMagiclink>>,
  TError,
  { data: BodyType<VerifyMagicLink> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAuthenticationsMagiclink>>,
    { data: BodyType<VerifyMagicLink> }
  > = (props) => {
    const { data } = props ?? {};

    return postAuthenticationsMagiclink(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAuthenticationsMagiclinkMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAuthenticationsMagiclink>>
>;
export type PostAuthenticationsMagiclinkMutationBody =
  BodyType<VerifyMagicLink>;
export type PostAuthenticationsMagiclinkMutationError = ErrorType<Error>;

/**
 * @summary Verify Magic Link
 */
export const usePostAuthenticationsMagiclink = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthenticationsMagiclink>>,
    TError,
    { data: BodyType<VerifyMagicLink> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}) => {
  const mutationOptions =
    getPostAuthenticationsMagiclinkMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Resend magic link
 */
export const postAuthenticationsMagiclinkResend = (
  resendMagicLink: BodyType<ResendMagicLink>,
  options?: SecondParameter<typeof authServiceRequest>
) => {
  return authServiceRequest<void>(
    {
      url: `/authentications/magiclink/resend`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: resendMagicLink,
    },
    options
  );
};

export const getPostAuthenticationsMagiclinkResendMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthenticationsMagiclinkResend>>,
    TError,
    { data: BodyType<ResendMagicLink> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAuthenticationsMagiclinkResend>>,
  TError,
  { data: BodyType<ResendMagicLink> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAuthenticationsMagiclinkResend>>,
    { data: BodyType<ResendMagicLink> }
  > = (props) => {
    const { data } = props ?? {};

    return postAuthenticationsMagiclinkResend(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAuthenticationsMagiclinkResendMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAuthenticationsMagiclinkResend>>
>;
export type PostAuthenticationsMagiclinkResendMutationBody =
  BodyType<ResendMagicLink>;
export type PostAuthenticationsMagiclinkResendMutationError = ErrorType<Error>;

/**
 * @summary Resend magic link
 */
export const usePostAuthenticationsMagiclinkResend = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthenticationsMagiclinkResend>>,
    TError,
    { data: BodyType<ResendMagicLink> },
    TContext
  >;
  request?: SecondParameter<typeof authServiceRequest>;
}) => {
  const mutationOptions =
    getPostAuthenticationsMagiclinkResendMutationOptions(options);

  return useMutation(mutationOptions);
};
