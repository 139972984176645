import './style.scss';

import { FC, useState } from 'react';

import { Button, Modal } from 'antd';
import cn from 'classnames';

import { LEGAL_DRAFTS_GUIDEBOOK } from './constants';

export type ModalLegalDraftGuidebookProps = {
  isShow?: boolean;
  onCloseModal?: () => void;
};

export const ModalLegalDraftGuidebook: FC<ModalLegalDraftGuidebookProps> = ({
  isShow,
  onCloseModal,
}) => {
  const [currPage, setCurrPage] = useState<number>(0);

  const handleCloseModal = () => {
    onCloseModal?.();
    setCurrPage(0);
  };

  return (
    <Modal
      centered
      className="legal-draft-guidebook"
      footer={null}
      onCancel={handleCloseModal}
      open={isShow}
      width={1107}>
      <div className="content">
        <img
          alt={LEGAL_DRAFTS_GUIDEBOOK[currPage].title}
          className="guide-image"
          src={LEGAL_DRAFTS_GUIDEBOOK[currPage].imageUrl}
        />
        <div className="guide-info">
          <div>
            <h3 className="title">{LEGAL_DRAFTS_GUIDEBOOK[currPage].title}</h3>

            <p className="subtitle">
              {LEGAL_DRAFTS_GUIDEBOOK[currPage].subtitle}
            </p>
          </div>

          <div className="footer">
            <div className="pointer">
              <div
                className={cn('pointer-bullet', currPage === 0 && 'active')}
              />
              <div
                className={cn(
                  'pointer-bullet',
                  currPage > 0 &&
                    currPage < LEGAL_DRAFTS_GUIDEBOOK.length - 1 &&
                    'active'
                )}
              />
              <div
                className={cn(
                  'pointer-bullet',
                  currPage === LEGAL_DRAFTS_GUIDEBOOK.length - 1 && 'active'
                )}
              />
            </div>
            <div className="buttons">
              <Button
                className={cn(currPage < 1 && 'hidden')}
                onClick={() => {
                  if (currPage < 1) return;
                  setCurrPage((prev) => prev - 1);
                }}
                type="ghost">
                Sebelumnya
              </Button>

              <Button
                onClick={() => {
                  if (currPage >= LEGAL_DRAFTS_GUIDEBOOK.length - 1) {
                    handleCloseModal();
                    return;
                  }
                  setCurrPage((prev) => prev + 1);
                }}
                type="primary">
                {currPage >= LEGAL_DRAFTS_GUIDEBOOK.length - 1
                  ? 'Mengerti'
                  : 'Selanjutnya'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
