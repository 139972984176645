import { Message } from 'libs/tinode/types/sdk/Message';

import { Consultation } from 'api/generated/models/case';

export function getStartMessage(
  messages: Message[] | undefined,
  consultation?: Consultation
): Message | null | undefined {
  const startMessage = messages?.[0];
  const isValidStartMessage =
    startMessage?.content?.type === 'system' &&
    startMessage?.content?.message === 'chat started';
  return isValidStartMessage || consultation?.status === 'ongoing'
    ? startMessage
    : null;
}
