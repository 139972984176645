import './style.scss';

import { HTMLAttributes, useState } from 'react';

import { Button, Col, Form, Grid, Row, Space, Typography } from 'antd';
import classNames from 'classnames';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDisbursements } from 'hooks/useDisbursements';
import { useResponsiveFlashMessage } from 'hooks/useResponsiveFlashMessage';

import { useGetConsultationsAggregate } from 'api/generated/services/case/consultation/consultation';
import { useRequestDisbursement } from 'api/generated/services/case/disbursement/disbursement';
import { ReactComponent as VerifiedIc } from 'assets/svg/verified-ic.svg';
import { Avatar } from 'components/avatar';
import { WithdrawModal } from 'components/modals/withdraw';
import {
  ADMIN_FEE,
  METHOD_TYPE,
  SERVICE_FEE,
} from 'components/page-sidebar/constants';
import { useCurrentUserStore } from 'store/current-user';
import { currencyFormatter } from 'utils';

export function UserInfo({ className }: HTMLAttributes<HTMLDivElement>) {
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const { messageSuccess } = useResponsiveFlashMessage();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);

  const firstName = useCurrentUserStore((state) => state.firstName);
  const lastName = useCurrentUserStore((state) => state.lastName);
  const picture = useCurrentUserStore((state) => state.picture);

  const onOpenConfirmationModal = () => setIsConfirmationModalOpen(true);
  const onCloseConfirmationModal = () => setIsConfirmationModalOpen(false);
  const onCloseWithdrawModal = () => setIsWithdrawModalOpen(false);

  const { currentLawyerBankAccount } = useCurrentUser();
  const { data: consultationAggregateData, refetch: refetchAggregate } =
    useGetConsultationsAggregate();

  const { refetchGetDisbursements } = useDisbursements();

  const {
    mutate: requestDisbursementMutation,
    isLoading: isLoadingRequestDisbursement,
  } = useRequestDisbursement({
    mutation: {
      async onSuccess() {
        refetchGetDisbursements();
        refetchAggregate();
        form.resetFields();
        onCloseConfirmationModal();
        onCloseWithdrawModal();

        return messageSuccess({
          desktopMessage: 'Sukses request tarik dana',
          mobileMessage:
            'Permintaan sudah dikirim dan permintaan penarikan akan diproses oleh admin.',
        });
      },
    },
  });

  const avatar = {
    first_name: firstName,
    last_name: lastName,
    picture,
  };
  const consultationAggregate = consultationAggregateData?.data;
  const totalWalletAmount = consultationAggregate?.total_wallet_amount ?? 0;

  const onOpenWithdrawModal = () => {
    setIsWithdrawModalOpen(true);
  };

  const handleWithdrawSubmit = () => {
    const { amount = 0 } = form.getFieldsValue();
    const total = amount - ADMIN_FEE - SERVICE_FEE;
    const payload = {
      amount,
      transfer_amount: total,
      method_type: METHOD_TYPE,
      channel_name: currentLawyerBankAccount?.bank_name ?? '',
      channel_code: currentLawyerBankAccount?.bank_code ?? '',
      account_number: currentLawyerBankAccount?.account_number ?? '',
      account_holder: currentLawyerBankAccount?.account_name ?? '',
      bank_branch: currentLawyerBankAccount?.bank_branch ?? '',
    };
    return requestDisbursementMutation({ data: payload });
  };

  return (
    <>
      <div className={classNames('user-info', className)}>
        <Row
          align="middle"
          className="hk-margin-bottom-md"
          justify="space-between"
          wrap={false}>
          <Col>
            <Space size={12}>
              <Avatar user={avatar} />
              <Space className="w-full" direction="vertical" size={0}>
                <Typography.Title
                  className="hk-margin-none font-16 weight5600 hk-color-black-title"
                  color="#404041">
                  {firstName + ' ' + lastName}
                </Typography.Title>
                <Space align="center" className="w-full">
                  <Typography.Paragraph
                    className="hk-margin-none"
                    type="secondary">
                    Terverifikasi
                  </Typography.Paragraph>
                  <VerifiedIc />
                </Space>
              </Space>
            </Space>
          </Col>
          <Col className="mobile">
            <Button
              className="btn-middle"
              onClick={onOpenWithdrawModal}
              type="primary">
              Tarik Dana
            </Button>
          </Col>
        </Row>

        <Space className="desktop" size={0}>
          <Row>
            <Col lg={12} xs={24}>
              <Space direction="vertical" size={0}>
                <Typography.Text type="secondary">
                  Jumlah Pendapatan
                </Typography.Text>
                <Typography.Text>
                  {currencyFormatter(consultationAggregate?.total_income ?? 0)}
                </Typography.Text>
              </Space>
            </Col>
            <Col lg={12} xs={24}>
              <Button onClick={onOpenWithdrawModal} size="large" type="primary">
                Tarik Dana
              </Button>
            </Col>
          </Row>
        </Space>

        <Row className={classNames('w-full', { 'text-center': !screens.md })}>
          <Col className="mobile" md={24} xs={8}>
            <Row>
              <Col md={12} xs={24}>
                <Typography.Text className="amount-label" type="secondary">
                  Jumlah Pendapatan{' '}
                </Typography.Text>
              </Col>
              <Col md={12} xs={24}>
                <Typography.Text>
                  {currencyFormatter(consultationAggregate?.total_income ?? 0)}
                </Typography.Text>
              </Col>
            </Row>
          </Col>

          <Col md={24} xs={8}>
            <Row>
              <Col md={12} xs={24}>
                <Typography.Text className="amount-label" type="secondary">
                  Kredit Tersedia{' '}
                </Typography.Text>
              </Col>
              <Col md={12} xs={24}>
                <Typography.Text>
                  {currencyFormatter(totalWalletAmount ?? 0)}
                </Typography.Text>
              </Col>
            </Row>
          </Col>

          <Col md={24} xs={8}>
            <Row>
              <Col md={12} xs={24}>
                <Typography.Text className="amount-label" type="secondary">
                  Total Penarikan{' '}
                </Typography.Text>
              </Col>
              <Col md={12} xs={24}>
                <Typography.Text>
                  {currencyFormatter(
                    consultationAggregate?.total_disburse ?? 0
                  )}
                </Typography.Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <WithdrawModal
        form={form}
        isConfirmationModalOpen={isConfirmationModalOpen}
        isLoadingSubmit={isLoadingRequestDisbursement}
        onCancel={onCloseWithdrawModal}
        onCloseConfirmationModal={onCloseConfirmationModal}
        onOpenConfirmationModal={onOpenConfirmationModal}
        onSubmit={handleWithdrawSubmit}
        open={isWithdrawModalOpen}
        totalWalletAmount={totalWalletAmount}
      />
    </>
  );
}
