import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Col, Form, Row, Tabs, TabsProps } from 'antd';

import { useGetLegalDrafts } from 'api/generated/services/case/legal-draft/legal-draft';
import { useGetOrders } from 'api/generated/services/case/order/order';
import { Pagination } from 'components';
import { useLegalDraftsFilter } from 'pages/legal-drafting/hooks/useLegalDraftsFilter';
import { TabType } from 'pages/legal-drafting/types';

import { CardSkeleton, LegalDraftCard, OfferCard } from '../card-legal-draft';
import { EmptyLegalDrafts } from '../empty-legal-draft';

interface LegalDraftTabProps {
  caseCode?: string | undefined;
}

export const LegalDraftTab = ({ caseCode }: LegalDraftTabProps) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [tab] = useSearchParams();

  const [activeTab, setActiveTab] = useState<TabType>('offer');

  useEffect(() => {
    const currentTab = tab.get('tab');
    if (currentTab) {
      setActiveTab(currentTab as TabType);
    }

    if (caseCode) {
      setActiveTab('offer');
    }
  }, [tab, caseCode]);

  const search = Form.useWatch('search', form);
  const status = Form.useWatch('status', form);

  const { legalDraftsParams, onChangePage, ordersParams } =
    useLegalDraftsFilter({
      search,
      status,
      type: activeTab,
      caseCode,
    });

  const { data: ordersData, isLoading: isLoadingOrders } =
    useGetOrders(ordersParams);

  const { data: legalDraftsList, isLoading: isLoadingLegalDraftsList } =
    useGetLegalDrafts(legalDraftsParams);

  const RenderOfferTab = () => {
    if (isLoadingOrders) {
      return <CardSkeleton />;
    }

    if (!ordersData?.data?.length && !isLoadingOrders) {
      return (
        <EmptyLegalDrafts message="Daftar akan ditampilkan, jika Advokat mengirimkan penawaran kepada Anda" />
      );
    }

    if (ordersData?.data?.length && !isLoadingOrders) {
      return (
        <>
          <Row gutter={[24, 24]}>
            {ordersData?.data?.map((eachOrder) => {
              return (
                <Col key={eachOrder?.id} md={12} xl={8} xs={24}>
                  <OfferCard orders={eachOrder} />
                </Col>
              );
            })}
          </Row>
          <Row align="middle" className="hk-margin-top-md" justify="center">
            <Pagination
              current={ordersData?.page_info?.current_page}
              defaultCurrent={legalDraftsParams.limit}
              defaultPageSize={8}
              onChange={onChangePage}
              pageSize={ordersData?.page_info?.limit}
              total={ordersData?.page_info.total_items}
            />
          </Row>
        </>
      );
    }
  };

  const RenderLegalDraftsTab = () => {
    if (isLoadingLegalDraftsList) {
      return <CardSkeleton />;
    }

    if (!legalDraftsList?.data?.length && !isLoadingLegalDraftsList) {
      return (
        <EmptyLegalDrafts message="Daftar legal drafting akan ditampilkan jika advokat telah mengirimkan dokumen kepada Anda" />
      );
    }

    if (legalDraftsList?.data?.length && !isLoadingLegalDraftsList) {
      return (
        <>
          <Row gutter={[24, 24]}>
            {legalDraftsList.data.map((eachLegalDraft) => {
              return (
                <Col key={eachLegalDraft?.id} md={12} xl={8} xs={24}>
                  <LegalDraftCard legalDraft={eachLegalDraft} />
                </Col>
              );
            })}
          </Row>
          <Row align="middle" className="hk-margin-top-md" justify="center">
            <Pagination
              current={legalDraftsList?.page_info?.current_page}
              defaultCurrent={legalDraftsParams.limit}
              defaultPageSize={8}
              onChange={onChangePage}
              pageSize={legalDraftsList?.page_info?.limit}
              total={legalDraftsList?.page_info.total_items}
            />
          </Row>
        </>
      );
    }
  };

  const items: TabsProps['items'] = [
    {
      key: 'offer',
      children: <RenderOfferTab />,
      label: 'Penawaran',
    },
    {
      key: 'legal-drafts',
      label: 'Legal Drafting',
      children: <RenderLegalDraftsTab />,
    },
  ];

  const onChangeTab = (selectedTab: string) => {
    if (!caseCode) {
      navigate(`/legal-drafting?tab=${selectedTab}`);
    }
    form.resetFields();
    setActiveTab(selectedTab as TabType);
  };

  return (
    <Tabs
      activeKey={activeTab}
      defaultActiveKey="offer"
      items={items}
      onChange={onChangeTab}
    />
  );
};
