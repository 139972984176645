// Status Legal Drafting:
export const LEGAL_DRAFTS_STATUS_OPTIONS = [
  {
    label: 'Sedang Diulas',
    value: 'in_review',
  },
  {
    label: 'Butuh Revisi',
    value: 'need_revise',
  },
  {
    label: 'Sedang Direvisi',
    value: 'in_revise',
  },
  {
    label: 'Selesai',
    value: 'accepted',
  },
  {
    label: 'Belum Selesai',
    value: 'appealed',
  },
  {
    label: 'Terlewatkan',
    value: 'passed_due_date',
  },
];

export const OFFER_STATUS_OPTIONS = [
  {
    label: 'Pending',
    value: 'not_started',
  },
  {
    label: 'Success',
    value: 'success',
  },
  {
    label: 'Failed',
    value: 'failed',
  },
  {
    label: 'Expired',
    value: 'late',
  },
];

export const LEGAL_STATUS = [
  //
  // 'created',
  'in_progress',
  'in_review',
  'accepted',
  'need_revise',
  'in_revise',
  'passed_due_date',
  'appealed',
  'not_completed',
  // 'cancelled',
];

export const OFFER_STATUS = ['not_started', 'success', 'failed', 'late'];
