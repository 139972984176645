import { Typography } from 'antd';
import classNames from 'classnames';

interface SettingPageTitleProps {
  className: string | null;
  title: string;
}

export const SettingPageTitle = ({
  className,
  title,
}: SettingPageTitleProps) => {
  return (
    <Typography.Title
      className={classNames(
        'text-title weight-500 hk-color-black-title hk-margin-top-md hk-margin-bottom-md',
        className
      )}>
      {title}
    </Typography.Title>
  );
};
