import { Menu } from 'antd';
import { useLogout } from 'hooks/useLogout';

import { AVATAR_DROPDOWN_MENU_ITEMS } from 'components/page-header/constants';

export function AuthMenu() {
  const { logout } = useLogout();

  const authMenuItems = AVATAR_DROPDOWN_MENU_ITEMS({
    onClickLogout: logout,
  })?.filter((item) => item?.key !== 'pengaturan');

  return <Menu items={authMenuItems} mode="inline" />;
}
