import { ComponentType, lazy } from 'react';

interface NamedModule {
  [key: string]: ComponentType<any>;
}

/**
 * Lazily imports a named export from a module and returns a dynamic import component.
 *
 * @param {Promise<NamedModule>} importPromise - The promise that resolves to the named module.
 * @param {string} componentName - The name of the exported component within the module.
 * @returns {React.LazyExoticComponent<React.ComponentType>} - The lazily loaded dynamic import component.
 *
 * @example
 * export function MyComponent() {...}
 * // ...
 * const LazyMyComponent = lazyNamedExport(
 *   import('./MyComponent'),
 *   'MyComponent'
 * );
 * // ...
 * <Suspense fallback={<LoadingSpinner />}>
 *   <LazyMyComponent />
 * </Suspense>
 */
export function lazyNamedExport(
  importPromise: Promise<NamedModule>,
  componentName: string
) {
  return lazy(() =>
    importPromise.then((module) => ({
      default: module[componentName],
    }))
  );
}
