import axios from 'axios';

export function createClient(
  baseURL = process.env.REACT_APP_BASE_URL,
  config = {}
) {
  const axiosInstance = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...config,
  });

  return axiosInstance;
}
