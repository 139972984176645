import { Modal, ModalProps, Space, Typography } from 'antd';

import { ReactComponent as AlertIc } from 'assets/svg/yellow-alert-ic.svg';

interface ViolateModalProps extends ModalProps {
  message: string;
}

export const ViolateModal = (props: ViolateModalProps) => {
  return (
    <Modal {...props} footer={false} width={400}>
      <Space align="center" className="hk-padding-lg" direction="vertical">
        <AlertIc />
        <Typography.Title
          className="text-title weight-600 hk-color-black-title"
          color="#404041">
          Panduan Chat
        </Typography.Title>
        <Space align="center" className="w-full">
          <Typography.Paragraph
            className="hk-margin-none hk-align-center"
            type="secondary">
            Supaya konsultasi berjalan lancar dan aman, hindari memberikan data
            pribadi seperti email dan nomor telepon.
          </Typography.Paragraph>
        </Space>
      </Space>
    </Modal>
  );
};
