import {
  BankAccount,
  Lawyer,
  UserWithProfile,
} from 'api/generated/models/auth';
import { useGetAuthenticationsMe } from 'api/generated/services/auth/authentication/authentication';

export interface UseCurrentUserParams {
  onSuccess?: (user: UserWithProfile, lawyerProfile: Lawyer) => void;
}

export function useCurrentUser({
  onSuccess = (currentUser) => currentUser,
}: UseCurrentUserParams = {}) {
  const {
    data: authenticationsMeData,
    isLoading: isLoadingCurrentUser,
    refetch: refetchCurrentUser,
  } = useGetAuthenticationsMe({
    query: {
      onSuccess(data) {
        const user = data.data;
        const lawyerProfile = user.profile as Lawyer;
        onSuccess(user, lawyerProfile);
      },
    },
  });

  const currentUser: UserWithProfile | null =
    authenticationsMeData?.data || null;
  const currentLawyerProfile: Lawyer | null =
    (currentUser?.profile as Lawyer) || null;
  const currentLawyerBankAccount: BankAccount | null =
    currentLawyerProfile?.bank_accounts?.[0] || null;

  return {
    currentUser,
    currentLawyerProfile,
    currentLawyerBankAccount,
    isLoadingCurrentUser,
    refetchCurrentUser,
  };
}
