import { useState } from 'react';

import { UploadFile } from 'antd';

export const useUploadDraft = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleChangeFile = (uploadData: any) => {
    const changedFileList = (uploadData?.fileList as UploadFile[]) || [];
    if (!changedFileList?.length) return;

    const fileListItem = changedFileList[0];
    setFileList([fileListItem]);
  };
  const onClickRemoveFile = () => {
    setFileList([]);
  };
  return {
    fileList,
    handleChangeFile,
    onClickRemoveFile,
    setFileList,
  };
};
