import { useMemo } from 'react';

import { Badge, Col, Popover, Result, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';

import { ReactComponent as NotificationIc } from 'assets/svg/header-notification-ic.svg';
import { ReactComponent as NotificationEmptyIc } from 'assets/svg/notification-empty-ic.svg';

// TODO: Fetch from BE
const notifications = [
  {
    id: 'not-1',
    label: 'Hari ini',
    data: [
      {
        id: 'notData-1',
        title: 'Pending Task',
        subTitle:
          'Ex fugiat occaecat occaecat reprehenderit labore nisi dolore dolore in cillum laboris elit aute aute.',
        time: Date.now(),
      },
    ],
  },
  {
    id: 'not-2',
    label: 'Juni 2023',
    data: [
      {
        id: 'notData-2',
        title: 'Pending Task',
        subTitle:
          'Ex fugiat occaecat occaecat reprehenderit labore nisi dolore dolore in cillum laboris elit aute aute.',
        time: Date.now(),
      },
      {
        id: 'notData-123',
        title: 'Payment Success',
        subTitle:
          'Ex fugiat occaecat occaecat reprehenderit labore nisi dolore dolore in cillum laboris elit aute aute.',
        time: Date.now(),
      },
    ],
  },
];

export function NotificationPopover() {
  const isNotificationEmpty = useMemo(() => notifications?.length === 0, []);

  return (
    <div className="notification-popover">
      <Popover
        content={
          isNotificationEmpty ? (
            <Result
              icon={<NotificationEmptyIc />}
              subTitle="Tidak ada notifikasi untuk saat ini. Kami akan memberitahukan anda saat pemberitahuan tiba!"
              title="Tidak Ada Notifikasi"
            />
          ) : (
            <>
              {notifications?.map((notification) => (
                <Row
                  align="middle"
                  className="hk-margin-top-xl"
                  key={notification?.id}>
                  <Col span={24}>
                    <Typography.Title
                      className="hk-margin-top-none font-16 weight-500 hk-color-black-title"
                      color="#404041">
                      {notification?.label}
                    </Typography.Title>
                  </Col>
                  <Col span={24}>
                    {notification?.data?.map((notificationData) => (
                      <Row align="middle" key={notificationData?.id}>
                        <Col span={3}>
                          <NotificationIc />
                        </Col>
                        <Col span={17}>
                          <Space
                            className="w-full"
                            direction="vertical"
                            size={0}>
                            <Typography.Title
                              className="font-16 weight-600 hk-color-black-title"
                              ellipsis
                              level={5}>
                              {notificationData?.title}
                            </Typography.Title>
                            <Typography.Paragraph ellipsis type="secondary">
                              {notificationData?.subTitle}
                            </Typography.Paragraph>
                          </Space>
                        </Col>
                        <Col offset={1} span={3}>
                          {dayjs(notificationData?.time).format('HH:mm')}
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              ))}
            </>
          )
        }
        overlayClassName="hk-header notification-popover-overlay"
        title={
          <Typography.Title
            className="hk-margin-top-none text-title weight-600 hk-color-black-title"
            color="#404041">
            Notifikasi
          </Typography.Title>
        }
        trigger="click">
        <Badge dot>
          <NotificationIc />
        </Badge>
      </Popover>
    </div>
  );
}
