import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { MenuProps, SelectProps, Space, Typography } from 'antd';

import { Dot } from 'components/dot';

import { AvatarDropdownMenuItemsProps } from './types';

export const AVATAR_DROPDOWN_MENU_ITEMS: (
  props: AvatarDropdownMenuItemsProps
) => MenuProps['items'] = ({ onClickLogout }) => [
  {
    key: 'pengaturan',
    label: (
      <Typography.Link href="/setting/profile">Pengaturan</Typography.Link>
    ),
    icon: <SettingOutlined />,
  },
  {
    key: 'logout',
    label: 'Keluar',
    icon: <LogoutOutlined />,
    onClick: onClickLogout,
  },
];

export const STATUS_SELECT_STATUS_OPTIONS: SelectProps['options'] = [
  {
    label: (
      <Space>
        <Dot color="green" size={7} />
        Online
      </Space>
    ),
    value: 'online',
  },
  {
    label: (
      <Space>
        <Dot color="red" size={7} />
        Offline
      </Space>
    ),
    value: 'offline',
  },
  // TODO: uncomment when busy status is available in backend
  // {
  //   label: (
  //     <Space>
  //       <Dot color="orange" size={7} />
  //       Busy
  //     </Space>
  //   ),
  //   value: 'busy',
  // },
];
