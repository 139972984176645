import { useCallback, useEffect, useState } from 'react';

import {
  GetLegalDraftsParams,
  GetOrdersParams,
} from 'api/generated/models/case';

import { LEGAL_STATUS, OFFER_STATUS } from '../constants';
import { TabType } from '../types';
interface LegalDraftsFilterParams {
  caseCode?: string | undefined;
  search?: string;
  status?: string;
  type: TabType;
}

export const useLegalDraftsFilter = ({
  caseCode,
  search,
  status,
  type,
}: LegalDraftsFilterParams) => {
  const [legalDraftsParams, setLegalDraftsParams] =
    useState<GetLegalDraftsParams>({
      limit: 9,
      page: 1,
    });
  const [ordersParams, setOrdersParams] = useState<GetOrdersParams>({
    product_type: ['legal_drafts'],
    page: 1,
    limit: 9,
  });

  // BE cant handle fetch all status by using status: ['']
  // So we call all status instead
  const setDefaultStatus = useCallback(() => {
    if (!status) {
      if (type === 'legal-drafts') {
        setLegalDraftsParams((prevState) => ({
          ...prevState,
          status: LEGAL_STATUS,
        }));
      }
      if (type === 'offer') {
        setOrdersParams((prevState) => ({
          ...prevState,
          status: OFFER_STATUS,
        }));
      }
    }
  }, [status, type]);

  useEffect(() => {
    setDefaultStatus();
  }, [setDefaultStatus]);

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      if (caseCode) return;
      if (type === 'legal-drafts') {
        setLegalDraftsParams((prevState) => ({
          ...prevState,
          page: 1,
          search,
        }));
      }
      if (type === 'offer') {
        setOrdersParams((prevState) => ({ ...prevState, page: 1, search }));
      }
    }, 1000);

    return () => clearTimeout(debounceSearch);
  }, [search, type, caseCode]);

  const searchBySearchCode = useCallback(() => {
    if (caseCode) {
      if (type === 'legal-drafts') {
        setLegalDraftsParams((prevState) => ({
          ...prevState,
          search: caseCode,
        }));
      }
      if (type === 'offer') {
        setOrdersParams((prevState) => ({ ...prevState, search: caseCode }));
      }
    }
  }, [caseCode, type]);

  useEffect(() => {
    searchBySearchCode();
  }, [searchBySearchCode]);

  const onChangePage = (page: number) => {
    if (type === 'legal-drafts') {
      setLegalDraftsParams((prevState) => ({ ...prevState, page }));
    }
    if (type === 'offer') {
      setOrdersParams((prevState) => ({ ...prevState, page }));
    }
  };

  const onChangeStatus = useCallback(() => {
    if (!status) return;
    if (type === 'offer') {
      setOrdersParams((prevState) => ({
        ...prevState,
        status: [status],
      }));
    }
    if (type === 'legal-drafts') {
      setLegalDraftsParams((prevState) => ({
        ...prevState,
        status: [status],
      }));
    }
  }, [type, status]);

  // handle change status
  useEffect(() => {
    onChangeStatus();
  }, [onChangeStatus]);

  return {
    legalDraftsParams,
    onChangePage,
    onChangeStatus,
    ordersParams,
    setLegalDraftsParams,
  };
};
