import './style.scss';

import { useMemo } from 'react';

import { Card, Col, Collapse, Grid, Row, Space, Typography } from 'antd';

import { LegalDraft } from 'api/generated/models/case';
import { useGetPracticeAreas } from 'api/generated/services/auth/practice-area/practice-area';
import { LegalStatus } from 'pages/legal-drafting/types';

import { StatusTag } from '../../status-tag';

interface DocumentDetailCardProps {
  legalDraft: LegalDraft | undefined;
}

export const DocumentDetailCard = (props: DocumentDetailCardProps) => {
  const { legalDraft } = props;

  const screens = Grid.useBreakpoint();

  const practiceAreaId = legalDraft?.case?.practice_area_id;

  const { data: practiceAreaList } = useGetPracticeAreas();

  const documentDetailInfo = useMemo(() => {
    const status = legalDraft?.items?.[0]?.status as LegalStatus;
    const title = legalDraft?.items?.[0]?.name;
    const description = legalDraft?.items?.[0]?.description;
    const topic = legalDraft?.case?.topic ?? '';
    const practiceArea = practiceAreaList?.data?.find(
      ({ id }) => id === practiceAreaId
    );
    return [
      {
        label: 'Status',
        value: <StatusTag status={status ?? 'in_progress'} />,
      },
      {
        label: 'Area Praktik',
        value: practiceArea?.name || '-',
      },
      {
        label: 'Topik',
        value: topic || '-',
      },
      {
        label: 'Judul',
        value: title || '-',
      },
      {
        label: 'Deskripsi',
        value: description || '-',
      },
    ];
  }, [
    legalDraft?.case?.topic,
    legalDraft?.items,
    practiceAreaId,
    practiceAreaList?.data,
  ]);

  const RenderDetailDocument = (
    <Space direction="vertical" size={20}>
      {screens.md ? (
        <Typography.Text className="font-20 weight-500 hk-color-black-title">
          Detail Dokumen
        </Typography.Text>
      ) : null}

      {documentDetailInfo.map(({ label, value }) => {
        return (
          <Row gutter={[0, 0]} key={label}>
            <Col span={24}>
              <Typography.Text className="font-14 weight-500 hk-color-black-title">
                {label}
              </Typography.Text>
            </Col>
            <Col span={24}>
              {typeof value === 'string' ? (
                <Typography.Text className="font-12 weight-400 hk-color-black-title">
                  {value}
                </Typography.Text>
              ) : (
                value
              )}
            </Col>
          </Row>
        );
      })}
    </Space>
  );

  if (screens.md) {
    return (
      <Card className="hk-margin-top-lg forum-payment-card">
        {RenderDetailDocument}
      </Card>
    );
  }
  if (!screens.md) {
    return (
      <Collapse
        accordion
        className="forum-collapse"
        expandIconPosition="end"
        items={[
          {
            key: 'document-detail-card',
            children: RenderDetailDocument,
            label: (
              <Typography.Text className="font-20 weight-500 hk-color-black-title">
                Detail Dokumen
              </Typography.Text>
            ),
          },
        ]}
      />
    );
  }
};
