import { useCallback, useEffect, useState } from 'react';

import { GetDisbursementsParams } from 'api/generated/models/case';
import { useGetDisbursements } from 'api/generated/services/case/disbursement/disbursement';
import { getDocumentsId } from 'api/generated/services/document/default/default';

export const useDisbursements = () => {
  const [disbursements, setDisbursements] = useState<any>([]);
  const [disbursementParams, setDisbursementParams] =
    useState<GetDisbursementsParams>({
      page: 1,
      limit: 5,
    });
  const {
    data: disbursementData,
    isLoading: isLoadingDisbursement,
    refetch: refetchGetDisbursements,
  } = useGetDisbursements(disbursementParams);

  const getDocumentUrl = async (documentId: string | undefined) => {
    if (!documentId) return;
    const response = await getDocumentsId(documentId);
    return response;
  };

  const modifiedDirbursement = useCallback(async () => {
    const dbsData = disbursementData?.data ?? [];
    const modified = await Promise.all(
      dbsData?.map(async (eachDisburse) => {
        let document_url = '';
        let document_name = '';
        await getDocumentUrl(eachDisburse.document_id ?? undefined)
          .then((response) => {
            document_url = response?.data?.url ?? '';
            document_name = response?.data?.name ?? '';
          })
          .catch(() => {
            document_url = '';
            document_name = '';
          });
        return {
          ...eachDisburse,
          document_url,
          document_name,
        };
      })
    );
    setDisbursements(modified);
  }, [disbursementData]);

  useEffect(() => {
    modifiedDirbursement();
  }, [modifiedDirbursement]);
  return {
    setDisbursementParams,
    disbursementData,
    disbursements,
    isLoadingDisbursement,
    refetchGetDisbursements,
  };
};
