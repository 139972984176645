import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { ConsultationBasicCase } from 'api/generated/models/case';
import { MAX_ONGOING_CONSULTATIONS_COUNT } from 'config/constants';

import { ChatState, InitialChat } from './types/chat';

const initialState: InitialChat = {
  isTinodeConnected: false,
  acceptedConsultationIds: [],
  incomingConsultations: [],
  ongoingConsultations: [],
  isLoadingIncomingConsultations: false,
};

export const useChatStore = create<ChatState>()(
  persist(
    (set) => ({
      ...initialState,
      actions: {
        acceptConsultation(consultationId: string) {
          set((state) => {
            const acceptedConsultationIds = [
              ...(state.acceptedConsultationIds || []),
              consultationId,
            ].slice(-MAX_ONGOING_CONSULTATIONS_COUNT);
            return {
              ...state,
              acceptedConsultationIds,
            };
          });
        },

        finishConsultation(consultationId: string) {
          set((state) => {
            const acceptedConsultationIds =
              state.acceptedConsultationIds?.filter(
                (id) => id !== consultationId
              );
            return {
              ...state,
              acceptedConsultationIds,
            };
          });
        },

        setAcceptedConsultationIds(acceptedConsultationIds) {
          set((state) => ({ ...state, acceptedConsultationIds }));
        },

        setIsTinodeConnected(isTinodeConnected: boolean) {
          set((state) => ({ ...state, isTinodeConnected }));
        },

        setIncomingConsultations(
          incomingConsultations: ConsultationBasicCase[]
        ) {
          set((state) => ({ ...state, incomingConsultations }));
        },

        setOngoingConsultations(ongoingConsultations: ConsultationBasicCase[]) {
          set((state) => ({ ...state, ongoingConsultations }));
        },

        setIsLoadingIncomingConsultations(
          isLoadingIncomingConsultations: boolean
        ) {
          set((state) => ({ ...state, isLoadingIncomingConsultations }));
        },

        reset() {
          set(() => initialState);
        },
      },
    }),
    {
      name: 'chat',
      partialize: (state) => {
        // define keys from state that dont want to be included in persist storage
        // isTinodeConnected is expected to not be persisted to make sure every time user refresh page it will be false and tinode will be reconnected
        const excludedKeys = [
          'actions',
          'incomingConsultations',
          'isTinodeConnected',
          'ongoingConsultations',
        ];
        return Object.fromEntries(
          Object.entries(state).filter(([key]) => !excludedKeys.includes(key))
        );
      },
    }
  )
);
