import { useMemo } from 'react';

import { Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';

import { User } from 'api/generated/models/case';
import { Avatar } from 'components/avatar';
import { getFullName, getSecretFullName } from 'utils/get-full-name';

import { RevisionRole } from './types';
interface RevisionHeadProps {
  createdAt?: number;
  role: RevisionRole;
  user: User;
}
export const RevisionHead = ({ createdAt, role, user }: RevisionHeadProps) => {
  const fullName =
    role === 'client' ? getSecretFullName(user) : getFullName(user);

  const currentRole = useMemo(() => {
    if (role === 'client') {
      return 'Klien';
    }

    if (role === 'lawyer') {
      return 'Advokat';
    }

    if (role === 'system') {
      return 'Admin';
    }
    return '';
  }, [role]);

  const timeStamp = createdAt
    ? dayjs.unix(createdAt).format('DD MMMM YYYY, hh:mm')
    : '-';

  return (
    <Row justify="start">
      <Space
        align="start"
        className="hk-margin-bottom-md"
        direction="horizontal"
        size={12}>
        <Avatar size={40} user={user} />
        <Space align="start" direction="vertical" size={2}>
          <Typography.Text className="weight-500 font-14 hk-color-black-title">
            {fullName}
          </Typography.Text>
          <Typography.Text className="weight-400 font-12 hk-color-black-title">
            {currentRole}

            {createdAt ? (
              <span className="hk-color-dark-grey">
                {' - '}
                {timeStamp}
              </span>
            ) : null}
          </Typography.Text>
        </Space>
      </Space>
    </Row>
  );
};
