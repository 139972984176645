import { Layout } from 'antd';

import { DashboardLayout } from 'components/layouts/dashboard-layout';

import { CenteredSpinnerLoading } from '../centered-spinner-loading';

export function DashboardLayoutLoading() {
  return (
    <DashboardLayout>
      <Layout.Content className="w-full">
        <CenteredSpinnerLoading />
      </Layout.Content>
    </DashboardLayout>
  );
}
