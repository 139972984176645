import { AxiosError, AxiosRequestConfig } from 'axios';

import { generateUrlPath } from 'utils';

import { createClient } from './create-client';

const client = createClient();

export const documentServiceRequest = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const { url, ...restOfConfig } = config;
  const prefixedUrl = generateUrlPath({
    service: 'DOCUMENT',
    path: url || '',
  });
  const response = await client({
    url: prefixedUrl,
    ...restOfConfig,
    ...options,
  });
  return response?.data;
};

export type ErrorType<Error> = AxiosError<Error>;

export type BodyType<BodyData> = BodyData;
