import './style.scss';

import { Pagination as AntdPagination } from 'antd';
import { PaginationProps } from 'antd';

export const Pagination = (props: PaginationProps) => {
  const { total, defaultCurrent = 1, pageSize = 5 } = props;
  return (
    <AntdPagination
      {...props}
      className="hk-pagination"
      defaultCurrent={defaultCurrent}
      pageSize={pageSize}
      total={total}
    />
  );
};
