import { HTMLAttributes } from 'react';

interface IProps extends HTMLAttributes<HTMLSpanElement> {
  color?: string;
  size?: string | number;
}

export function Dot({ className, color, size, ...props }: IProps) {
  const style: Record<string, any> = {
    borderRadius: '50%',
    display: 'inline-block',
  };

  if (color) {
    style.backgroundColor = color;
  }

  if (size) {
    style.height = size;
    style.width = size;
  }
  return (
    <span
      {...props}
      className={`hk-dot${className ? ` ${className}` : ''}`}
      style={style}
    />
  );
}
