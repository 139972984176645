import { useEffect, useRef } from 'react';

import { tinodeAdapter } from 'libs/tinode/tinode-adapter';

import { useChatStore } from 'store/chat';
import { useCurrentUserStore } from 'store/current-user';

import { useAuthenticationState } from './useAuthenticationState';

export function useTinodeConnect() {
  const { isTinodeConnected, actions } = useChatStore();
  const { isOnline } = useCurrentUserStore();
  const { isFullyAuthenticated } = useAuthenticationState();
  const isLoadingTinodeConnect = useRef<boolean>(false);

  useEffect(() => {
    if (!isLoadingTinodeConnect.current && !isTinodeConnected) {
      isLoadingTinodeConnect.current = true;
      tinodeAdapter.connect({
        onConnect() {
          actions.setIsTinodeConnected(true);
          isLoadingTinodeConnect.current = false;
        },
      });
    }
  }, [actions, isTinodeConnected]);

  useEffect(() => {
    if (!isTinodeConnected || !isFullyAuthenticated) return;

    if (isOnline) {
      tinodeAdapter.subscribeMe();
    } else {
      tinodeAdapter.leaveMe();
    }
  }, [isFullyAuthenticated, isOnline, isTinodeConnected]);
}
