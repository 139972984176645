import { Dropdown, Grid, Space } from 'antd';
import { useLogout } from 'hooks/useLogout';

import { Avatar } from 'components/avatar';
import { useCurrentUserStore } from 'store/current-user';

import { AVATAR_DROPDOWN_MENU_ITEMS } from '../constants';
import { AvatarDropdownProps } from '../types';

export function AvatarDropdown({ disableDropdown }: AvatarDropdownProps) {
  const screens = Grid.useBreakpoint();
  const firstName = useCurrentUserStore((state) => state.firstName);
  const lastName = useCurrentUserStore((state) => state.lastName);
  const picture = useCurrentUserStore((state) => state.picture);
  const { logout } = useLogout();

  return (
    <Dropdown
      className="avatar-dropdown"
      disabled={disableDropdown}
      menu={{
        items: AVATAR_DROPDOWN_MENU_ITEMS({
          onClickLogout: logout,
        }),
      }}
      trigger={['click']}>
      <Space>
        <Avatar
          size={screens.md ? 56 : 32}
          user={{
            first_name: firstName,
            last_name: lastName,
            picture: picture,
          }}
        />
      </Space>
    </Dropdown>
  );
}
