import './style.scss';

import { Modal, ModalProps, Typography } from 'antd';

import { LegalDraftTab } from '../legal-draft-tab';
interface LegalDraftModalProps extends ModalProps {
  caseCode?: string | undefined;
  clientName?: string | undefined;
}

export const LegalDraftModal = (props: LegalDraftModalProps) => {
  const { caseCode, clientName = '' } = props;

  return (
    <Modal
      {...props}
      className="legal-draft-modal"
      footer={false}
      title={
        <Typography.Title
          className="legal-draft-modal-title weight-500"
          color="#121926">
          Legal Drafting, {clientName}
        </Typography.Title>
      }
      width={1212}>
      <LegalDraftTab caseCode={caseCode} />
    </Modal>
  );
};
