import './style.scss';

import { Modal, Typography } from 'antd';

import { CaseModalContent } from 'components/case-modal-content';

import { ClientIssueModalProps } from '../../types';

export const ClientIssueModal = ({
  consultationCase,
  isOpen,
  onClose,
}: ClientIssueModalProps) => {
  return (
    <Modal
      className="client-issue-modal"
      footer={false}
      onCancel={onClose}
      open={isOpen}
      title={
        <Typography.Title className="font-24 weight-500" color="#404041">
          Permasalahan Klien
        </Typography.Title>
      }
      width={487}>
      <CaseModalContent consultationCase={consultationCase} />
    </Modal>
  );
};
