import { tinodeAdapter } from 'libs/tinode/tinode-adapter';

import { useAuthStore } from 'store/auth';

export function useAuthenticationState() {
  const { user } = useAuthStore((authState) => authState);
  const hasTinodeCredentials = tinodeAdapter.hasCredentials();

  return {
    isFullyAuthenticated: Boolean(user) && hasTinodeCredentials,
  };
}
