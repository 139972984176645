import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import {
  EVerificationStep,
  IInitialVerification,
  IVerificationState,
} from './types/verification';

const initialState: IInitialVerification = {
  step: EVerificationStep.STEP1,
  formData: {
    step: 'personal_information',
    first_name: '',
    last_name: '',
    educations: [],
    email: '',
    phone_number: '',
    gender: '',
    location: '',
    about_me: '',
    identity: { id: '', number: '' },
    level_of_experience: '',
    position: '',
    workplace: '',
    lawyer_advocate_license: { key: '', url: '' },
    picture: { key: '', url: '' },
    identity_card_document: { key: '', url: '' },
  },
};

export const useVerificationStore = create<IVerificationState>()(
  persist(
    (set) => ({
      ...initialState,
      actions: {
        setStep(payload) {
          set((state) => ({ ...state, step: payload }));
        },
        setFormData(payload) {
          set((state) => ({
            ...state,
            formData: { ...state.formData, ...payload },
          }));
        },
        resetStep() {
          set((state) => ({ ...state, step: initialState.step }));
        },
        resetFormData() {
          set((state) => ({ ...state, formData: initialState.formData }));
        },
      },
    }),
    {
      name: 'verification',
      partialize: (state) => {
        // define keys from state that dont want to be included in persist storage
        const excludedKeys = ['actions'];
        return Object.fromEntries(
          Object.entries(state).filter(([key]) => !excludedKeys.includes(key))
        );
      },
    }
  )
);
