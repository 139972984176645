import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Grid } from 'antd';

import { useLayoutStore } from 'store/layout';

export function MobileSidebarToggle() {
  const screens = Grid.useBreakpoint();

  const isMobileSidebarVisible = useLayoutStore(
    (state) => state.isMobileSidebarVisible
  );

  const toggleMobileSidebar = useLayoutStore(
    (state) => state.actions.toggleMobileSidebar
  );

  return (
    <>
      {!screens.md && !isMobileSidebarVisible && (
        <MenuOutlined onClick={() => toggleMobileSidebar()} />
      )}
      {!screens.md && isMobileSidebarVisible && (
        <CloseOutlined onClick={() => toggleMobileSidebar()} />
      )}
    </>
  );
}
