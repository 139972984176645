import './style.scss';

import { Button, Modal, Space, Typography } from 'antd';

import { ReactComponent as HukumkuLogo } from 'assets/svg/header-logo.svg';

import { IdleModalProps } from './types';

export function IdleModal({
  open,
  handleOnline,
  handleOffline,
}: IdleModalProps) {
  return (
    <Modal
      centered
      className="idle-modal"
      footer={null}
      onCancel={handleOffline}
      open={open}
      width={420}>
      <Space align="center" className="w-full" direction="vertical" size={24}>
        <HukumkuLogo />
        <Typography.Text>
          Hai, Anda sudah tidak aktif selama 10 menit, kami akan membuat Anda
          offline.
        </Typography.Text>
      </Space>
      <Space className="w-full" direction="vertical">
        <Button block onClick={handleOnline} type="primary">
          Saya Kembali, Tetap Online
        </Button>
        <Button block danger onClick={handleOffline} type="text">
          Tutup Pesan
        </Button>
      </Space>
    </Modal>
  );
}
