// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: 'AIzaSyDCPqYCuPIvfUdMjqvSvW0AQVqD4SIDyXQ',
  authDomain: 'hukumku-c33e0.firebaseapp.com',
  projectId: 'hukumku-c33e0',
  storageBucket: 'hukumku-c33e0.appspot.com',
  messagingSenderId: '972947219456',
  appId: '1:972947219456:web:60673f40f7c0e39c8de4e5',
};
