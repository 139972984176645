import './config/i18n';
import 'dayjs/locale/id';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import dayjs from 'dayjs';

import { log } from 'utils/log';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

dayjs.locale('id');

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register({
  onSuccess: (registration) => {
    log({
      error: false,
      message: `Service worker registered successfully: ${registration}`,
    });
  },
  onUpdate: (registration) => {
    log({
      error: false,
      message: `Service worker updated: ${registration}`,
    });
  },
});
