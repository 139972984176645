import './style.scss';

import { Col, Row, Space, Typography } from 'antd';

import { Case } from 'api/generated/models/case';
import { ReactComponent as PaperIc } from 'assets/svg/paper-ic.svg';
import { useClientIssue } from 'components/chat/hooks/useClientIssue';
import { CASE_DESCRIPTION_EXCERPT_MAX_CHAR_LENGTH } from 'config/constants';

import { ClientIssueModal } from './ClientIssueModal';

export interface ClientIssueProps {
  consultationCase?: Case;
}

export const ClientIssue = ({ consultationCase }: ClientIssueProps = {}) => {
  const { onOpenIssueModal, onCloseIssueModal, isIssueModalOpen } =
    useClientIssue();
  return (
    <>
      <Row className="issue-wrapper">
        <Col span={24}>
          <Row>
            <Space>
              <PaperIc />
              <Typography.Text className="font-16 weight-500 hk-color-black-title">
                Permasalahan Klien
              </Typography.Text>
            </Space>
          </Row>
        </Col>
        <Col span={24}>
          <Row align="middle" justify="space-between">
            <Col>
              <Typography.Text className="font-12 weight-400 hk-color-dark-gray">
                {consultationCase?.description?.slice(
                  0,
                  CASE_DESCRIPTION_EXCERPT_MAX_CHAR_LENGTH
                )}
              </Typography.Text>
            </Col>

            <Col>
              <Typography.Text
                className="detail font-14 weight-500 hk-color-dark-green"
                onClick={onOpenIssueModal}>
                Detail
              </Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
      <ClientIssueModal
        consultationCase={consultationCase}
        isOpen={isIssueModalOpen}
        onClose={onCloseIssueModal}
        onOpen={onOpenIssueModal}
      />
    </>
  );
};
