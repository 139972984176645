import { Alert, Col, Row, Typography } from 'antd';

import ExclamationIcon from 'assets/svg/form-error-alert.svg';

import { MissedBy } from '../modal/forum-discussion-modal/types';
interface LimitAlertProps {
  dueDate: string;
  missedBy: MissedBy;
}

export const LimitAlert = ({ dueDate, missedBy }: LimitAlertProps) => {
  const className = 'hk-padding-top-xs hk-padding-bottom-xs';

  if (missedBy === 'missed by client') {
    return (
      <Alert
        className={className}
        message={
          <Row align="middle" gutter={[4, 0]} justify="start">
            <Col className="flex center">
              <img alt="exclamation error" src={ExclamationIcon} />
            </Col>
            <Col className="flex center">
              <Typography.Text className="font-10 weight-400 hk-color-black-title">
                Klien tidak memberikan ulasan selama 2x24 jam
              </Typography.Text>
            </Col>
          </Row>
        }
        type="error"
      />
    );
  }
  if (missedBy === 'missed by lawyer') {
    return (
      <Alert
        className={className}
        message={
          <Row align="middle" gutter={[4, 0]} justify="start">
            <Col className="flex center">
              <img alt="exclamation error" src={ExclamationIcon} />
            </Col>
            <Col>
              <Typography.Text className="font-10 weight-400 hk-color-black-title">
                Dokumen ditangguhkan. Anda tidak memberikan feedback dalam 2x24
                jam.
              </Typography.Text>
            </Col>
          </Row>
        }
        type="error"
      />
    );
  }

  return (
    <Alert
      className={className}
      message={
        <Row align="middle" justify="space-between">
          <Col span={12}>
            <Typography.Text className="font-10 weight-400 hk-color-black-title">
              Selesaikan pekerjaan sebelum
            </Typography.Text>
          </Col>
          <Col className="hk-align-right" span={12}>
            <Typography.Text className="hk-align-right font-12 weight-500 hk-color-status-yellow">
              {dueDate}
            </Typography.Text>
          </Col>
        </Row>
      }
      type="success"
    />
  );
};
