import { Col, Row, Typography } from 'antd';

import { ReactComponent as EmptyListImg } from 'assets/svg/empty-folder.svg';

export interface EmptyListProps {
  className?: string;
  title?: string;
  secondaryTitle?: string;
}

export function EmptyList({
  className,
  title = 'Tidak ada data',
  secondaryTitle,
}: EmptyListProps) {
  return (
    <Row className={className} justify="center">
      <Col className="flex center flex-column items-center">
        <EmptyListImg />
        <Typography.Paragraph className="hk-margin-bottom-xs hk-margin-top-lg font-18 weight-500 hk-color-black-title hk-align-center">
          {title}
        </Typography.Paragraph>
        {Boolean(secondaryTitle) && (
          <Typography.Paragraph className="font-14 weight-400 hk-color-heading-1 hk-align-center">
            {secondaryTitle}
          </Typography.Paragraph>
        )}
      </Col>
    </Row>
  );
}
